import React, { useRef, useState } from "react";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Viewer from "react-viewer";
import { PickupsResponseContentModel } from "../../../app/data/pickup/models";
import "./documentViewer.scss";
import pickup from "../../../images/bol_scan_1.svg";
import consignee from "../../../images/bol_scan_2.svg";
import phone from "../../../images/bol_scan_3.svg";
import shipper from "../../../images/bol_scan_4.svg";
import driver from "../../../images/bol_scan_5.svg";
import origin from "../../../images/bol_scan_6.svg";
import destination from "../../../images/bol_scan_7.svg";
import items from "../../../images/bol_scan_8.svg";
import weight from "../../../images/bol_scan_9.svg";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import { toast } from "react-toastify";

export interface PickupDocumentViewerProps {
  pickup: PickupsResponseContentModel | null;
  isOpen: boolean;
  onClose: any;
}

export const PickupDocumentViewer: React.FC<PickupDocumentViewerProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const imageContainer = useRef<any>(null);
  const queryClient = useQueryClient();

  const approvePickupMutation = useMutation(
    async () => {
      return await apiQueryAdapter.query<null>(`/pickups/billing/${props.pickup?.pickup.pickupId}/approve`, "POST", {
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pickup_approvement']);
        toast.info("Pickup approved");
        props.onClose();
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );
  
  const handleApprovePickup = () => {
    approvePickupMutation.mutate();
  };
  
  let documents = props.pickup?.pickupFullData.pickupRequest?.documents?.map((image) => ({
    src: image.storageUrl,
    alt: "Gallery Image",
    type: "Scan 01"
  }))

  return (
    <SlideOutSidebar show={props.isOpen} onClose={props.onClose} header={"BOL Pictures: " + props.pickup?.pickup.pickupNumber}>
      <div className="xgs-pickup-doc-viewer-info">
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={pickup} alt="Pickup #" />
          <span>Pickup #:</span>&nbsp;{props.pickup?.pickup.pickupNumber}
        </div>
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={consignee} alt="Consignee" />
          <span>Consignee:</span>&nbsp;{props.pickup?.consignee.name}
        </div>
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={phone} alt="Cons. Phone" />
          <span>Cons. Phone:</span>&nbsp;{props.pickup?.pickupFullData.pickupRequest.consigneePhone}
        </div>
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={shipper} alt="Shipper" />
          <span>Shipper:</span>&nbsp;{props.pickup?.shipper.name}
        </div>
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={driver} alt="Driver" />
          <span>Driver:</span>&nbsp;{props.pickup?.pickup.driverName}
        </div>
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={origin} alt="Origin SC" />
          <span>Origin SC:</span>&nbsp;{props.pickup?.originServiceCenter.airportCode} - {props.pickup?.originServiceCenter.terminalName} &nbsp; <span>{props.pickup?.originServiceCenter.terminalNumber}</span>
        </div>
        {!!props.pickup?.destinationServiceCenter && <div className="xgs-pickup-doc-viewer-info__item">
          <img src={destination} alt="Destination SC" />
          <span>Destination SC:</span>&nbsp;{props.pickup?.destinationServiceCenter?.airportCode} - {props.pickup?.destinationServiceCenter?.terminalName} &nbsp; <span>{props.pickup?.destinationServiceCenter?.terminalNumber}</span>
        </div>}
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={items} alt="Items" />
          <span>Items:</span>&nbsp;{props.pickup?.items.count}
        </div>
        <div className="xgs-pickup-doc-viewer-info__item">
          <img src={weight} alt="Weight" />
          <span>Weight:</span>&nbsp;{props.pickup?.items.weight} lbs.
        </div>
      </div>
      {!documents?.length && <>No documents found</>}
      <div style={!documents?.length ? { display: "none" } : {}} className="xgs-pickup-doc-viewer">
        <div className="xgs-pickup-doc-viewer__images">
          {documents?.map((doc, idx) => {
            return (
              <div onClick={() => setActiveIndex(idx)} className="xgs-pickup-doc-viewer__images__item">
                <img src={doc.src} alt={doc.alt}/>
                <p>{doc.type}</p>
              </div>
            )
          })}
        </div>
        <div ref={imageContainer} id={"image-viewer-container"} style={{ width: "100%"}}></div>
        {!!imageContainer.current && !!documents?.length && <Viewer
          visible
          noClose
          noNavbar
          images={documents}
          container={imageContainer.current}
          defaultScale={1.3}
          attribute={false}
          activeIndex={activeIndex}
          changeable
          onChange={(_, index) => setActiveIndex(index)}
        />}
      </div>
      <div className="xgs-pickup-doc-viewer__buttons">
        <Button
          theme={ButtonThemes.blue}
          onClick={handleApprovePickup}
          spinner={approvePickupMutation.isLoading}
        >
          Confirm Probill
        </Button>
        <Button
          theme={ButtonThemes.gray}
          onClick={() => props.onClose()}
          disabled={approvePickupMutation.isLoading}
        >
          Cancel
        </Button>
      </div>
    </SlideOutSidebar>
  )
}
