
export default interface InvoiceGenerateState {
    requestStarted: boolean;
    requestCreator?: string | null;
    image: any;
  }
  
  export const initialInvoiceGenerateState: InvoiceGenerateState = {
    requestStarted: false,
    requestCreator: null,
    image: []
  };