import { useSelector } from "react-redux";
import ContentContainer from "../../templates/content-container/contentContainer";
import React, { useState } from "react";
import Table from "../../ui-components/table/table";
import { PickupApprovementFilter, PickupApprovementFilters } from "./filter/filter";
import { useInfiniteQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../app/data/apiQueryAdapter";
import { userSelector } from "../../slices/user/userSlice";
import { getPickupApprovalColumns } from "./getPickupApprovalColumns";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import { PickupsResponseContentModel } from "../../app/data/pickup/models";
import { PageResponseModel } from "../../app/data/common/models";
import "./pickupApproval.scss";
import { PickupDocumentViewer } from "./doc-viewer/documentViewer";

export const PickupApprovement = () => {
  const userState = useSelector(userSelector);
  const [filters, setFilters] = useState<PickupApprovementFilters>({});
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [sort, setSort] = useState("")
  let clearSorting: any = null;

  const {
    data: pickupData,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isError,
    error,
    isPreviousData,
    isLoading
  } = useInfiniteQuery(
    ['pickup_approvement', userState.activeTerminal?.id!, filters, sort],
    async ({ pageParam = 0 }) => {
      return await apiQueryAdapter.query<PageResponseModel<PickupsResponseContentModel>>("/pickups/billing", "GET", {
        params: {
          page: pageParam,
          completedDateFrom: filters.cdf?.toApiDateFormat(),
          completedDateTo: filters.cdt?.toApiDateFormat(),
          companyName: filters.cn,
          originTerminalCode: filters.o,
          trackingNumber: filters.tn,
          sort
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) =>
        !lastPage.data.last ? lastPage.data.pageable.pageNumber + 1 : null,
        keepPreviousData: true,
    }
  );

  const onSearch = (newFilters: any) => {
    setSelectedPickup(null);
    setFilters(newFilters);
  };

  const onSortByColumn = (
    columnIndex: number,
    order: boolean
  ) => {
    const columns = {
      0: "pickupNumber",
      1: "dateCompleted",
      3: "consigneeName",
      5: "shipperName"
    } as any;
    if (!columns[columnIndex]) return;
    setSort(`${columns[columnIndex]},${order ? "asc" : "desc"}`)
  };

  const tableData =
    pickupData?.pages.flatMap((page) => page.data.content) || [];

  const terminalAvailable = !!userState.activeTerminal?.id;

  const clearFilters = () => {
    clearSorting?.();
    setSort("");
  }

  return (
    <ContentContainer mods={{ "full-width": true }} title="Pickup Approval">
      {isError && <XGSErrorMessage>{error}</XGSErrorMessage>}
      {(!isError && terminalAvailable) && (
        <div className="xgs-pickup-approval">
          <PickupApprovementFilter onClear={clearFilters} isLoading={isFetching} onSearch={onSearch}/>
          <Table
            columns={getPickupApprovalColumns()}
            data={tableData}
            isLoading={isLoading || (isFetching && isPreviousData)}
            infiniteScroll
            cursorPointer
            onInfiniteScroll={() => fetchNextPage()}
            infiniteScrollHasNext={!pickupData?.pages.some(
              (page) => page.data.content.length !== 50
            )}
            keepTableOnLoading
            infiniteScrollLoading={isFetchingNextPage}
            rowHeight={56}
            responsive
            minTableHeight={320}
            onRowClicked={(data: any) => setSelectedPickup(data)}
            sorting={!!sort || true}
            onSortByColumn={onSortByColumn}
            sortingExcludeColumnIndexes={[2, 4, 6, 7, 8]}
            clearSorting={(f: any) => {
              clearSorting = f;
            }}
          />
        </div>
      )}
      <PickupDocumentViewer
        isOpen={!!selectedPickup}
        pickup={selectedPickup}
        onClose={() => setSelectedPickup(null)}
      />
    </ContentContainer>
  );
};
