import React, { useEffect, useCallback, memo } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { useHistory } from "react-router";
import "./filter.scss";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useDispatch, useSelector } from "react-redux";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { fetchAllTerminals } from "../../../slices/warehouse-inventory/warehouseInventorySlice";
import { getServiceCenters, serviceCentersSelector } from "../../../slices/service-centers/serviceCentersSlice";
import { userSelector } from "../../../slices/user/userSlice";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import queryString from "query-string";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";

function parseQueryString<T>(queryStringInput: string): T {
  return queryString.parse(queryStringInput, {
    arrayFormat: "bracket",
    parseBooleans: true,
  }) as T;
}

const enum Filters {
  ORIGIN = "o",
  DESTINATION = "d",
  DATE_FROM = "cdf",
  DATE_TO = "cdt",
  COMPANY_NAME = "cn"
}

export interface PickupApprovementFilters {
  o?: string | null;
  d?: string | null;
  cdf?: string | null;
  cdt?: string | null;
  cn?: string | null;
  tn?: string | null;
}

type PickupApprovementFilterProps = {
  sameRowButtons?: boolean;
  isLoading?: boolean;
  onSearch: (filters: PickupApprovementFilters) => void;
  onClear?: () => void;
};

function stringifyQueryString(queryParams: PickupApprovementFilters): string {
  return queryString.stringify(queryParams, {
    arrayFormat: "bracket",
    skipNull: true,
    skipEmptyString: true,
  });
}

export const PickupApprovementFilter: React.FC<PickupApprovementFilterProps> = memo(
  ({ onSearch, onClear, ...props }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const serviceCentersState = useSelector(serviceCentersSelector);
    const userState = useSelector(userSelector);

    const searchWithOriginTerminal = (filters: any) => {
      filters.originTerminalCode = userState.activeTerminal?.id!;
      onSearch(filters);
    }
  
    const getDestinationOptions = () => {
      return (
        serviceCentersState.destinations?.map(
          (destination): XGSSelectOption => ({
            label: destination.name,
            value: destination.id.toString(),
          })
        ) || []
      );
    };
  
    const getOriginOptions = () => {
      return (
        serviceCentersState.origins?.map(
          (origin): XGSSelectOption => ({
            label: origin.name,
            value: origin.id.toString(),
          })
        ) || []
      );
    };

    const getFiltersFromURL = useCallback(() => {
      const queryParams = parseQueryString<PickupApprovementFilters>(location.search);

      return queryParams;
    }, [location.search]);

    const currentFilters = getFiltersFromURL();

    const handleFilterChange = (key: keyof PickupApprovementFilters, value: any, triggerSearch?: boolean) => {
      const filters = getFiltersFromURL();
      filters[key] = value;
      history.push({ search: stringifyQueryString(filters) })
      triggerSearch && onSearch(filters);
    };

    const handleSearch = () => {
      const filters = getFiltersFromURL();
      onSearch(filters);
    };

    const handleClear = () => {
      history.push({ search: "" });
      searchWithOriginTerminal({})
      onClear && onClear();
    };

    useEffect(() => {
      dispatch(fetchAllTerminals());
    }, [dispatch]);

    useEffect(() => {
      handleSearch();
      // eslint-disable-next-line
    }, [location])

    const onDestinationChanged = (_origin: XGSSelectOption | null | undefined) => {
      handleFilterChange(Filters.DESTINATION, _origin?.value, true);
    };
  
    const onOriginChanged = (_origin: XGSSelectOption | null | undefined) => {
      handleFilterChange(Filters.ORIGIN, _origin?.value, true);
    };
  
    useEffect(() => {
      if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
    }, [dispatch, serviceCentersState]);

    useEffect(() => {
      const filters = getFiltersFromURL();
      onSearch(filters);
      // eslint-disable-next-line
    }, [])

    return (
      <div className="xgs-pickup-approval-filter">
        <div className="xgs-pickup-approval-filter__controls">
          <div className="xgs-pickup-approval-filter__controls__item">
            <LabeledTextInput
              value={currentFilters.tn || ""}
              label="Tracking Number:"
              placeholder="Pickup #, Probill #, BOL #"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              onChange={(e) => handleFilterChange("tn", e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSearch(currentFilters);
                }
              }}
            />
          </div>
          <div className="xgs-pickup-approval-filter__controls__item">
            <LabeledTextInput
              value={currentFilters.cn || ""}
              label="Company name:"
              placeholder="Shipper, Consignee"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              onChange={(e) => handleFilterChange("cn", e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSearch(currentFilters);
                }
              }}
            />
          </div>
          <div className="xgs-pickup-approval-filter__controls__item">
            <LabeledSelectInput
              isLoading={serviceCentersState.loading}
              onValueChange={onOriginChanged}
              value={getOriginOptions().find(o => o.value === currentFilters.o) || null}
              options={getOriginOptions()}
              label="Origin Terminal:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
          <div className="xgs-pickup-approval-filter__controls__item">
            <LabeledSelectInput
              isLoading={serviceCentersState.loading}
              onValueChange={onDestinationChanged}
              value={getDestinationOptions().find(o => o.value === currentFilters.d) || null}
              options={getDestinationOptions()}
              label="Destination Terminal:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
          <div className="xgs-pickup-approval-filter__controls__item">
            <LabeledDateRangeInput
              onStartChange={(value) => handleFilterChange(Filters.DATE_FROM, value, true)}
              onEndChange={(value) => handleFilterChange(Filters.DATE_TO, value, true)}
              start={currentFilters.cdf || ""}
              end={currentFilters.cdt || ""}
              label="Completion Date:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
            />
          </div>
          <div className="xgs-pickup-approval-filter__buttons xgs-pickup-approval-filter__buttons--small xgs-pickup-approval-filter__controls__item">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.gray} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
