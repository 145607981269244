import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ImageIndexerService from "../../app/data/image-indexer/imageIndexerService";
import { IState } from "..";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";
import { initialImageViewIndexState } from "./imageViewIndexState";
import { AcceptRequestModel } from "../../app/data/image-indexer/models";

const ImageIndexService = ImageIndexerService.getInstance();
export const imageViewIndexSlice = createSlice({
    name: "index-image",
    initialState: initialImageViewIndexState,
    reducers: {
        resetImageViewIndexState: (state) => initialImageViewIndexState,
        requestStarted: (state, { payload }) => {
            state.requestStarted = true;
            state.requestCreator = payload;
        },
        requestFailed: (state) => {
            state.requestStarted = false;
        },
        requestSucceed: (state) => {
            state.requestStarted = false;
        },
        storeImageDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                state.image = payload[0];  // If you expect only one item
            }
        },
        storeThumbnails: (state, { payload }) => {
            state.relatedThumbnails = payload
                .filter((obj: any) => obj !== null)
                .map((obj: any) => {
                    return {
                        thumbnailId: obj.ID,
                        thumbnailtype: obj.type,
                        thumbnailTypeId: obj.typeId,
                        thumbnail: obj.thumbnail? obj.thumbnail : null,
                        thumbnailstatus: obj.status,
                        thumbnailimage: obj.image ? obj.image : null, // Handle null images properly
                        thumbnailprobill: obj.probill,
                        indexUser: obj.indexUser,
                        docIndexDte: obj.docIndexDte,
                        docLockUser: obj.docLockUser,
                        docLockDate: obj.docLockDate
                    };
                });
        },
        storeAcceptReturnDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                const imageData = payload[0];
        
                // Direct fields
                state.image = {
                    docId: imageData.docId,
                    probill: imageData.probill,
                    docTypeId: imageData.docTypeId,
                    docStatId: imageData.docStatId,
                    docIndexUsr: imageData.docIndexUsr,
                    docIndexDte: imageData.docIndexDte,
                };
                if (imageData.indexData) {
                    state.image.indexData = {
                        MCUSNM: imageData.indexData.MCUSNM.trim(),
                        MCUSNO: imageData.indexData.MCUSNO,
                        PBBOL2: imageData.indexData.PBBOL2.trim(),
                        PBPO: imageData.indexData.PBPO.trim()
                    };
                }
            }
        },
        storeLockImageReturnDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                const imageData = payload[0];
                state.image = {
                    docId: imageData.docId,
                    docTypeId: imageData.docTypeId,
                    docStatId: imageData.docStatId,
                    docLockedUsr: imageData.docLockedUsr,
                    docLockDte: imageData.docLockDte,
                };
            }
        },
        storePassOrRejectImageReturnDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                const imageData = payload[0];
                state.image = {
                    docId: imageData.docId,
                    docTypeId: imageData.docTypeId,
                    docIndexUsr: imageData.docIndexUsr,
                    docIndexDte: imageData.docIndexDte,
                };
            }
        }
    }
})

export const {
    resetImageViewIndexState,
    requestStarted,
    requestFailed,
    requestSucceed,
    storeImageDetails,
    storeThumbnails,
    storeAcceptReturnDetails,
    storeLockImageReturnDetails,
    storePassOrRejectImageReturnDetails
} = imageViewIndexSlice.actions;

export const imageViewIndexSelector = (state: IState) => {
    return state.imageViewIndexer;
};

export const getRelatedImages = (
    probill: string,
    currentDocId: string
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("GET IMAGE FOR INDEXER"));

    try {
        const response = await ImageIndexService.getRelatedThumbnails(probill, currentDocId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeThumbnails(response.data));
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error; 
    }
};
export const acceptImages = (
    acceptModel: AcceptRequestModel
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("ACCEPT_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.acceptImageClick(acceptModel);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeAcceptReturnDetails(response.data));
            toast.success(`Image ${acceptModel.docId} got indexed`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error; 
    }
};
export const multiProImages = (
    multiProModel: AcceptRequestModel
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("MULTIPRO_IMAGE_REQUEST"));

    try {
        const response = await ImageIndexService.multiProImageClick(multiProModel);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeAcceptReturnDetails(response.data));
            toast.info(`Image ${multiProModel.docId} multiprod`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error; 
    }
};
export const lockImages = (
    docId: string
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("LOCK_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.lockImageClick(docId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeLockImageReturnDetails(response.data));
            toast.info(`Image ${docId} is re-indexed`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error; 
    }
};
export const passImages = (
    docId: string
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("PASS_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.passImageClick(docId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storePassOrRejectImageReturnDetails(response.data));
            toast.info(`Image ${docId} got passed`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error; 
    }
};
export const rejectImages = (
    docId: string
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("REJECT_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.rejectImageClick(docId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storePassOrRejectImageReturnDetails(response.data));
            toast.info(`Image ${docId} got rejected`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
const imageViewIndexReducer = imageViewIndexSlice.reducer;
export default imageViewIndexReducer;