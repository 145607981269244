import axios from "axios";
import api, { ApiResponse } from "./../api";
import {
  DriversResponseModel,
  PickupAssignRequestModel,
  PickupCancelRequestModel,
  PickupLogItem,
  PickupRequestModel,
  PickupRescheduleModel,
  PickupsLogFilterModel,
  PickupsResponseContentModel,
  PickupsResponseModel,
  PickupSummaryDto
} from "./models";
import { PickupStatus } from "../common/route";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_PICKUPS: "get_pickups",
  GET_DRIVERS: "get_drivers",
  GET_DRIVER: "get_driver",
  GET_PICKUP_LOG: "get_pickup_log",
  GET_PICKUP: "get_pickup",
};

class PickupService {
  private source: any;
  private static instance: PickupService;
  private constructor() {
    this.source = {};
  }
  static getInstance(): PickupService {
    if (!PickupService.instance) {
      PickupService.instance = new PickupService();
    }
    return PickupService.instance;
  };

  public cancelRequest = (requestId: string) => {
    this.source[requestId] && this.source[requestId].cancel();
  }

  public createPickup = async (
    request: PickupRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/pickups", request);
  };

  public getPickups = async (
    terminal: number | null,
    statuses: PickupStatus[],
    expiredOnly: boolean,
    search: string,
    lastIds?: string
  ): Promise<ApiResponse<PickupsResponseModel>> => {
    const params = new URLSearchParams();
    terminal && params.append("terminalCode", terminal.toString());
    expiredOnly && params.append("expiredOnly", expiredOnly.toString());
    search && params.append("search", search);
    if (statuses?.length > 0) params.append("statuses", statuses.toString());
    lastIds && params.append("lastIds", lastIds);
    this.cancelRequest(REQUEST_ID.GET_PICKUPS);
    this.source[REQUEST_ID.GET_PICKUPS] = axios.CancelToken.source();
    return await api.get("/pickups", { params, cancelToken: this.source[REQUEST_ID.GET_PICKUPS].token });
  };

  public changePickup = async (
    request: PickupAssignRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.put("/pickups", request);
  };

  public changePickups = async (
    request: PickupAssignRequestModel[]
  ): Promise<ApiResponse<any>> => {
    return await api.put("/pickups/drivers", request);
  };

  public getDrivers = async (
    zip: string,
    lastIds?: string
  ): Promise<ApiResponse<DriversResponseModel>> => {
    const params = new URLSearchParams();
    params.append("postalCode", zip);
    lastIds && params.append("lastIds", lastIds);
    this.cancelRequest(REQUEST_ID.GET_DRIVERS);
    this.source[REQUEST_ID.GET_DRIVERS] = axios.CancelToken.source();
    return await api.get("/pickups/drivers", { params, cancelToken: this.source[REQUEST_ID.GET_DRIVERS].token });
  };

  public getDriver = async (
    id: number
  ): Promise<ApiResponse<any>> => {
    this.cancelRequest(REQUEST_ID.GET_DRIVER);
    this.source[REQUEST_ID.GET_DRIVER] = axios.CancelToken.source();
    return await api.get(`/pickups/driverBy/${encodeURIComponent(id)}`, { cancelToken: this.source[REQUEST_ID.GET_DRIVER].token });
  };

  public getPickupLog = async (
    pickupId: string
  ): Promise<ApiResponse<PickupLogItem[]>> => {
    const params = new URLSearchParams();
    params.append("pickupId", pickupId);
    this.cancelRequest(REQUEST_ID.GET_PICKUP_LOG);
    this.source[REQUEST_ID.GET_PICKUP_LOG] = axios.CancelToken.source();
    return await api.get("/pickups/activity-log", { params, cancelToken: this.source[REQUEST_ID.GET_PICKUP_LOG].token });
  };

  public getPickupsLog = async (
    filter: PickupsLogFilterModel,
    page?: number
  ): Promise<ApiResponse<PageResponseModel<PickupLogItem>>> => {
    const params = new URLSearchParams();
    for (const property in filter) {
      if (!property) continue;
      filter[property as keyof PickupsLogFilterModel] && params.append(property, filter[property as keyof PickupsLogFilterModel] || "");
    }
    page && params.append("page", page.toString());
    this.cancelRequest(REQUEST_ID.GET_PICKUPS_LOG);
    this.source[REQUEST_ID.GET_PICKUPS_LOG] = axios.CancelToken.source();
    return await api.get("/pickups/all/activity-log", {params, cancelToken: this.source[REQUEST_ID.GET_PICKUPS_LOG].token });
  };

  public cancelPickup = async (
    request: PickupCancelRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/pickups/cancel", request);
  };

  public reschedulePickup = async (
    pickupId: string,
    payload: PickupRescheduleModel
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/pickups/reschedule/${pickupId}`, payload);
  };

  public completePickup = async (
    pickupId: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/pickups/complete", { pickupId });
  };

  public async getInboundPickups(
    originTerminalCode?: number,
    destinationTerminalCode?: number | null,
    statuses?: string[],
    pageNumber: number = 0,
    pageSize: number = 50
  ): Promise<ApiResponse<{ content: PickupsResponseContentModel[]; totalPages: number; totalElements: number;}>> {
    const params: any = {
        page: pageNumber,
        size: pageSize,
    };

    params.originTerminalCode = originTerminalCode;

    if (destinationTerminalCode) {
        params.destinationTerminalCode = destinationTerminalCode;
    }

    if (statuses && statuses.length > 0) {
      params.statuses = statuses.join(",");
    }

    return await api.get(`/pickups/inbound`, { params });
  }

  public async getInboundPickupsSummary(origin: number): Promise<ApiResponse<PickupSummaryDto[]>> {
      const params = new URLSearchParams();
      params.append("originTerminalCode", origin.toString());
      return await api.get(`/pickups/inbound/summary`, { params });
  }

  public getPickup = async (
    pickupNumber: string,
  ): Promise<ApiResponse<PickupsResponseContentModel>> => {
    this.cancelRequest(REQUEST_ID.GET_PICKUP);
    this.source[REQUEST_ID.GET_PICKUP] = axios.CancelToken.source();
    return await api.get(`/pickups/${pickupNumber}`, {cancelToken: this.source[REQUEST_ID.GET_PICKUP].token });
  };
};

export default PickupService;
