import React, { useState } from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import "./invoiceGenerator.scss";
import { Routes } from "../../../app/route/RoutesConfig";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateInvoice, invoiceGenerateSelector } from "../../../slices/imaging/invoiceGenerateSlice";
import InvoiceGenerateState from "../../../slices/imaging/invoiceGenerateState";
import { InvoiceGenerateModel, invoiceFormValidationSchema } from "../../../app/data/invoice-generator/models";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";
import { Form, Formik, FormikProps } from "formik";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";

const InvoiceGenerator: React.FC<{}> = (props) => {
    const invoiceGenerateState: InvoiceGenerateState = useSelector(invoiceGenerateSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [viewAsBalance, setViewAsBalance] = useState(false);
    const [authorization, setAuthorization] = useState(false);
    const [billOfLanding, setBillOfLanding] = useState(false);
    const [miscellaneous, setMiscellaneous] = useState(false);
    const [proofOfDelivery, setProofOfDelivery] = useState(false);

    const initialValues: InvoiceGenerateModel = {
        probills: "",
        isViewBalance: false,
        isAth: false,
        isBol: false,
        isMsc: false,
        isPod: false
    };

    const createClick = async (data: InvoiceGenerateModel) => {
            const acceptModel = getCreateRequestModel(data);
            await generateInvoiceClick(acceptModel);
            history.push(Routes.documents.generateView)
    };
    const getCreateRequestModel = (data: InvoiceGenerateModel) => {
        let createRequestModel: InvoiceGenerateModel = {
            probills: data.probills ? data.probills : "",
            isViewBalance: viewAsBalance,
            isAth: authorization,
            isBol: billOfLanding,
            isMsc: miscellaneous,
            isPod: proofOfDelivery
        };
        return createRequestModel;
    }
    const generateInvoiceClick = async (acceptModel: InvoiceGenerateModel) => {
        try {
            if (acceptModel.probills !== "") {
                await dispatch(generateInvoice(acceptModel));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <ContentContainer title="Invoice Generator">
            <div className="xgs-invoice-generator">
                <div className="xgs-invoice-generator__instruction">
                    Please, put list of probills, separated by comma, fill the form and click Create button to create new invoices for probills.
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={invoiceFormValidationSchema}
                    onSubmit={createClick}
                >
                    {(props: FormikProps<InvoiceGenerateModel>) => (
                        <Form>
                            <div className="xgs-invoice-generator__input-area">
                                <XGSFormTextarea
                                    name="probills"
                                    value={props.values.probills}
                                    label="Probill:"
                                    required={true}
                                    rows={3}
                                    className="xgs-invoice-generator__input-area__text-area"
                                />
                            </div>
                            <div className="xgs-invoice-generator__switch-primary ">
                                <XGSToggle
                                    onChange={setViewAsBalance}
                                    checked={viewAsBalance}
                                    label=""
                                />
                                <span className="xgs-invoice-generator__switch-secondary">View as balance due invoice</span>
                            </div>
                            <div className="xgs-invoice-generator__checkbox-collection ">
                                <div className="xgs-invoice-generator__checkbox-collection__primary">Please select document types to include</div>
                                <div className="xgs-invoice-generator__checkbox-collection__secondary">
                                    <XGSCheckbox
                                        name="authorization"
                                        onChange={() => setAuthorization(!authorization)}
                                        checked={authorization}
                                    >
                                        Authorization
                                    </XGSCheckbox>
                                    <XGSCheckbox
                                        name="billoflanding"
                                        onChange={() => setBillOfLanding(!billOfLanding)}
                                        checked={billOfLanding}
                                    >
                                        Bill of Landing
                                    </XGSCheckbox>
                                </div>
                                <div className="xgs-invoice-generator__checkbox-collection__third">
                                    <XGSCheckbox
                                        name="miscellaneous"
                                        onChange={() => setMiscellaneous(!miscellaneous)}
                                        checked={miscellaneous}
                                    >
                                        Miscellaneous
                                    </XGSCheckbox>
                                    <XGSCheckbox
                                        name="proofofdelivery"
                                        onChange={() => setProofOfDelivery(!proofOfDelivery)}
                                        checked={proofOfDelivery}
                                    >
                                        Proof of Delivery
                                    </XGSCheckbox>

                                </div>
                            </div>
                            <Button
                                theme={ButtonThemes.blue}
                                type="submit"
                                disabled={!props.isValid || !props.values.probills}
                                spinner={invoiceGenerateState.requestStarted && invoiceGenerateState.requestCreator === "START_INVOICE_GENERATION"}
                            >
                                Create
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </ContentContainer>
    )
};
export default InvoiceGenerator;