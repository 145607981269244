import React from "react";
import { Link } from "react-router-dom";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { createColumnHelper } from "@tanstack/react-table";

const getFormattedDate = (date: number) => {
  if (!date || !(+date)) return "";
  let dateStr = date.toString();
  const abrevations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", " Sep", "Oct", "Nov", "Dec"];
  return `${abrevations[+dateStr.slice(4, 6) - 1]} ${dateStr.slice(6)}, ${dateStr.slice(0, 4)}`;
}

const getFormattedTime = (time: number) => {
  let timeStr = `000000${time}`.slice(-6);
  let hour = +timeStr.slice(0, 2);
  if (hour < 12) return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} am`;
  else return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} pm`;
}

const tagsMap: any = {
  "Appointment Needed": {
    icon: XGSIcons.faCalendarTimes,
    style: {
      color: "#C43232",
      backgroundColor: "#DD3F3F33"
    }
  },
  "Appointment Set": {
    icon: XGSIcons.faCalendarCheck,
    style: {
      color: "#14870A",
      backgroundColor: "#5DC75433"
    },
    formatDetails: (details: any) => {
      if (details && +details) return `(${getFormattedDate(details)})`;
      else return "";
    }
  },
  "Standing Appointment": {
    icon: XGSIcons.faCalendar,
    getStyles: (details: any) => {
      if (details && details.startTime && (details.startTime === details.endTime)) {
        return {
          color: "#C43232",
          backgroundColor: "#DD3F3F33"
        }
      } else return {
        color: "#14870A",
        backgroundColor: "#5DC75433"
      }
    },
    formatDetails: (details: any) => {
      if (details && details.startTime) {
        return `(${getFormattedTime(details.startTime)} - ${getFormattedTime(details.endTime)})`;
      }
      else return "";
    }
  },
  "Residential": {
    icon: XGSIcons.faHome,
    style: {
      color: "#B89311",
      backgroundColor: "#E8D15A33"
    }
  },
  "Driver Collect": {
    icon: XGSIcons.faDollarSign,
    style: {
      color: "#B89311",
      backgroundColor: "#E8D15A33"
    }
  }
}

const getTags = (tagTypes: any) => {
  if (!tagTypes || !tagTypes.length) return "";
  return <div className="plan-probills-tags-list">{
    tagTypes.map((tagObj: any, tagIdx: any) => {
      let tagType = tagObj.type;
      let tagDetails = tagObj.details;
      if (tagsMap[tagType]) {
        let styles = (tagsMap[tagType].getStyles) ? tagsMap[tagType].getStyles(tagDetails) : tagsMap[tagType].style;
        return <div
          key={`${tagIdx}_t`}
          className="plan-probills-tag-conatiner"
          style={{ ...styles }}>
          <div className="plan-probills-tag">
            <XGSIcon
              icon={tagsMap[tagType].icon} />
            <div className="plan-probills-tag-type">{tagType}</div>
          </div>
          {tagsMap[tagType].formatDetails && <div className="plan-probills-tag-type">{tagsMap[tagType].formatDetails(tagDetails)}</div>}
          {!tagsMap[tagType].formatDetails && <div className="plan-probills-tag-type">{tagDetails}</div>}
        </div>
      } else {
        return "";
      }
    })
  }
  </div>

}
const getFormattedConsignee = (rawValue: string) => {
  if (rawValue) {
    let splitValues = rawValue.split("|");
    if (splitValues && splitValues.length === 2) {
      return <div>{splitValues[0]}{" ("}<span>
        {splitValues[1]}
        </span>{")"}</div>
    } else {
      return rawValue;
    }
  } else {
    return ""
  }
}

const columnHelper = createColumnHelper<any>();

const getPlanProbillsColumns = (
) => [
    columnHelper.display({
      id: "selection",
      header: "",
      cell: ({ row }: any) => (
        <div>
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            indeterminate={undefined}
          />
        </div>
      ),
      size: 30,
    }),
    columnHelper.accessor("probillNumber", {
      id: "probillNumber",
      header: "Probill",
      cell: (cellProps) => (
        <Link
          className="xgs-table-link-cell"
          to={`/shipments/${cellProps.getValue()}`}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          {cellProps.getValue()}
        </Link>
      ),
      filterFn: "includesString",
      size: 80,
    }),
    columnHelper.accessor("staticRouteName", {
      id: "staticRouteName",
      header: "Static Route",
      meta: {
        filterVariant: "select",
      },
      size: 110,
    }),
    columnHelper.accessor("consignee", {
      id: "consignee",
      header: "Consignee",
      cell: (cellProps) => (
        <div>{getFormattedConsignee(cellProps.getValue())}</div>
      ),      
      size: 120,
    }),
    columnHelper.accessor("consigneeAddress", {
      id: "consigneeAddress",
      header: "Street Address",      
      size: 150,
    }),
    columnHelper.accessor("consigneeCity", {
      id: "consigneeCity",
      header: "City",      
      meta: {
        filterVariant: "select",
      },
      size: 100,
    }),
    // columnHelper.accessor("consigneeState", {
    //   id: "consigneeState",
    //   header: "State",    
    //   meta: {
    //     filterVariant: "select",
    //   },
    //   size: 60,
    // }),
    columnHelper.accessor("consigneeZip", {
      id: "consigneeZip",
      header: "Zip Code",
      meta: {
        filterVariant: "select",
      },
      size: 90,
    }),
    columnHelper.accessor("probillItems", {
      id: "probillItems",
      header: "Pieces",
      enableColumnFilter: false,
      size: 70,
    }),
    columnHelper.accessor("probillWeight", {
      id: "probillWeight",
      header: "Weight",
      cell: (cellProps) => (
        <>
          {cellProps.getValue().toLocaleString('en', { useGrouping: true })}
        </>
      ),
      enableColumnFilter: false,
      size: 70,
    }),
    columnHelper.accessor("etaDate", {
      id: "etaDate",
      header: "ETA",
      cell: (cellProps) => (
        <>
          {getFormattedDate(cellProps.getValue())}
        </>
      ),      
      meta: {
        filterVariant: "select",
        filterLabelFormatter: getFormattedDate,
      },
      size: 100,
    }),
    columnHelper.accessor("probillStatus", {
      id: "probillStatus",
      header: "Status",
      cell: (cellProps) => (
        <div style={{
          display: "flex",
          height: "auto",
          padding: "4px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          color: "#7936D0",
          backgroundColor: "#9747FF33",
          borderRadius: "8px"

        }}>
          {cellProps.getValue()}
        </div >
      ),      
      meta: {
        filterVariant: "select",
      },      
      size: 130,
    }),
    columnHelper.accessor("tags", {
      id: "tags",
      header: "Accessorials",
      cell: (cellProps) => (
        <div>{getTags(cellProps.getValue())}</div>
      ),
      enableColumnFilter: false,
      enableSorting: false,
      size: 180,
    }),
  ];

export default getPlanProbillsColumns;
