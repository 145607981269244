import { createSlice } from "@reduxjs/toolkit";
import { initialImageIndexState } from "./imageIndexState";
import { AppThunk } from "../../app/store";
import ImageIndexerService from "../../app/data/image-indexer/imageIndexerService";
import { IState } from "..";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";
import { AcceptRequestModel, RecordNumberRequestModel, SearchRequestModel } from "../../app/data/image-indexer/models";

const ImageIndexService = ImageIndexerService.getInstance();
export const imageIndexSlice = createSlice({
    name: "index-image",
    initialState: initialImageIndexState,
    reducers: {
        requestStarted: (state, { payload }) => {
            state.requestStarted = true;
            state.requestCreator = payload;
        },
        requestFailed: (state) => {
            state.requestStarted = false;
        },
        requestSucceed: (state) => {
            state.requestStarted = false;
        },
        storeImageDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                state.image = payload[0];  // If you expect only one item
            }
        },
        storeRecordNumber: (state, { payload }) => {
            if (payload) {
                state.recordNumber = payload;
            }
        },
        storeThumbnails: (state, { payload }) => {
            state.relatedThumbnails = payload
                .filter((obj: any) => obj !== null)
                .map((obj: any) => {
                    return {
                        thumbnailId: obj.ID,
                        thumbnailtype: obj.type,
                        thumbnail: obj.thumbnail ? obj.thumbnail : null,
                        thumbnailstatus: obj.status,
                        thumbnailimage: obj.image ? obj.image : null, // Handle null images properly
                        thumbnailprobill: obj.probill
                    };
                });
        },
        restoreThumbnails: (state) => {
            state.relatedThumbnails = [];
        },
        restoreImageDetails: (state) => {
            state.image = [];
        },
        storeAcceptReturnDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                const imageData = payload[0];

                // Direct fields
                state.image = {
                    docId: imageData.docId,
                    probill: imageData.probill,
                    docTypeId: imageData.docTypeId,
                    docIndexUsr: imageData.docIndexUsr,
                    docIndexDte: imageData.docIndexDte
                };
                if (imageData.indexData) {
                    state.image.indexData = {
                        MCUSNM: imageData.indexData.MCUSNM.trim(),
                        MCUSNO: imageData.indexData.MCUSNO,
                        PBBOL2: imageData.indexData.PBBOL2.trim(),
                        PBPO: imageData.indexData.PBPO.trim()
                    };
                }
            }
        },
        storeLockImageReturnDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
                const imageData = payload[0];
                state.image = {
                    docId: imageData.docId,
                    docTypeId: imageData.docTypeId,
                    docLockedUsr: imageData.docLockedUsr,
                    docLockDte: imageData.docLockDte,
                };
            }
        },
        storePassOrRejectImageReturnDetails: (state, { payload}) => {
            if (payload && payload.length > 0) {
                const imageData = payload[0];
                state.image = {
                    docId: imageData.docId,
                    docTypeId: imageData.docTypeId,
                    docIndexUsr: imageData.docIndexUsr,
                    docIndexDte: imageData.docIndexDte
                };
            }
        },
        setCurrentThumbnail: (state, { payload }) => {
            state.image.imagethumbnail = payload;
        },
        setCurrentImageUrl: (state, { payload }) => {
            state.image.savedImage = payload;
        },
        resetImageIndexState: (state) => initialImageIndexState,
        storeSearchModel: (state, {payload}) => {
            state.storeSearchRequestModel = payload;
        }
    }
})

export const {
    requestStarted,
    requestFailed,
    requestSucceed,
    storeImageDetails,
    storeThumbnails,
    restoreThumbnails,
    storeRecordNumber,
    storeAcceptReturnDetails,
    storeLockImageReturnDetails,
    storePassOrRejectImageReturnDetails,
    setCurrentThumbnail,
    setCurrentImageUrl,
    resetImageIndexState,
    restoreImageDetails,
    storeSearchModel
} = imageIndexSlice.actions;

export const imageIndexSelector = (state: IState) => {
    return state.imageIndexer;
};

export const getImages = (
    searchModel: SearchRequestModel,
    caller?: string,
): AppThunk => async (dispatch, getState) => {
    dispatch(storeSearchModel(searchModel));
    if (caller === "continue")
        dispatch(requestStarted("GET CONTINUE IMAGE FOR INDEXER"));
    else
        dispatch(requestStarted("GET IMAGE FOR INDEXER"));

    try {
        const response = await ImageIndexService.getImages(searchModel);
        const statePayload = { ...getState().imageIndexer.storeSearchRequestModel };
        if (response.ok()) {
            dispatch(requestSucceed());
            if(JSON.stringify(statePayload) === JSON.stringify(searchModel))
                dispatch(storeImageDetails(response.data.fullImage));
            if(response.data.failedMsg)
                toast.error(response.data.failedMsg);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const getRecordNumber = (
    recordNumModel: RecordNumberRequestModel
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("GET NUMBER OF RECORDS AVAILABLE"));

    try {
        const response = await ImageIndexService.getRecordNumber(recordNumModel);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeRecordNumber(response.data));
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const getRelatedImages = (
    probill: string,
    currentDocId: string
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("GET IMAGE FOR INDEXER"));

    try {
        const response = await ImageIndexService.getRelatedThumbnails(probill, currentDocId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeThumbnails(response.data));
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const restoreThumbnail = (): 
AppThunk => async (dispatch) => {
    dispatch(restoreThumbnails());
}
export const acceptImages = (
    acceptModel: AcceptRequestModel,
    thumbnail: string,
    imageUrl: string,
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("ACCEPT_INDEXING_IMAGES"));

    try {
        setCurrentThumbnail(thumbnail);
        setCurrentImageUrl(imageUrl);
        const response = await ImageIndexService.acceptImageClick(acceptModel);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeAcceptReturnDetails(response.data));
            dispatch(setCurrentThumbnail(thumbnail));
            dispatch(setCurrentImageUrl(imageUrl));
            toast.success(`Image ${acceptModel.docId} got indexed`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const multiProImages = (
    multiProModel: AcceptRequestModel,
    thumbnail: string,
    imageUrl: string,
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("MULTIPRO_IMAGE_REQUEST"));

    try {
        const response = await ImageIndexService.multiProImageClick(multiProModel);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeAcceptReturnDetails(response.data));
            dispatch(setCurrentThumbnail(thumbnail));
            dispatch(setCurrentImageUrl(imageUrl));
            toast.info(`Image ${multiProModel.docId} multiprod`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const lockImages = (
    docId: number,
    thumbnail: string,
    imageUrl: string,
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("LOCK_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.lockImageClick(docId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeLockImageReturnDetails(response.data));
            dispatch(setCurrentThumbnail(thumbnail));
            dispatch(setCurrentImageUrl(imageUrl));
            toast.info(`Image ${docId} is re-indexed`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const passImages = (
    docId: number,
    thumbnail: string,
    imageUrl: string,
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("PASS_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.passImageClick(docId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storePassOrRejectImageReturnDetails(response.data));
            dispatch(setCurrentThumbnail(thumbnail));
            dispatch(setCurrentImageUrl(imageUrl));
            toast.info(`Image ${docId} got passed`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
};
export const rejectImages = (
    docId: number,
    thumbnail: string,
    imageUrl: string,
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("REJECT_INDEXING_IMAGES"));

    try {
        const response = await ImageIndexService.rejectImageClick(docId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storePassOrRejectImageReturnDetails(response.data));
            dispatch(setCurrentThumbnail(thumbnail));
            dispatch(setCurrentImageUrl(imageUrl));
            toast.info(`Image ${docId} got rejected`);
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response;
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error;
    }
    
};
const imageIndexReducer = imageIndexSlice.reducer;
export default imageIndexReducer;