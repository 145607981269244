import React from "react";
import { cn } from "../../../services/common/className";
import { TagColor } from "../../../app/data/common/tagColor";

import "./tag.scss";

export interface TagProps {
  children: any;
  mods?: {
    color?: TagColor;
    uppercase?: boolean;
    flex?: boolean;
  };
  mix?: string;
  onClick?: () => void;
}

const Tag: React.FC<TagProps> = ({ mods, mix, children, onClick }) => {
  return (
    <div className={cn("tag")({ ...mods, clickable: !!onClick }, mix)} onClick={ onClick }>
      {children}
    </div>
  );
}

export default Tag;