import { SearchRequestModel } from "../../app/data/image-indexer/models";

export default interface ImageIndexState {
    requestStarted: boolean;
    requestCreator?: string | null;
    recordNumber: any;
    image: any; 
    relatedThumbnails: any;
    storeSearchRequestModel: SearchRequestModel;
  }
  export const defaultSearchRequestModel: SearchRequestModel = {
    docId: 0,
    docType: "",
    fromDate: "",
    indexDate: "",
    indexTime: 0,
    nextOrPrevious: "",
    toDate: "",
  };  
  export const initialImageIndexState: ImageIndexState = {
    requestStarted: false,
    requestCreator: null,
    recordNumber: [],
    image: [],
    relatedThumbnails: [],
    storeSearchRequestModel: defaultSearchRequestModel,
  };