import React, { useState } from "react"
import XGSSelect, { XGSSelectOption } from "../../../xgs-select/xgsSelect";

export interface SelectColumnFilterProps {
    column: any
}
export const SelectColumnFilter: React.FC<SelectColumnFilterProps> = ({
    column
}) => {
  const { filterLabelFormatter } = column.columnDef.meta ?? {};

  const [value, setValue] = useState<XGSSelectOption>();
  const options: XGSSelectOption[] = [];

  column.getFacetedUniqueValues().forEach((value: number, key: string) => {
    options.push({ value: key, label: `${filterLabelFormatter ? filterLabelFormatter(key) : key} (${value})` });
  }); 

  return (
    <XGSSelect
      id='column-filter-select'
      value={value}
      options={options}
      disabled={false}
      onValueChange={(value) => {
        column.setFilterValue(value?.value || undefined);
        setValue(value!)
      }}
      isClearable
    />
  );
};