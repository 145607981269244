import { useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import React, { useState, useEffect } from "react";
import Table from "../../../ui-components/table/table";
import { InboundPickupsFilter, InboundPickupsFilters } from "./filter/filter";
import PickupDetails from "../../common/pickup-details/pickupDetails";
import { useInfiniteQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import { userSelector } from "../../../slices/user/userSlice";
import "./inboundPickups.scss";
import { getInboundPickupColumns } from "./getInboundPickupColumns";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { getPickupDetails } from "../../../services/pickups";
import { PageResponseModel } from "../../../app/data/common/models";
import { PickupsResponseContentModel } from "../../../app/data/pickup/models";

export const InboundPickups = () => {
  const userState = useSelector(userSelector);
  const [filters, setFilters] = useState<InboundPickupsFilters>({});
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [page, setPage] = useState(0);

  const {
    data: inboundData,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isError,
    error,
    isLoading,
    isPreviousData
  } = useInfiniteQuery(
    ['pickups', userState.activeTerminal?.id!, filters],
    async ({ pageParam = 0 }) => {
      return await apiQueryAdapter.query<PageResponseModel<PickupsResponseContentModel>>("/pickups/inbound", "GET", {
        params: {
          page: pageParam,
          originTerminalCode: filters.o,
          destinationTerminalCode: filters.d,
          statuses: !!filters.s ?filters.s.join(",") : ""
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) =>
        lastPage.data.content.length === 50 ? page + 1 : null,
        keepPreviousData: true,
    }
  );

  useEffect(() => {
    setPage(0);
  }, [userState.activeTerminal?.id])

  const onSearch = (newFilters: any) => {
    setSelectedPickup(null);
    setFilters(newFilters);
    setPage(0);
  };

  const pickupDetails = selectedPickup && getPickupDetails(selectedPickup);

  const tableData =
    inboundData?.pages.flatMap((page) => page.data.content) || [];

  const terminalAvailable = !!userState.activeTerminal?.id;

  return (
    <ContentContainer mods={{ "full-width": true }} title="Outbound Planning List">
      {(!terminalAvailable) && (
          <XGSErrorMessage>
            <div>
              You do not have assigned terminals.
              Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
            </div>
          </XGSErrorMessage>
      )}
      {isError && <XGSErrorMessage>{error}</XGSErrorMessage>}
      {(!isError && terminalAvailable) && (
        <div className="xgs-inbound-pickups">
          <InboundPickupsFilter isLoading={isFetching} onSearch={onSearch}/>
          <Table
            columns={getInboundPickupColumns()}
            data={tableData}
            isLoading={isLoading || (isFetching && isPreviousData)}
            infiniteScroll
            cursorPointer
            onInfiniteScroll={() => fetchNextPage()}
            infiniteScrollHasNext={!inboundData?.pages.some(
              (page) => page.data.content.length !== 50
            )}
            keepTableOnLoading
            infiniteScrollLoading={isFetchingNextPage}
            rowHeight={56}
            responsive
            minTableHeight={320}
            onRowClicked={(data: any) => setSelectedPickup(data)}
          />
        </div>
      )}
      <PickupDetails
        readonly
        isOpen={!!selectedPickup}
        pickup={pickupDetails}
        onClose={() => setSelectedPickup(null)}
      />
    </ContentContainer>
  );
};
