
export default interface ImageViewState {
    requestStarted: boolean;
    requestFailed: boolean;
    requestCreator?: string | null;
    thumbnails: any;
    payload: any
    paginationDetails: any
  }
  
  export const initialImageViewsState: ImageViewState = {
    requestStarted: false,
    requestFailed: false,
    requestCreator: null,
    thumbnails: [],
    payload: [],
    paginationDetails: []
  };
  