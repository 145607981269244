import api, { ApiResponse } from "./../api";
class InvoiceGenratorService {
    private static instance: InvoiceGenratorService;
    private constructor() { }

    static getInstance(): InvoiceGenratorService {
        if (!InvoiceGenratorService.instance) {
            InvoiceGenratorService.instance = new InvoiceGenratorService();
        }
        return InvoiceGenratorService.instance;
    }
    public generateInvoiceClick = async (
        payload: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/invoice-view/invoice-create", payload);
    };
}
export default InvoiceGenratorService;