
export default interface ImageViewIndexState {
    requestStarted: boolean;
    requestCreator?: string | null;
    image: any; 
    relatedThumbnails: any;
  }
  
  export const initialImageViewIndexState: ImageViewIndexState = {
    requestStarted: false,
    requestCreator: null,
    image: [],
    relatedThumbnails: []
  };