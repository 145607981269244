import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { initialImageViewsState } from "./imageViewState";
import ImageViewerService from "../../app/data/image-viewer/imageViewerService";
import { IState } from "..";
import { toast } from "react-toastify";
import { DOCUMENT_STAT_ID_ACTIONS, DOCUMENT_TYPE_ID_ACTIONS } from "../../features/imaging/image-viewer/common";
import { ImageSearchRequestModel, ThumbnailUpdateModel } from "../../app/data/image-viewer/model";

const ImageViewService = ImageViewerService.getInstance();
export const imageViewSlice = createSlice({
    name: "records",
    initialState: initialImageViewsState,
    reducers: {
        resetImageViewState: (state) => initialImageViewsState,
        requestStarted: (state, { payload }) => {
            state.requestFailed = false;
            state.requestStarted = true;
            state.requestCreator = payload;
        },
        requestFailed: (state) => {
            state.requestFailed = true;
            state.requestStarted = false;
        },
        requestSucceed: (state) => {
            state.requestFailed = false;
            state.requestStarted = false;
        },
        storeThumbnails: (state, { payload }) => {
            state.thumbnails = payload
                .filter((obj: any) => obj !== null)
                .map((obj: any) => {
                    return {
                        Id: obj.ID,
                        label: obj.probill,
                        docTypeId: obj.docTypeId,
                        type: obj.type,
                        thumbnail: obj.thumbnail,
                        status: obj.status,
                        indexDate: obj.indexDate,
                        imageUrl: obj.imageUrl,
                        probill: obj.probill,
                        indexUser: obj.indexUser,
                        lockUser: obj.lockUser,
                        lockDate: obj.lockDate,
                        indexData: obj.indexData
                    };
                });
        },
        appendAndStoreThumbnails: (state, { payload }) => {
            const existingIds = new Set(state.thumbnails.map((thumbnail: any) => thumbnail.Id));
            state.thumbnails = [
                ...state.thumbnails, // Append new thumbnails
                ...payload.filter((obj: any) => obj !== null && !existingIds.has(obj.ID)).map((obj: any) => {
                    return {
                        Id: obj.ID,
                        label: obj.probill,
                        docTypeId: obj.docTypeId,
                        type: obj.type,
                        thumbnail: obj.thumbnail,
                        status: obj.status,
                        indexDate: obj.indexDate,
                        imageUrl: obj.imageUrl,
                        probill: obj.probill,
                        indexUser: obj.indexUser,
                        lockUser: obj.lockUser,
                        lockDate: obj.lockDate,
                        indexData: obj.indexData,
                    };
                }),
            ];
        },
        removeThumbnailById: (state, { payload }) => {
            const idToRemove = Number(payload.documentId);
            state.thumbnails = state.thumbnails.filter((thumbnail: any) => thumbnail.Id !== idToRemove);
        },
        storePayload: (state, { payload }) => {
            state.payload = payload
        },
        storePaginationDetails: (state, { payload }) => {
            state.paginationDetails = payload
        },
        updateThumbnails: (state, { payload }) => {
            const { docId, probill, docTypeId, docIndexUsr, docIndexDte, indexData, docLockedUsr, docLockDte, docStatId } = payload;
            const thumbnailIndex = state.thumbnails.findIndex((thumbnail: any) => thumbnail.Id === docId);
            if (thumbnailIndex !== -1) {
                const newStatus = DOCUMENT_STAT_ID_ACTIONS[docStatId] || state.thumbnails[thumbnailIndex].status; // Default to current status if not found
                const newType = DOCUMENT_TYPE_ID_ACTIONS[docTypeId] || state.thumbnails[thumbnailIndex].type;

                state.thumbnails[thumbnailIndex] = {
                    ...state.thumbnails[thumbnailIndex],
                    probill: probill || state.thumbnails[thumbnailIndex].probill,
                    indexData: indexData || state.thumbnails[thumbnailIndex].indexData,
                    indexDate: docIndexDte || state.thumbnails[thumbnailIndex].indexDate,
                    indexUser: docIndexUsr || state.thumbnails[thumbnailIndex].indexUser,
                    docTypeId: docTypeId || state.thumbnails[thumbnailIndex].docTypeId,
                    lockUser: docLockedUsr === undefined ? "" : docLockedUsr ? docLockedUsr : state.thumbnails[thumbnailIndex].lockUser,
                    lockDate: docLockDte === undefined ? 0 : docLockDte ? docLockDte : state.thumbnails[thumbnailIndex].lockDate,
                    status: newStatus,
                    type: newType
                };
                state.thumbnails.sort((a: any, b: any) => a.probill - b.probill);
            }
        }
    }
});

export const {
    resetImageViewState,
    requestStarted,
    requestFailed,
    requestSucceed,
    storeThumbnails,
    appendAndStoreThumbnails,
    removeThumbnailById,
    storePayload,
    storePaginationDetails,
    updateThumbnails
} = imageViewSlice.actions;

export const imageViewSelector = (state: IState) => {
    return state.imageView;
};
export const getThumbnails = (
    imageSearchModel: ImageSearchRequestModel,
    isrestore: boolean,
    page: number, // add page for pagination
    pageSize: number, // add page size
): AppThunk => async (dispatch, getState) => {
    !isrestore ? dispatch(requestStarted("IMAGE_THUMBNAIL_DETAILS")) : dispatch(requestStarted("RESTORE_DELETED_IMAGES"));
    const response = await ImageViewService.getThumbnails({ ...imageSearchModel, page, pageSize });
    if (response.ok()) {
        dispatch(requestSucceed());
        if (!response.data.imagesWithThumbnails ||
            !Array.isArray(response.data.imagesWithThumbnails) ||
            response.data.imagesWithThumbnails.length === 0 ||
            response.data.imagesWithThumbnails.every((item: any) => item === null))
            toast.error("Image not available");
        if (isrestore) {
            dispatch(storePaginationDetails(response.data));
            const { isRestoreImage, ...payloadWithoutRestore } = imageSearchModel;
            const statePayload = { ...getState().imageView.payload };
            delete statePayload.isRestoreImage;
            if (JSON.stringify(statePayload) === JSON.stringify(payloadWithoutRestore))
                dispatch(appendAndStoreThumbnails(response.data.imagesWithThumbnails));
            if ((JSON.stringify(statePayload) === "{}" || JSON.stringify(statePayload) === "[]"
                || (getState().imageView.requestCreator === "RESTORE_DELETED_IMAGES" && JSON.stringify(statePayload) !== JSON.stringify(payloadWithoutRestore))))
                dispatch(storeThumbnails(response.data.imagesWithThumbnails));

        } else if (!isrestore) {
            dispatch(storePaginationDetails(response.data));
            dispatch(storePayload(imageSearchModel));
            if (response.data.page > 1) {
                dispatch(appendAndStoreThumbnails(response.data.imagesWithThumbnails));
            }
            else
                dispatch(storeThumbnails(response.data.imagesWithThumbnails));
        }

    } else {
        dispatch(requestFailed());
        toast.error(response.getError ? response.getError() : "Error");
    }
};
export const downloadAllDocuments = (
    selectedDocsId: number[],
    onSuccess: (arg: Blob) => void
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("DOWNLOAD_SELECTED_IMAGES"));
    const response = await ImageViewService.downloadAllDocuments(selectedDocsId);
    if (response.ok()) {
        dispatch(requestSucceed());
        let blob: Blob = new Blob([response.data], { type: "application/pdf" });
        onSuccess(blob);
    } else {
        dispatch(requestFailed());
        toast.error(response.getError ? response.getError() : "Error");
    }
};
export const deleteSelectedImage = (
    imageId: string
):
    AppThunk => async (dispatch) => {
        dispatch(requestStarted("DELETE_SELECTED_IMAGE"));
        const response = await ImageViewService.deleteSelectedImage(imageId);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(removeThumbnailById(response.data));
            toast.info(`Image ${imageId} deleted`);
        }
        else {
            dispatch(requestFailed());
            toast.error(response.getError ? response.getError() : "Error");
        }
    };
export const updateThumbnail = (
    thumbnailUpdateModel: ThumbnailUpdateModel
): AppThunk => async (dispatch) => {
    dispatch(updateThumbnails(thumbnailUpdateModel));
}
const imageViewReducer = imageViewSlice.reducer;
export default imageViewReducer;