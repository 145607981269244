import React, { useEffect, useRef, useState } from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { DOCUMENT_TYPE_ACTIONS, NEW_DOCUMENT_TYPE_ACTIONS } from "./common";
import "./imageIndexer.scss";
import "../../shipments/delivery-records/deliveryRecords.scss"
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import { ReactComponent as DocumentIcon } from "../../../images/document_blue.svg";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { ReactComponent as DownloadIconGrey } from "../../../images/download_grey.svg";
import { ReactComponent as CopyIcon } from "../../../images/copy.svg";
import { ReactComponent as CopyIconGrey } from "../../../images/copy_grey.svg";
import { ReactComponent as RecycleIcon } from "../../../images/recycle_blue.svg";
import { ReactComponent as RecycleIconGrey } from "../../../images/recycle_grey.svg";
import { ReactComponent as AntiClockWiseIcon } from "../../../images/rotate-anticlockwise.svg";
import { ReactComponent as ClockWiseIcon } from "../../../images/rotate-clockwise.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus_white.svg";
import { ReactComponent as MinusIcon } from "../../../images/minus_white.svg";
import { ReactComponent as TickIcon } from "../../../images/tick_black.svg";
import { ReactComponent as TickIconGrey } from "../../../images/tick_grey.svg";
import { ReactComponent as PreviousIcon } from "../../../images/angle-left_blue.svg";
import { ReactComponent as PreviousIconGrey } from "../../../images/angle-left_grey.svg";
import { ReactComponent as NextIcon } from "../../../images/angle-right_blue.svg";
import { ReactComponent as NextIconGrey } from "../../../images/angle-right_grey.svg";
import { ReactComponent as LoadingIcon } from "../../../images/loading_blue.svg";
import { ReactComponent as TiltVertical } from "../../../images/tilt-vertical.svg";
import { ReactComponent as TiltHorizontal } from "../../../images/tilt-horizontal.svg";
import Button, { ButtonThemes, ButtonSizes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import "../../shipments/tracking/tracking-filter/appointments/appointmentFilters.scss";
import { useDispatch, useSelector } from "react-redux";
import { acceptImages, getImages, getRecordNumber, getRelatedImages, imageIndexSelector, lockImages, multiProImages, passImages, rejectImages, resetImageIndexState, restoreImageDetails, restoreThumbnail } from "../../../slices/imaging/imageIndexSlice";
import ImageIndexState from "../../../slices/imaging/imageIndexState";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import mixpanel from "mixpanel-browser";
import Cropper from "react-easy-crop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import { DOCUMENT_TYPE_ID_ACTIONS } from "../image-viewer/common";
import { AcceptRequestModel, RecordNumberRequestModel, SearchRequestModel } from "../../../app/data/image-indexer/models";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";

const ImageIndexer: React.FC<{}> = (props) => {
    const imageIndexState: ImageIndexState = useSelector(imageIndexSelector);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [nextOrPrevious, setnextOrPrevious] = useState<string>("");
    const [related, setRelated] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [clickedImageId, setClickedImageId] = useState<string>("");
    const [responseStatus, setResponseStatus] = useState(false);
    const [defaultImageSrc, setDefaultImageSrc] = useState<string | null>(null);
    const imageContainerRef = useRef<HTMLDivElement | null>(null);
    const [imageHoveredIndex, setImageHoveredIndex] = useState<number | null>(null);
    const [userIndexedOrLocked, setUserIndexedOrLocked] = useState<string>("");
    const [dateIndexedOrLocked, setDateIndexedOrLocked] = useState<string>("");
    const [probill, setProbill] = useState<string>("");
    const [customer, setCustomer] = useState<string>("");
    const [newDocType, setNewDocType] = useState<XGSSelectOption | null | undefined>();
    const onNewDocTypeChanged = (_docType: XGSSelectOption | null | undefined) => {
        setNewDocType(_docType);
    };
    const [currentSelectedDocType, setCurrentSelectedDocType] = useState<XGSSelectOption | null | undefined>();
    const [zoom, setZoom] = useState(1); // Zoom level
    const [rotation, setRotation] = useState(0); // Rotation angle
    const [tilt, setTilt] = useState({ horizontal: 0, vertical: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [relatedLoading, setRelatedLoading] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop position
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
    const [dynamicSize, setDynamicSize] = useState({ width: 430, height: 556 });
    const [dynamicGap, setDynamicGap] = useState("calc((100% - 84px) / 2 - 232px)");
    const [dynamicMarginGap, setDynamicMarginGap] = useState(326);
    const [docLockDte, setDocLockDte] = useState<string>("");
    const [docLockTme, setDocLockTme] = useState<string>("");

    const handleMediaLoaded = () => {
        setZoom(1); // Reset zoom to 1
        setRotation(0); // Reset rotation to 0
        setIsLoading(false); // Hide spinner once image is loaded
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => {
            if (prevZoom <= 1) {
                // Reset crop position and zoom to default
                setCrop({ x: 0, y: 0 });
                return 1;
            }
            return Math.max(prevZoom - 0.1, 1);
        });
    };

    // Rotate image
    const rotateImage = (direction: any) => {
        if (direction === "clockwise") {
            setRotation((prev) => prev + 90);
        } else {
            setRotation((prev) => prev - 90);
        }
    };

    const tiltImage = (direction: any) => {
        setTilt((prev: any) => ({
            ...prev,
            [direction]: prev[direction] + 180,
        }));
    };

    const calculateTop = () => {
        if (rotation % 180 !== 0) {
            return `${(dynamicSize.height - dynamicSize.width) / 2}px`;
        }
        return "0px"; // Default value if no top adjustment is needed
    };

    const getDocTypePerformedValues = () => {
        return Object.keys(DOCUMENT_TYPE_ACTIONS).map((key) => {
            return {
                value: key,
                label: DOCUMENT_TYPE_ACTIONS[key]
            };
        });
    };
    const getNewDocTypePerformedValues = () => {
        return Object.keys(NEW_DOCUMENT_TYPE_ACTIONS).map((key) => {
            return {
                value: key,
                label: NEW_DOCUMENT_TYPE_ACTIONS[key]
            };
        });
    };

    const getDocTypeShort = (docTypeId: string) => {
        return DOCUMENT_TYPE_ID_ACTIONS[docTypeId] || "UNK"
    }

    const onStartDateChanged = (value: string) => {
        setStartDate(value);
    };
    const onEndDateChanged = (value: string) => {
        setEndDate(value);
    };
    const handleThumbnailClick = (thumbnailImage: any | null, thumbnailId: any) => {
        if (thumbnailImage) {
            setClickedImageId(thumbnailId);
            const imageUrlWithToken = thumbnailImage;
            setImageSrc(imageUrlWithToken);
        }
    };
    const onPreviousOrNextClick = async (value: string) => {
        setnextOrPrevious(value);
        const searchModel = getSearchRequestModel(value);
        setImageSrc(null);
        setIsLoading(true);
        await fetchData(searchModel, "nextOrPrevious");
        setnextOrPrevious("");
    };
    const continueClick = async () => {
        if (docType) {
            setImageSrc(null);
            setIsLoading(true);
            const searchModel = getContinueRequestModel();
            await fetchData(searchModel, "continue");
        }
    };
    const rejectClick = async () => {
        if (imageIndexState?.image?.docId) {
            setShowRemoveConfirmation(false);
            RejectImageClick(imageIndexState?.image?.docId);
        }
    };
    const passClick = async () => {
        if (imageIndexState?.image?.docId) {
            PassImageClick(imageIndexState?.image?.docId);
        }
    };
    const lockImage = async () => {
        if (imageIndexState?.image?.docId) {
            LockImageClick(imageIndexState?.image?.docId);
        }
    };
    const multiPro = async () => {
        if (probill) {
            const acceptModel = getAcceptRequestModel();
            multiProImageClick(acceptModel);
        }
    };
    const acceptClick = async () => {
        if (probill) {
            const acceptModel = getAcceptRequestModel();
            acceptImageClick(acceptModel);
        }
    };
    const [docType, setDocType] = useState<XGSSelectOption | null | undefined>(() => {
        const options = getDocTypePerformedValues();
        return options.find(option => option.value === "LOCKED") || null;  // Set default to "Locked"
    });
    const onDocTypeChanged = (_docType: XGSSelectOption | null | undefined) => {
        setDocType(_docType);
    };
    const getSearchRequestModel = (nextOrPreviousClick?: string) => {
        let searchRequestModel: SearchRequestModel = {
            docId: imageIndexState?.image?.docId ? imageIndexState.image.docId : 0,
            nextOrPrevious: nextOrPreviousClick ? nextOrPreviousClick : nextOrPrevious,
            indexDate: docLockDte && docType?.value === "LOCKED" ? docLockDte : imageIndexState?.image?.docIndexDte ? imageIndexState.image.docIndexDte : "0",
            indexTime: docLockTme && docType?.value === "LOCKED" ? docLockTme : imageIndexState?.image?.docIndexTme ? imageIndexState.image.docIndexTme : 0,
            docType: docType ? docType.value : "",
            fromDate: startDate ? startDate.toApiDateFormat() : "",
            toDate: endDate ? endDate.toApiDateFormat() : ""
        };
        return searchRequestModel;
    };
    const getContinueRequestModel = () => {
        let continueRequestModel: SearchRequestModel = {
            docId: 0,
            nextOrPrevious: "",
            indexDate: "0",
            indexTime: 0,
            docType: docType ? docType.value : "",
            fromDate: startDate ? startDate.toApiDateFormat() : "",
            toDate: endDate ? endDate.toApiDateFormat() : ""
        }
        return continueRequestModel;
    };
    const getRecordNumRequestModel = () => {
        let recordNumRequestModel: RecordNumberRequestModel = {
            docType: docType ? docType.value : "",
            fromDate: startDate ? startDate.toApiDateFormat() : "",
            toDate: endDate ? endDate.toApiDateFormat() : ""
        };
        return recordNumRequestModel;
    };
    const getAcceptRequestModel = () => {
        let acceptRequestModel: AcceptRequestModel = {
            docTypeId: newDocType ? newDocType.value : "",
            docId: imageIndexState?.image?.docId ? imageIndexState.image.docId : "",
            probill: probill ? probill : "",
            customer: customer ? customer : ""
        };
        return acceptRequestModel;
    }

    const fetchData = async (searchModel: SearchRequestModel, caller?: any) => {
        try {
            if (searchModel.docType !== "") {
                const response: any = await dispatch(getImages(searchModel, caller));
                setResponseStatus(true);
                if (response.data?.message) {
                    if (response.data.message === "No images available to index" && caller === "nextOrPrevious") {
                        handleNoImagesAvailable();
                    }
                    if (response.data.message === "No images available to index" && imageIndexState.recordNumber.numOfRecords === 0) {
                        dispatch(restoreImageDetails());
                        setImageSrc(null);
                        setResponseStatus(false);
                        setProbill("");
                        setCustomer("");
                        setNewDocType(null);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchRecordNumber = async (searchModel: RecordNumberRequestModel) => {
        try {
            if (searchModel.docType !== "") {
                await dispatch(getRecordNumber(searchModel));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchRelatedThumbnails = async (probill: string, currentDocId: string) => {
        try {
            await dispatch(getRelatedImages(probill, currentDocId));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const resetThumbnails = async () => {
        try {
            dispatch(restoreThumbnail());
        } catch (error) {
            console.error("error in resetting thumbnails:", error);
        }
    }
    const acceptImageClick = async (acceptModel: AcceptRequestModel) => {
        try {
            if (acceptModel.probill !== "") {
                await dispatch(acceptImages(acceptModel, imageIndexState.image.imagethumbnail, imageIndexState.image.savedImage));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const multiProImageClick = async (multiProModel: AcceptRequestModel) => {
        try {
            if (multiProModel.probill !== "") {
                await dispatch(multiProImages(multiProModel, imageIndexState.image.imagethumbnail, imageIndexState.image.savedImage));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const LockImageClick = async (acceptModel: number) => {
        try {
            await dispatch(lockImages(acceptModel, imageIndexState.image.imagethumbnail, imageIndexState.image.savedImage));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const PassImageClick = async (acceptModel: number) => {
        try {
            await dispatch(passImages(acceptModel, imageIndexState.image.imagethumbnail, imageIndexState.image.savedImage));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const RejectImageClick = async (acceptModel: number) => {
        try {
            await dispatch(rejectImages(acceptModel, imageIndexState.image.imagethumbnail, imageIndexState.image.savedImage));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleNoImagesAvailable = () => {
        if (imageIndexState?.image) {
            const imageUrlWithToken = imageIndexState.image.savedImage;
            setImageSrc(imageUrlWithToken);
            setZoom(1);
            setRotation(0);
            setDefaultImageSrc(imageUrlWithToken);
            setClickedImageId(imageIndexState?.image?.docId)
        }
    };

    const clearSearchRecords = () => {
        setStartDate("");
        setEndDate("");
    };

    const downloadDocument = () => {
        if (imageIndexState?.image?.savedImage) {
            setDownloadStatus(true);
            const link = document.createElement("a");
            const imageUrlWithToken = imageIndexState.image.savedImage;
            link.href = imageUrlWithToken
            link.download = `document-${imageIndexState?.image?.docId}.jpg`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            mixpanel.track("Image document was downloaded (JPG)");
            setIsDownloaded(true);
        }
    };

    useEffect(() => {
        if (docType !== currentSelectedDocType) {
            setCurrentSelectedDocType(docType);
            const searchModel = getSearchRequestModel();
            setImageSrc(null);
            setIsLoading(true);
            fetchData(searchModel);
            const searchRecordNumModel = getRecordNumRequestModel();
            fetchRecordNumber(searchRecordNumModel);
        }
        else {
            const searchModel = getSearchRequestModel();
            const searchRecordNumModel = getRecordNumRequestModel();
            if ((searchModel.fromDate !== "" && searchModel.toDate !== "") || (searchModel.fromDate === "" && searchModel.toDate === "")) {
                setImageSrc(null);
                setIsLoading(true);
                fetchData(searchModel);
                fetchRecordNumber(searchRecordNumModel);
            }
        }
        // eslint-disable-next-line
    }, [docType, startDate, endDate]);

    useEffect(() => {
        if (imageIndexState?.image) {
            const imageUrlWithToken = imageIndexState.image.savedImage;
            setImageSrc(imageUrlWithToken);
            setZoom(1);
            setRotation(0);
            setDefaultImageSrc(imageUrlWithToken);
            setClickedImageId(imageIndexState?.image?.docId)
        }
    }, [imageIndexState?.image]);

    useEffect(() => {
        const fetchThumbnails = async () => {
            if (related && imageIndexState?.image?.probill > 0) {
                setRelatedLoading(true);
                try {
                    await fetchRelatedThumbnails(
                        imageIndexState.image.probill.toString(),
                        imageIndexState.image.docId.toString()
                    );
                } catch (error) {
                    console.error("Error fetching related thumbnails:", error);
                } finally {
                    setRelatedLoading(false);
                }
            } else if (imageIndexState?.image?.probill === 0) {
                resetThumbnails();
            }
        };

        fetchThumbnails();
        // eslint-disable-next-line
    }, [related, imageIndexState?.image?.probill]);

    useEffect(() => {
        if (imageIndexState?.image?.docTypeId) {
            const options = getNewDocTypePerformedValues();
            const selectedOption = options.find(option => option.value === imageIndexState?.image?.docTypeId.toString());
            setNewDocType(selectedOption);
        }
        if (imageIndexState?.image?.probill > 0)
            setProbill(imageIndexState?.image?.probill);
        if (imageIndexState?.image?.probill === 0)
            setProbill("");
        setCustomer(imageIndexState?.image?.indexData?.MCUSNO);
        if (imageIndexState?.image?.indexData === null)
            setCustomer("");
        // eslint-disable-next-line
    }, [imageIndexState?.image?.docTypeId, imageIndexState?.image?.probill, imageIndexState?.image?.indexData?.MCUSNO]);

    useEffect(() => {
        imageIndexState?.image?.docLockedUsr ? setUserIndexedOrLocked("Locked by") : setUserIndexedOrLocked("Indexed by");
        imageIndexState?.image?.docLockDte ? setDateIndexedOrLocked("Locked on") : setDateIndexedOrLocked("Indexed on");
    }, [imageIndexState?.image?.docLockedUsr, imageIndexState?.image?.docIndexUsr, imageIndexState?.image?.docLockDte, imageIndexState?.image?.docIndexDte]);

    useEffect(() => {
        if (docType?.value !== "INDEXED" && imageIndexState?.image?.docIndexTme !== undefined && imageIndexState?.image?.docLockTme !== undefined) {
            const searchRecordNumModel = getRecordNumRequestModel();
            fetchRecordNumber(searchRecordNumModel);
        }
        // eslint-disable-next-line
    }, [imageIndexState?.image?.docIndexTme, imageIndexState?.image?.docLockTme])

    useEffect(() => {
        if (imageIndexState?.image?.docLockDte !== undefined && imageIndexState?.image?.docLockTme !== undefined) {
            setDocLockDte(imageIndexState?.image?.docLockDte);
            setDocLockTme(imageIndexState?.image?.docLockTme);
        }
    }, [imageIndexState?.image?.docLockDte, imageIndexState?.image?.docLockTme])

    useEffect(() => {
        if (imageIndexState.recordNumber.numOfRecords === 0) {
            dispatch(restoreImageDetails());
            setImageSrc(null);
            setResponseStatus(false);
            setIsLoading(false);
            setProbill("");
            setCustomer("");
            setNewDocType(null);
        }
        // eslint-disable-next-line
    }, [imageIndexState.recordNumber.numOfRecords])

    useEffect(() => {
        if (isDownloaded) {
            setDownloadStatus(false);
            setIsDownloaded(false);
        }
    }, [isDownloaded])

    useEffect(() => {
        return () => {
            dispatch(resetImageIndexState());
        };
    }, [dispatch]);

    useEffect(() => {
        const handleResize = () => {
            const screenHeight = window.innerHeight;
            if (screenHeight > 800) {
                const height = screenHeight * 0.8;
                const width = height * (430 / 556);
                setDynamicSize({ width, height });
                setDynamicMarginGap(height - 226);
                const calculatedGap = `calc((100% - 84px) / 2 - ${Math.round(0.332 * screenHeight - 8)}px)`;
                setDynamicGap(calculatedGap);

            } else {
                setDynamicSize({ width: 430, height: 556 });
                setDynamicMarginGap(326);
                setDynamicGap("calc((100% - 84px) / 2 - 232px)");
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <ContentContainer title="Image Indexer">
            <div className="xgs-image-indexer__select_options">
                <div className="xgs-image-indexer__left-options">
                    <div className="xgs-image-indexer__controls__item">
                        <LabeledSelectInput
                            onValueChange={onDocTypeChanged}
                            value={docType}
                            options={getDocTypePerformedValues()}
                            label="Document status:"
                            labelMode={LabelModes.column}
                            className="xgs-image-indexer__input"
                        />
                    </div>
                    <div className="xgs-image-indexer__input">
                        <LabeledDateRangeInput
                            label="Dates range:"
                            labelMode={LabelModes.column}
                            className="xgs-image-indexer__list__filters__dates"
                            start={startDate}
                            end={endDate}
                            onStartChange={onStartDateChanged}
                            onEndChange={onEndDateChanged}
                        />
                    </div>
                    <Button
                        theme={ButtonThemes.gray}
                        onClick={clearSearchRecords}
                        className="xgs-image-indexer__clear-button"
                    >
                        Clear Dates
                    </Button>
                </div>
                <div className="xgs-image-indexer__info-box">
                    <div className="xgs-image-indexer__icon-wrapper">
                        <div className="xgs-image-indexer__document-icon">
                            <DocumentIcon className="xgs-btn__icon" />
                        </div>
                    </div>
                    <div className="xgs-image-indexer__info-text">
                        <div className="xgs-image-indexer__record-count">
                            {imageIndexState.recordNumber.numOfRecords}
                        </div>
                        <div className="xgs-image-indexer__subtext">
                            <div className="xgs-image-indexer__documents-selected">
                                Documents Selected
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="xgs-image-indexer__image_view_primary">
                <div className="xgs-image-indexer__image_view_secondary">
                    <div className="xgs-image-indexer__image_controls">
                        <div className="xgs-image-indexer__image_previous_next">
                            <Button
                                className="xgs-image-indexer__image_previous_next_inner_style"
                                style={{ width: "96px", border: imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) ? "rgba(79, 95, 114, 0.32)" : "" }}
                                onClick={() => onPreviousOrNextClick("ISPREVIOUS")}
                                theme={ButtonThemes.blueInverted}
                                disabled={(imageIndexState.requestStarted && imageIndexState.requestCreator === "GET IMAGE FOR INDEXER" && nextOrPrevious === "ISPREVIOUS") || imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)}>
                                {imageIndexState.requestStarted && imageIndexState.requestCreator === "GET IMAGE FOR INDEXER" && nextOrPrevious === "ISPREVIOUS" ? (
                                    <LoadingIcon className="xgs-image-indexer__spin xgs-image-indexer__margin_right" />
                                ) : (
                                    (imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)) ? <PreviousIconGrey className="xgs-image-indexer__margin_right" /> : <PreviousIcon className="xgs-image-indexer__margin_right" />

                                )}

                                Previous
                            </Button>
                            <Button
                                className="xgs-image-indexer__image_previous_next_inner_style"
                                style={{ width: "73px", border: imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) ? "rgba(79, 95, 114, 0.32)" : "" }}
                                onClick={() => onPreviousOrNextClick("ISNEXT")}
                                theme={ButtonThemes.blueInverted}
                                disabled={(imageIndexState.requestStarted && imageIndexState.requestCreator === "GET IMAGE FOR INDEXER" && nextOrPrevious === "ISNEXT") || imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)}>
                                Next
                                {imageIndexState.requestStarted && imageIndexState.requestCreator === "GET IMAGE FOR INDEXER" && nextOrPrevious === "ISNEXT" ? (
                                    <LoadingIcon className="xgs-image-indexer__spin xgs-image-indexer__margin_left" />
                                ) : (
                                    (imageSrc !== defaultImageSrc) || (imageSrc === undefined && defaultImageSrc === undefined) ? <NextIconGrey className="xgs-image-indexer__margin_left" /> : <NextIcon className="xgs-image-indexer__margin_left" />
                                )}

                            </Button>
                        </div>
                        <div className="xgs-image-indexer__image-download-multipro-reindex">
                            <Button
                                theme={ButtonThemes.transparent}
                                size={ButtonSizes.auto}
                                className={"xgs-image-indexer__small-button"}
                                disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)}
                                onClick={downloadDocument}>
                                {downloadStatus ? (
                                    <LoadingIcon className="xgs-image-indexer__spin xgs-image-indexer__margin_left" />
                                ) : (
                                    (imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)) ? <DownloadIconGrey className="xgs-btn__icon xgs-image-indexer__margin_right" /> : <DownloadIcon className="xgs-btn__icon xgs-image-indexer__margin_right xgs-image-indexer__image-download-multipro-reindex__download-icon" />
                                )}
                                Download
                            </Button>
                            <Button
                                theme={ButtonThemes.transparent}
                                size={ButtonSizes.auto}
                                disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) || (imageIndexState.requestStarted && imageIndexState.requestCreator === "MULTIPRO_IMAGE_REQUEST") || (!(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999))}
                                onClick={multiPro}
                                className={"xgs-image-indexer__small-button"}>
                                {imageIndexState.requestStarted && imageIndexState.requestCreator === "MULTIPRO_IMAGE_REQUEST" ? (
                                    <LoadingIcon className="xgs-image-indexer__spin xgs-image-indexer__margin_left" />
                                ) : (
                                    (imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)
                                        || (imageIndexState.requestStarted && imageIndexState.requestCreator === "MULTIPRO_IMAGE_REQUEST") || (!(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999))
                                    ) ? <CopyIconGrey className="xgs-btn__icon xgs-image-indexer__margin_right" /> : <CopyIcon className="xgs-btn__icon xgs-image-indexer__margin_right xgs-image-indexer__image-download-multipro-reindex__download-icon" />
                                )}
                                Multi Pro
                            </Button>
                            <Button
                                theme={ButtonThemes.transparent}
                                size={ButtonSizes.auto}
                                onClick={lockImage}
                                disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) || (imageIndexState?.image?.docLockedUsr || imageIndexState?.image?.docIndexUsr) || (imageIndexState.requestStarted && imageIndexState.requestCreator === "LOCK_INDEXING_IMAGES")}
                                className={"xgs-image-indexer__small-button"}>
                                {imageIndexState.requestStarted && imageIndexState.requestCreator === "LOCK_INDEXING_IMAGES" ? (
                                    <LoadingIcon className="xgs-image-indexer__spin xgs-image-indexer__margin_left" />
                                ) : (
                                    (imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)
                                        || (imageIndexState?.image?.docLockedUsr || imageIndexState?.image?.docIndexUsr) || (imageIndexState.requestStarted && imageIndexState.requestCreator === "LOCK_INDEXING_IMAGES")
                                    ) ? <RecycleIconGrey className="xgs-btn__icon xgs-image-indexer__margin_right" /> : <RecycleIcon className="xgs-btn__icon xgs-image-indexer__margin_right" />
                                )}
                                Reindex
                            </Button>
                            <div className="xgs-image-indexer__image-download-multipro-reindex__switch">
                                <XGSToggle
                                    onChange={setRelated}
                                    checked={related}
                                />
                                <span className="xgs-image-indexer__margin_left xgs-image-indexer__image-download-multipro-reindex__switch__font">Related</span>
                            </div>
                        </div>
                    </div>

                    <div className="xgs-image-indexer__related_image_image_view_primary">
                        {related && (<div className="xgs-image-indexer__related_image_primary" style={{ height: `${dynamicSize.height + 33}px` }}>
                            {imageSrc && (<div className="xgs-image-indexer__related_original_image" onClick={() => handleThumbnailClick(imageIndexState.image.savedImage, imageIndexState.image.docId)} onMouseEnter={() => setImageHoveredIndex(-1)} onMouseLeave={() => setImageHoveredIndex(null)}>
                                <div className="xgs-image-indexer__related_original_image__primary">
                                    <img className="xgs-image-indexer__related_original_image__secondary" style={{ filter: imageHoveredIndex === -1 ? "brightness(93%)" : "brightness(100%)", border: clickedImageId === imageIndexState.image.docId ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${imageIndexState.image.imagethumbnail}`} alt={imageIndexState.image.docId} />
                                </div>
                                <div className="xgs-image-indexer__related_original_image__tick_primary">
                                    <div className="xgs-image-indexer__related_original_image__tick_secondary">
                                        <TickIcon className="xgs-btn__icon xgs-image-indexer__related_original_image__tick-third" />
                                    </div>
                                </div>

                                <div className="xgs-image-indexer__related_original_image__docid_doctypeid">
                                    {imageIndexState.image.docId}
                                </div>
                                <div className="xgs-image-indexer__related_original_image__docid_doctypeid">
                                    {getDocTypeShort(imageIndexState.image.docTypeId)}
                                </div>
                            </div>)}

                            <div className="xgs-image-indexer__related_image" >{imageIndexState.relatedThumbnails.length > 1 ? "Related" : "No Related Images"}</div>
                            {relatedLoading && imageIndexState?.image?.probill ? (
                                <div className="xgs-image-indexer__related_image__spin-icon">
                                    <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                                </div>
                            ) : (imageSrc &&
                                imageIndexState.relatedThumbnails
                                    .filter((record: any) => record.thumbnailId !== imageIndexState?.image?.docId && record.thumbnailprobill === imageIndexState?.image?.probill)
                                    .map((record: any, recordIndex: number) => (
                                        <div key={recordIndex} className="xgs-image-indexer__related_image__primary" onClick={() => handleThumbnailClick(record.thumbnailimage, record.thumbnailId)} onMouseEnter={() => setImageHoveredIndex(recordIndex)} onMouseLeave={() => setImageHoveredIndex(null)}>
                                            <div className="xgs-image-indexer__related_image__secondary-one">
                                                <img className="xgs-image-indexer__related_image__secondary-one__img" style={{ filter: imageHoveredIndex === recordIndex ? "brightness(93%)" : "brightness(100%)", border: clickedImageId === record.thumbnailId ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${record.thumbnail}`} alt={record.thumbnailId} />
                                            </div>
                                            <div className="xgs-image-indexer__related_image__secondary-two">
                                                {record.thumbnailId}
                                            </div>
                                            <div className="xgs-image-indexer__related_image__secondary-two">
                                                {record.thumbnailtype}
                                            </div>
                                        </div>
                                    ))
                            )}
                        </div>
                        )}
                        <div className="xgs-image-indexer__image-to-index" style={{ width: related ? "calc(100% - 84px)" : "100%", gap: dynamicGap }}>
                            {isLoading && responseStatus ? (
                                <div className="xgs-image-indexer__image-to-index__loading-primary">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </div>
                            ) : !imageSrc && (
                                <div className="xgs-image-indexer__image-to-index__loading-primary">
                                    No Images
                                </div>
                            )}
                            {imageSrc && (<div className="xgs-image-indexer__image-to-index__image-id-viewonly-primary">
                                {(imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)) && (<div className="xgs-image-indexer__image-to-index__image-id-viewonly-primary__viewonly-primary">
                                    <div className="xgs-image-indexer__image-to-index__image-id-viewonly-primary__viewonly-secondary">
                                        <div className="xgs-image-indexer__image-to-index__image-id-viewonly-primary__viewonly-third">
                                            View Only
                                        </div>
                                    </div>
                                </div>)}
                                <div className="xgs-image-indexer__image-to-index__image-id-viewonly-primary__id-primary">
                                    <div className="xgs-image-indexer__image-to-index__image-id-viewonly-primary__id-secondary">ID {clickedImageId}</div>
                                </div>
                            </div>)}

                            <div className="xgs-image-indexer__image-to-index__image-primary" ref={imageContainerRef} style={{ width: `${dynamicSize.width}px`, height: `${dynamicSize.height}px`, top: calculateTop(), transform: `rotateX(${tilt.vertical}deg) rotateY(${tilt.horizontal}deg)` }} >
                                {imageSrc && (
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        rotation={rotation}
                                        cropShape="rect"
                                        cropSize={dynamicSize}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        showGrid={false}// Ensure the image can cover the container
                                        maxZoom={5}
                                        style={{
                                            cropAreaStyle: {
                                                border: "none",      // Remove crop window border
                                                opacity: 0,          // Make the crop area invisible
                                                visibility: "hidden"
                                            },
                                            mediaStyle: {
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            },
                                            containerStyle: {
                                                overflow: "visible", // Try making the container overflow visible
                                            },
                                        }}
                                        onMediaLoaded={handleMediaLoaded}
                                    />
                                )
                                }

                            </div>

                            {imageSrc && (<div className="xgs-image-indexer__image-to-index__image-controls-primary">
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => rotateImage("anticlockwise")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <AntiClockWiseIcon className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__clock-anticlock-primary xgs-image-indexer__image-to-index__image-controls-primary__clock-anticlock-primary__anticlock" />
                                    </div>
                                </div>
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => rotateImage("clockwise")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <ClockWiseIcon className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__clock-anticlock-primary xgs-image-indexer__image-to-index__image-controls-primary__clock-anticlock-primary__clock" />
                                    </div>
                                </div>
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => tiltImage("horizontal")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <TiltHorizontal className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__tilt-left-primary" />
                                    </div>
                                </div>
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => tiltImage("vertical")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <TiltVertical className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__tilt-right-primary" />
                                    </div>
                                </div>
                                <div style={{ marginTop: dynamicMarginGap }}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__plus-minus-icon-primary">
                                        <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary"
                                            onClick={() => setZoom(zoom + 0.1)}>
                                            <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                                <PlusIcon className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__plus-minus-icon-secondary" />
                                            </div>
                                        </div>
                                        <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary"
                                            onClick={handleZoomOut}>
                                            <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                                <MinusIcon className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__plus-minus-icon-secondary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className="xgs-image-indexer__image-details">
                    <div className="xgs-image-indexer__image-details__image-info">
                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary">
                            <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-primary">
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-secondary">
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">{userIndexedOrLocked}</div>
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">:</div>
                                </div>
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fourth">
                                    {responseStatus && (
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fifth">{imageIndexState?.image?.docLockedUsr ? imageIndexState?.image?.docLockedUsr : imageIndexState?.image?.docIndexUsr}</div>
                                    )}
                                </div>
                            </div>
                            <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-primary">
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-secondary">
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">{dateIndexedOrLocked}</div>
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">:</div>
                                </div>
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fourth">
                                    {responseStatus && (
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fifth">{imageIndexState?.image?.docLockDte ? imageIndexState?.image?.docLockDte : imageIndexState?.image?.docIndexDte}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary-two">
                            <div className="xgs-image-indexer__image-details__image-info__index-details-primary-two__secondary">
                                <LabeledTextInput
                                    label="Probill#"
                                    labelMode={LabelModes.column}
                                    className="xgs-image-indexer__image-details__image-info__index-details-primary-two__probill-customer"
                                    value={probill}
                                    onChange={(e) => setProbill(e.currentTarget.value)}
                                    type="text"
                                    placeholder="Probill Number"
                                />
                                <LabeledTextInput
                                    label="Customer#"
                                    labelMode={LabelModes.column}
                                    className="xgs-image-indexer__image-details__image-info__index-details-primary-two__probill-customer"
                                    value={customer}
                                    onChange={(e) => setCustomer(e.currentTarget.value)}
                                    type="text"
                                    placeholder="Customer Number"
                                />

                                <LabeledSelectInput
                                    className="xgs-image-indexer__filter__input"
                                    onValueChange={onNewDocTypeChanged}
                                    value={newDocType}
                                    options={getNewDocTypePerformedValues()}
                                    label="Document type:"
                                    labelMode={LabelModes.column}
                                />
                            </div>

                            <div className="xgs-image-indexer__image-details__image-info__image-relation">
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-primary">
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-secondary">
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">BOL</div>
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">:</div>
                                    </div>
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fourth">
                                        {responseStatus && (
                                            <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fifth">{imageIndexState?.image?.indexData?.PBBOL2}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-primary">
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-secondary">
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">PO</div>
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">:</div>
                                    </div>
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fourth">
                                        {responseStatus && (
                                            <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fifth">{imageIndexState?.image?.indexData?.PBPO}</div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-primary">
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-secondary">
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">Company Name</div>
                                    <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-third">:</div>
                                </div>
                                <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fourth">
                                    {responseStatus && (
                                        <div className="xgs-image-indexer__image-details__image-info__index-details-primary__indexedon-indexedby-fifth">{imageIndexState?.image?.indexData?.MCUSNM}</div>
                                    )}
                                </div>
                            </div>

                            <div className="xgs-image-indexer__image-details__image-controls-button">
                                <Button
                                    theme={ButtonThemes.redInverted}
                                    style={{
                                        border: imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) || (imageIndexState?.image?.probill > 0 &&
                                            (imageIndexState?.image?.docIndexUsr !== "" || imageIndexState?.image?.docLockedUsr !== "")) ? "rgba(79, 95, 114, 0.32)" : ""
                                    }}
                                    onClick={() => setShowRemoveConfirmation(true)}
                                    spinner={imageIndexState.requestStarted && imageIndexState.requestCreator === "REJECT_INDEXING_IMAGES"}
                                    disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) || (imageIndexState?.image?.probill > 0 &&
                                        (imageIndexState?.image?.docIndexUsr !== "" || imageIndexState?.image?.docLockedUsr !== ""))}
                                >
                                    <XGSIcon
                                        icon={XGSIcons.faTrashAlt}
                                        size="sm"
                                        style={{
                                            color: imageSrc !== defaultImageSrc || (imageIndexState.requestStarted && imageIndexState.requestCreator === "REJECT_INDEXING_IMAGES") ||
                                                (imageSrc === undefined && defaultImageSrc === undefined) || (imageIndexState?.image?.probill > 0 && (imageIndexState?.image?.docIndexUsr !== "" ||
                                                    imageIndexState?.image?.docLockedUsr !== "")) ? "rgba(79, 95, 114, 0.32)" : "rgb(232, 42, 43)", marginRight: "10px"
                                        }}
                                    />
                                    Reject
                                </Button>
                                <Button
                                    theme={ButtonThemes.blue}
                                    onClick={acceptClick}
                                    spinner={imageIndexState.requestStarted && imageIndexState.requestCreator === "ACCEPT_INDEXING_IMAGES"}
                                    disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) ||
                                        (probill
                                            ? !(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999)
                                            : !(customer && parseInt(customer, 10) > 100)
                                        )}
                                >
                                    {imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) ||
                                        (probill
                                            ? !(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999)
                                            : !(customer && parseInt(customer, 10) > 100)
                                        )
                                        ? <TickIconGrey className="xgs-btn__icon xgs-image-indexer__image-details__image-controls-button__tick-grey" /> : <TickIcon className="xgs-btn__icon xgs-image-indexer__image-details__image-controls-button__tick-white" />}
                                    Accept
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="xgs-image-indexer__image-details__image-controls-button">
                        <Button
                            theme={ButtonThemes.gray}
                            onClick={passClick}
                            className="xgs-image-indexer__image-details__image-controls-button__pass"
                            spinner={imageIndexState.requestStarted && imageIndexState.requestCreator === "PASS_INDEXING_IMAGES"}
                            disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) || (imageIndexState?.image?.probill > 0 && (imageIndexState?.image?.docIndexUsr !== "" || imageIndexState?.image?.docLockedUsr !== ""))}
                        >
                            Pass
                        </Button>
                        <Button
                            className="xgs-image-indexer__image-details__image-controls-button__continue"
                            style={{ border: imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) ? "rgba(79, 95, 114, 0.32)" : "" }}
                            onClick={continueClick}
                            spinner={imageIndexState.requestStarted && imageIndexState.requestCreator === "GET CONTINUE IMAGE FOR INDEXER"}
                            disabled={imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined)}
                            theme={ButtonThemes.blueInverted}
                        >
                            Continue
                            {imageSrc !== defaultImageSrc || (imageSrc === undefined && defaultImageSrc === undefined) ? <NextIconGrey className="xgs-image-indexer__image-details__image-controls-button__continue__icon" /> : <NextIcon className="xgs-image-indexer__image-details__image-controls-button__continue__icon" />}
                        </Button>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                opened={showRemoveConfirmation}
                header="Delete record"
                confirmButtonText="Delete record"
                spinner={imageIndexState.requestStarted && imageIndexState.requestCreator === "PASS_INDEXING_IMAGES"}
                onCancel={() => { setShowRemoveConfirmation(false); }}
                onConfirm={() => { rejectClick(); }}
            >
                Are you sure you want to reject this?
            </ConfirmationModal>
        </ContentContainer>
    );
}
export default ImageIndexer;