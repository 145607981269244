import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { ShipmentCommentModel } from "../../../app/data/tracking/ShipmentCommentsModels";
import Table from "../../../ui-components/table/table";

export interface ShipmentCommentsProps {
  comments: ShipmentCommentModel[];
}

const columnHelper = createColumnHelper<any>();

const ShipmentCommentsList: React.FC<ShipmentCommentsProps> = (props) => {

  const columns = [
    columnHelper.accessor("dateAdded", {
      id: "dateAdded",
      header: "Date",
      cell: ({ row }: any) => (
        <>{row.original.dateAdded?.toUiDateFormat()} <span className="xgs-shipment-details__comments__time">{row.original.timeAdded}</span></>
      ),
      size: 120,
      minSize: 80,
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: "Type",
      size: 64,
      minSize: 48
    }),
    columnHelper.accessor("text", {
      id: "text",
      header: "Comment",
      size: 520,
      minSize: 280
    })
  ];

  return (
    <div className="xgs-shipment-details__comments">
      <Table
        columns={columns}
        data={props.comments}
        rowHeight={56}
        responsive
        minTableHeight={160}
        strictMinTableHeight
        infiniteScroll
      />
    </div>
  );
};


export default ShipmentCommentsList;
