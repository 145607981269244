import React from "react";
import AccountSummary from "../../features/account-summary/accountSummary";
import AgentsList from "../../features/agents-list/agentsList";
import AgingInvoices from "../../features/invoices/aging-invoices/agingInvoices";
import AnnouncementManagement from "../../features/announcement/management";
import AuthCallbackReceiver from "../../features/auth/callbackReceiver";
import BolCombined from "../../features/bol";
import BolCreate from "../../features/bol/bol-only/create";
import BolList from "../../features/bol/bol-only/list";
import ClaimList from "../../features/claims/list";
import ClaimCreate from "../../features/claims/create";
import CompanySettings from "../../features/company-settings/companySettings";
import ConfirmData from "../../features/login/confirm-data/confirmData";
import ConfirmDataValidate from "../../features/login/confirm-data/confirmDataValidate";
import CustomersList from "../../features/customers/list/customersList";
import CustomersUsers from "../../features/company/users-list/usersList";
import DeliveryRecordsList from "../../features/shipments/delivery-records/deliveryRecordsList";
import DeliveryRecordsNew from "../../features/shipments/delivery-records/deliveryRecordsNew";
import DockLoadingList from "../../features/dock-loading/dockLoadingList";
import EmailConfirm from "../../features/settings/email-confirm/emailConfirm";
import EmployeeHome from "../../features/home/employee/employeeHome";
import FeatureBannerManagement from "../../features/feature-banner/management";
import ForgotPassword from "../../features/login/forgot-password/forgotPassword";
import GuidedRequestRates from "../../features/rates/guidedRequestRates";
import InvoiceDetails from "../../features/invoices/invoice-details/invoiceDetails";
// import JWTGenerator from "../../features/admin/jwt-generator/jwtGenerator";
import PendingRequests from "../../features/customers/pending/pendingRequests";
import PickupCreate from "../../features/pickup/create";
import PickupManagement from "../../features/pickup/management";
import PickupLog from "../../features/pickup/log";
import PodRecognition from "../../features/documents/podRecognition/podRecognition";
import PortalRegistration from "../../features/login/portal-registration/portalRegistration";
import PublicShipmentDetails from "../../features/shipments/public-shipment-details/publicShipmentDetails";
import RecognitionLogs from "../../features/documents/recognitionLogs/recognitionLogs";
import ReportDetails from "../../features/reports/scheduled/report-details/reportDetails";
import ReportUnsubscribe from "../../features/reports/scheduled/unsubscribe/reportUnsubscribe";
import ResetPassword from "../../features/login/reset-password/resetPassword";
import ReturnCreate from "../../features/bol/return/create";
import ReturnList from "../../features/bol/return/list";
import CustomerPickupsList from "../../features/bol/pickups/customerPickupsList";
import RatesDetails from "../../features/rates/ratesDetails";
import RatesList from "../../features/rates/ratesList";
import QuickRequestRates from "../../features/rates/quickRequestRates";
import ScheduledReports from "../../features/reports/scheduled/scheduledReports";
import Settings from "../../features/settings/settings";
import ShipmentDetails from "../../features/shipments/shipment-details/shipmentDetails";
import ShipmentsDocuments from "../../features/shipments/documents/documents";
import SignupByInvite from "../../features/signup-by-invite/signupByInvite";
import Tracking from "../../features/shipments/tracking/tracking";
import TransitCalculator from "../../features/transit-calculator/transitCalculator";
import UserProfile from "../data/user/UserProfile";
import { AgingInvoicesPath } from "../../features/invoices/aging-invoices/route";
import { ClaimsPath } from "../../features/claims/route";
import { CompanySettingsPath } from "../../features/company-settings/route";
import { DataConfirmPath } from "../../features/login/confirm-data/route";
import { EmailConfirmPath } from "../../features/settings/email-confirm/route";
import { InvoiceDetailsPath } from "../../features/invoices/invoice-details/route";
import { PickupLogPath } from "../../features/pickup/log/route";
import { PublicShipmentDetailsPath } from "../../features/shipments/public-shipment-details/route";
import { RatesPath } from "../../features/rates/route";
import { ReportsPath } from "../../features/reports/route";
import { ResetPasswordPath } from "../../features/login/reset-password/route";
import { SettingsPath } from "../../features/settings/route";
import { ShipmentDetailsPath } from "../../features/shipments/shipment-details/route";
import { SignupByInvitePath } from "../../features/signup-by-invite/route";
import { TrackShipmentsPath } from "../../features/shipments/tracking/route";
import { UserUtils } from "../data/user/userUtils";
import { ManifestDocuments } from "../../features/shipments/manifest-documents/manifestDocuments";
import { HolidayCalendar } from "../../features/holiday-calendar/holidayCalendar";
import { ManifestsView } from '../../features/manifest/manifestsView'
import { LinehaulMileage } from "../../features/linehaul-mileage";
import { LinehaulLaneManagement } from "../../features/linehaul-mileage/linehaulLaneManagement";
import { AppointmentSummary } from "../../features/appointment/appointmentSummary";
import PlanProbills from "../../features/dispatch/plan-probills/planProbills";
import ActiveRoutes from "../../features/dispatch/route/activeRoutes";
import Picklists from "../../features/dispatch/picklists/picklists";
import { PickupsPath } from "../../features/bol/route";
import UnplannableProbills from "../../features/dispatch/unplannable-probills/unplannableProbills";
import ShipmentChat from "../../features/ai/shipment-chat/shipmentChat";
import { WarehouseSummary } from "../../features/warehouse-inventory/warehouseSummary";
import { CustomerInventory } from "../../features/warehouse-inventory/customerInventory";
import { InboundPickupSummary } from "../../features/pickup/inbound-pickups/summary";
import { InboundPickups } from "../../features/pickup/inbound-pickups/inboundPickups";
import ServiceDisruption from "../../features/dispatch/service-disruption/serviceDisruption";
import ImageViewer from "../../features/imaging/image-viewer/imageViewer";
import ImageIndexer from "../../features/imaging/image-indexer/imageIndexer";
import InvoiceGenerator from "../../features/imaging/invoice-generator/invoiceGenerator";
import InvoiceViewer from "../../features/imaging/invoice-generator/invoiceViewer";
import { PickupApprovement } from "../../features/pickup-approval";

export const Routes = {
  home: "/",
  authCallback: "/auth-callback",
  forgotPassword: "/forgot-password",
  portalRegistration: "/portal-registration",
  confirmData: "/confirm-data",
  invoices: "/invoices",
  agingInvoices: "/aging-invoices",
  claims: {
    list: "/claims",
    create: "/claims/create",
  },
  shipments: {
    documents: "/documents-download",
    manifestDocuments: "/manifest-documents",
    filteredList: "/shipments-filtered",
    inProgress: "/in-progress",
    publicDetails: "/shipment-tracking",
    recent: "/shipments/recent",
    tracking: "/shipments",
    appointments: "/appointments"
  },
  deliveryRecords: {
    list: "/delivery-records",
    new: "/delivery-records/new"
  },
  settings: "/settings",
  users: {
    resetPassword: "/users/reset-password",
    signupByInvite: "/users/invite",
    emailConfirm: "/users/email-confirm",
    dataConfirm: "/users/confirm",
    list: "/users/list"
  },
  customers: {
    pending: "/customers/pending",
    list: "/customers/list",
  },
  agents: {
    list: "/agents/list"
  },
  announcement: "/announcement",
  featureBanner: "/feature-banner",
  admin: {
    // jwtGenerator: "/admin/generate-jwt"
  },
  reports: {
    scheduled: {
      list: "/reports/scheduled",
      create: "/reports/scheduled/create",
      unsubscribe: "/unsubscribe/report"
    }
  },
  rates: {
    list: "/rates",
    guided: "/rates/guided-request",
    quick: "/rates/quick-request"
  },
  company: {
    general: "/company"
  },
  bol: {
    list: "/bol-only",
    create: "/bol-only/create"
  },
  bolCombined: "/bol-all",
  pickup: {
    create: "/pickup/create",
    log: "/pickup/log",
    management: "/pickup/management",
    inboundSummary: '/pickup/outbound/summary',
    inboundPickups: '/pickup/outbound/list'
  },
  pickups: {
    list: "/pickups",
  },
  return: {
    list: "/return",
    create: "/return/create",
  },
  transitCalculator: "/transit-calculator",
  holidayCalendar: "/holiday-calendar",
  manifest: {
    manifests: "/manifest/manifests"
  },
  linehaulMileage: {
    mileage: "/linehaul-mileage/trips",
    management: "/linehaul-mileage/lanes"
  },
  dispatch:{
    activeRoutes: "/dispatch/active-routes",
    planRoute: "/dispatch/route/plan",
    picklists: "/dispatch/picklists",
    unplannableProbills: "/dispatch/unplannable/probills",
    serviceDisruption: "/dispatch/service/disruption"
  },
  ai: {
    shipmentChat: "/ai/shipment-chat",
  },
  warehouseInventory: {
    summary: "/warehouse-inventory/summary",
    inventory: "/warehouse-inventory/inventory"
  },
  documents: {
    podRecognition: "/documents/pod-recognition",
    recognitionLogs: "/documents/recognition-logs",
    view: "/documents/image-view",
    index: "/documents/image-index",
    generate: "/documents/invoice-generate",
    generateView: "/documents/invoice-generate/invoice-view"
  },
  dockLoading: {
    list: "/dock-loading"
  },
  billing: {
    pickupApproval: "/probill-approval"
  }
};

export const accountNumberParamName = "accountNumber";

export interface CoreAccountPathParams {
  [accountNumberParamName]: string;
};

export const getPathWithCoreAccountParams = (route: string) => {
  return `/:${accountNumberParamName}${route === Routes.home ? "" : route}`;
};

export const getPathWithAccountNumber = (route: string) => {
  const paths = [getPathWithCoreAccountParams(route), route];
  return paths;
};

export const terminalNumberParamName = "terminalNumber";

export interface CoreTerminalPathParams {
  [terminalNumberParamName]: string;
};

export const getPathWithCoreTerminalParams = (route: string) => {
  return `/:${terminalNumberParamName}${route === Routes.home ? "" : route}`;
};

export const getPathWithTerminalNumber = (route: string) => {
  const paths = [getPathWithCoreTerminalParams(route), route];
  return paths;
};

export enum FEATURE {
  RETURNS = "RETURNS",
  LINEHAUL_MANAGEMENT = "LINEHAUL_MANAGEMENT",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
}

export interface RoutesComponent {
  path: string | string[];
  component: React.FC<any>;
  fixedPath?: string;
  exact?: boolean;
  isPrivate?: boolean;
  includeAccountNumberInUrl?: boolean;
  feature?: FEATURE;
}

export const publicRoutesComponents: RoutesComponent[] = [
  {
    path: Routes.authCallback,
    component: AuthCallbackReceiver
  },
  {
    fixedPath: Routes.users.resetPassword,
    path: `${Routes.users.resetPassword}/:${ResetPasswordPath.token}`,
    component: ResetPassword
  },
  {
    fixedPath: Routes.users.signupByInvite,
    path: `${Routes.users.signupByInvite}/:${SignupByInvitePath.token}`,
    component: SignupByInvite
  },
  {
    path: Routes.forgotPassword,
    component: ForgotPassword
  },
  { 
    path: Routes.portalRegistration,
    component: PortalRegistration
  },
  {
    path: Routes.confirmData,
    component: ConfirmData
  },
  {
    fixedPath: Routes.users.dataConfirm,
    path: `${Routes.users.dataConfirm}/:${DataConfirmPath.token}`,
    component: ConfirmDataValidate
  },
  {
    fixedPath: Routes.users.emailConfirm,
    path: `${Routes.users.emailConfirm}/:${EmailConfirmPath.token}`,
    component: EmailConfirm
  },
  {
    fixedPath: Routes.shipments.publicDetails,
    path: `${Routes.shipments.publicDetails}/:${PublicShipmentDetailsPath.probill}`,
    component: PublicShipmentDetails
  },
  {
    fixedPath: Routes.reports.scheduled.unsubscribe,
    path: `${Routes.reports.scheduled.unsubscribe}/:${ReportsPath.id}/:${ReportsPath.email}`,
    component: ReportUnsubscribe
  }
];

// {
//   path: getPathWithAccountNumber(Routes.admin.jwtGenerator),
//   component: JWTGenerator,
//   isPrivate: true,
//   exact: true,
//   includeAccountNumberInUrl: true
// }

const customerViewRoutesComponents: RoutesComponent[] = [
  {
    path: getPathWithAccountNumber(Routes.home),
    component: AccountSummary,
    exact: true,
    isPrivate: true,
    includeAccountNumberInUrl: true,
  },
  {
    path: [
      ...getPathWithAccountNumber(`${Routes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}/:${ShipmentDetailsPath.activeTab}`),
      ...getPathWithAccountNumber(`${Routes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}`)
    ],
    component: ShipmentDetails,
    isPrivate: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.shipments.tracking),
    component: Tracking,
    exact: true,
    isPrivate: true,
    includeAccountNumberInUrl: true
  },
  {
    path: [
      ...getPathWithAccountNumber(`${Routes.settings}/:${SettingsPath.activeTab}`),
      ...getPathWithAccountNumber(Routes.settings)
    ],
    component: Settings,
    exact: true,
    isPrivate: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.reports.scheduled.list),
    component: ScheduledReports,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.reports.scheduled.create),
    component: ReportDetails,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(`${Routes.reports.scheduled.list}/:${ReportsPath.id}`),
    component: ReportDetails,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: [
      ...getPathWithAccountNumber(`${Routes.company.general}/:${CompanySettingsPath.activeTab}/:${CompanySettingsPath.selectedItem}/:${CompanySettingsPath.innerTab}`),
      ...getPathWithAccountNumber(`${Routes.company.general}/:${CompanySettingsPath.activeTab}/:${CompanySettingsPath.selectedItem}`),
      ...getPathWithAccountNumber(`${Routes.company.general}/:${CompanySettingsPath.activeTab}`),
      ...getPathWithAccountNumber(Routes.company.general)
    ],
    component: CompanySettings,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },  
  {
    path: getPathWithAccountNumber(Routes.bolCombined),
    component: BolCombined,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.bol.list),
    component: BolList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.bol.create),
    component: BolCreate,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.return.list),
    component: ReturnList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true,
    feature: FEATURE.RETURNS,
  },
  {
    path: getPathWithAccountNumber(Routes.return.create),
    component: ReturnCreate,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true,
    feature: FEATURE.RETURNS,
  },
  {
    path: getPathWithAccountNumber(Routes.pickups.list),
    component: CustomerPickupsList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(`${Routes.pickups.list}/:${PickupsPath.pickupNumber}`),
    component: CustomerPickupsList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.shipments.documents),
    component: ShipmentsDocuments,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.claims.list),
    component: ClaimList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.claims.create),
    component: ClaimCreate,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(`${Routes.claims.list}/:${ClaimsPath.number}`),
    component: ClaimList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.transitCalculator),
    component: TransitCalculator,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    fixedPath: Routes.users.emailConfirm,
    path: `${Routes.users.emailConfirm}/:${EmailConfirmPath.token}`,
    component: EmailConfirm
  },
  {
    path: Routes.warehouseInventory.summary,
    component: WarehouseSummary,
    exact: true,
    isPrivate: true
  },
  {
    path: Routes.warehouseInventory.inventory,
    component: CustomerInventory,
    exact: true,
    isPrivate: true
  },
];

const customerRateQuotesRoutesComponents: RoutesComponent[] = [
  {
    path: getPathWithAccountNumber(Routes.rates.list),
    component: RatesList,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.rates.guided),
    component: GuidedRequestRates,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(Routes.rates.quick),
    component: QuickRequestRates,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  },
  {
    path: getPathWithAccountNumber(`${Routes.rates.list}/:${RatesPath.id}`),
    component: RatesDetails,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true
  }
];

const customerPaymentRoutesComponents: RoutesComponent[] = [
  {
    path: getPathWithAccountNumber(`${Routes.agingInvoices}/:${AgingInvoicesPath.period}`),
    component: AgingInvoices,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true,
  },
  {
    path: getPathWithAccountNumber(`${Routes.invoices}/:${InvoiceDetailsPath.invoiceNumber}`),
    component: InvoiceDetails,
    isPrivate: true,
    exact: true,
    includeAccountNumberInUrl: true,
  }
];

const customerWOPermissionsRoutesComponents: RoutesComponent[] = [
  {
    path: Routes.home,
    component: AccountSummary,
    exact: true,
    isPrivate: true,
    includeAccountNumberInUrl: false
  },
  {
    path: [
      `${Routes.settings}/:${SettingsPath.activeTab}`,
      Routes.settings
    ],
    component: Settings,
    exact: true,
    isPrivate: true
  },
  {
    path: Routes.company.general,
    component: CompanySettings,
    exact: true,
    isPrivate: true
  },
];

export const xgsUserRoutesComponents: RoutesComponent[] = [
  {
    path: getPathWithTerminalNumber(Routes.pickup.create),
    component: PickupCreate,
    isPrivate: true,
    exact: true
  },
  {
    path: [
      ...getPathWithTerminalNumber(`${Routes.pickup.log}/:${PickupLogPath.pickupNumber}`),
      ...getPathWithTerminalNumber(Routes.pickup.log)
    ],
    component: PickupLog,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.pickup.management),
    component: PickupManagement,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.shipments.documents),
    component: ShipmentsDocuments,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.shipments.manifestDocuments),
    component: ManifestDocuments,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.deliveryRecords.list),
    component: DeliveryRecordsList,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.deliveryRecords.new),
    component: DeliveryRecordsNew,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.customers.list),
    component: CustomersList,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.agents.list),
    component: AgentsList,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.users.list),
    component: CustomersUsers,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.customers.pending),
    component: PendingRequests,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.announcement),
    component: AnnouncementManagement,
    isPrivate: true,
    exact: true,
    feature: FEATURE.ANNOUNCEMENTS,
  },
  {
    path: getPathWithTerminalNumber(Routes.featureBanner),
    component: FeatureBannerManagement,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.holidayCalendar),
    component: HolidayCalendar,
    isPrivate: true,
    exact: true
  },
  {
    path: [
      ...getPathWithTerminalNumber(`${Routes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}/:${ShipmentDetailsPath.activeTab}`),
      ...getPathWithTerminalNumber(`${Routes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}`)
    ],
    component: ShipmentDetails,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(`${Routes.shipments.filteredList}/:${TrackShipmentsPath.accountNumber}/:${TrackShipmentsPath.shipmentGroup}`),
    component: Tracking,
    isPrivate: true
  },  
  {
    path: getPathWithTerminalNumber(Routes.shipments.tracking),
    component: Tracking,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.shipments.recent),
    component: Tracking,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.shipments.inProgress),
    component: Tracking,
    exact: true,
    isPrivate: true
  },
  {
    path: [
      ...getPathWithTerminalNumber(`${Routes.settings}/:${SettingsPath.activeTab}`),
      ...getPathWithTerminalNumber(Routes.settings)
    ],
    component: Settings,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.manifest.manifests),
    component: ManifestsView,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.linehaulMileage.mileage),
    component: LinehaulMileage,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.linehaulMileage.management),
    component: LinehaulLaneManagement,
    isPrivate: true,
    exact: true,
    feature: FEATURE.LINEHAUL_MANAGEMENT,
  },
  {
    path: getPathWithTerminalNumber(Routes.shipments.appointments),
    component: AppointmentSummary,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.dispatch.planRoute),
    component: PlanProbills,
    exact: true,
    isPrivate: true,
  },
  {
    path: getPathWithTerminalNumber(Routes.dispatch.activeRoutes),
    component: ActiveRoutes,
    exact: true,
    isPrivate: true,
  },
  {
    path: getPathWithTerminalNumber(Routes.dispatch.picklists),
    component: Picklists,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.dispatch.unplannableProbills),
    component: UnplannableProbills,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.ai.shipmentChat),
    component: ShipmentChat,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(`${Routes.ai.shipmentChat}/:probillNumber`),
    component: ShipmentChat,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.documents.podRecognition),
    component: PodRecognition,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.documents.recognitionLogs),
    component: RecognitionLogs,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.dockLoading.list),
    component: DockLoadingList,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.dispatch.serviceDisruption),
    component: ServiceDisruption,
    exact: true,
    isPrivate: true
  },
  {
    // should be the last one in the array!
    path: getPathWithTerminalNumber(Routes.home),
    component: EmployeeHome,
    isPrivate: true,
    exact: true
  },
  {
    path: getPathWithTerminalNumber(Routes.documents.view),
    component: ImageViewer,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.documents.index),
    component: ImageIndexer,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.documents.generate),
    component: InvoiceGenerator,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(Routes.documents.generateView),
    component: InvoiceViewer,
    exact: true,
    isPrivate: true
  },
  {
    path: getPathWithTerminalNumber(`${Routes.pickup.inboundSummary}`),
    component: InboundPickupSummary,
    isPrivate: true,
    exact: true,
  },
  {
    path: getPathWithTerminalNumber(`${Routes.pickup.inboundPickups}`),
    component: InboundPickups,
    isPrivate: true,
    exact: true,
  },
  {
    path: getPathWithTerminalNumber(`${Routes.billing.pickupApproval}`),
    component: PickupApprovement,
    isPrivate: true,
    exact: true
  }
];

const xgsDriverRoutesComponents: RoutesComponent[] = [
  {
    path: Routes.home,
    component: DeliveryRecordsList,
    exact: true,
    isPrivate: true
  },
  {
    path: Routes.deliveryRecords.list,
    component: DeliveryRecordsList,
    isPrivate: true,
    exact: true
  },
  {
    path: Routes.deliveryRecords.new,
    component: DeliveryRecordsNew,
    isPrivate: true,
    exact: true
  },
  {
    path: [
      `${Routes.settings}/:${SettingsPath.activeTab}`,
      Routes.settings
    ],
    component: Settings,
    exact: true,
    isPrivate: true
  }
];

export const getRouteConfig = (
  user: UserProfile | null | undefined,
  accountId?: string
) => {
  if (!user) return [];
  // employees
  if (UserUtils.isXGSUser(user) || UserUtils.isXGSAdministrator(user)) return xgsUserRoutesComponents;
  // drivers
  if (UserUtils.isXGSDriver(user)) return xgsDriverRoutesComponents;
  // customers w/o accounts
  if (!user.subAccounts || user.subAccounts.length === 0 || !accountId) return customerWOPermissionsRoutesComponents;
  // customers w/ permission(s)
  let routesComponents: RoutesComponent[] = [];
  if (UserUtils.hasPermission(user, accountId, "VIEW")) {
    routesComponents.push(...customerViewRoutesComponents);
  }
  if (UserUtils.hasPermission(user, accountId, "RATE_QUOTE")) {
    routesComponents.push(...customerRateQuotesRoutesComponents);
  }
  if (UserUtils.hasPermission(user, accountId, "PAYMENT")) {
    routesComponents.push(...customerPaymentRoutesComponents);
  }
  return routesComponents;
};
