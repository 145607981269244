import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";

const columnHelper = createColumnHelper<any>();

const getFormattedDate = (date: number) => {
  if (!date || !(+date)) return "";
  let dateStr = date.toString();
  let formattedDate = moment(dateStr, "YYYY-MM-DD");
  return formattedDate.format("MMM D, YYYY");
}

const getFormattedConsignee = (rawValue: string) => {
  if (rawValue) {
    let splitValues = rawValue.split("|");
    if (splitValues && splitValues.length === 2) {
      return <div>{splitValues[0]} (<span>
        {splitValues[1]}
      </span>)</div>
    } else {
      return rawValue;
    }
  } else {
    return ""
  }
}

const getLateReason = (cellProps: any) => {
  if (!cellProps.getValue()) return "";
  return <div className="cell-late-reason">
    <div className="reason-details">
      <XGSIcon icon={XGSIcons.faExclamationTriangle}></XGSIcon>
      <span className="reason-desc">{cellProps.row.original.lateReasonDescription} ({cellProps.getValue()})</span>
    </div>
    <div className="reason-added-by">Added by: {cellProps.row.original.lateReasonAddedBy}</div>
    <div className="reason-added-at">At: {(cellProps.row.original.lateReasonAddesAt) ?  cellProps.row.original.lateReasonAddesAt : ""}</div>
  </div>
}

const getServiceDisruptionColumns = (
) => [
    columnHelper.accessor("probillNumber", {
      id: "probillNumber",
      header: "Probill",
      cell: (cellProps) => (
        <Link
          className="xgs-table-link-cell"
          to={`/shipments/${cellProps.getValue()}`}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          {cellProps.getValue()}
        </Link>
      ),
      filterFn: "includesString",
      size: 80,
    }),
    columnHelper.accessor("staticRouteName", {
      id: "staticRouteName",
      header: "Static Route",
      meta: {
        filterVariant: "select",
      },
      size: 110,
    }),
    columnHelper.accessor("consignee", {
      id: "consignee",
      header: "Consignee",
      cell: (cellProps) => (
        <div>{getFormattedConsignee(cellProps.getValue())}</div>
      ),
      size: 120,
    }),
    columnHelper.accessor("consigneeAddress", {
      id: "consigneeAddress",
      header: "Street Address",
      size: 150
    }),
    columnHelper.accessor("consigneeCity", {
      id: "consigneeCity",
      header: "City",
      meta: {
        filterVariant: "select",
      },
      size: 100,
    }),
    columnHelper.accessor("consigneeZip", {
      id: "consigneeZip",
      header: "Zip Code",
      meta: {
        filterVariant: "select",
      },
      size: 90,
    }),
    columnHelper.accessor("probillItems", {
      id: "probillItems",
      header: "Pieces",
      enableColumnFilter: false,
      size: 70,
    }),
    columnHelper.accessor("probillWeight", {
      id: "probillWeight",
      header: "Weight",
      cell: (cellProps) => (
        <>
          {cellProps.getValue().toLocaleString("en", { useGrouping: true })}
        </>
      ),
      enableColumnFilter: false,
      size: 70,
    }),
    columnHelper.accessor("etaDate", {
      id: "etaDate",
      header: "ETA",
      size: 100,
      cell: (cellProps) => (
        <>
          {getFormattedDate(cellProps.getValue())}
        </>
      ),
      meta: {
        filterVariant: "select",
        filterLabelFormatter: getFormattedDate
      },
      filterFn: "equals",
    }),
    columnHelper.accessor("probillStatus", {
      id: "probillStatus",
      header: "Status",
      cell: (cellProps) => (
        <div className="cell-probill-status">
          {cellProps.getValue()}
        </div >
      ),
      meta: {
        filterVariant: "select"
      },
      size: 130,
    }),
    columnHelper.accessor("lateReasonCode", {
      id: "lateReasonCode",
      header: "Late Reason",
      cell: (cellProps) => (
        <>{getLateReason(cellProps)}</>
      ),
      enableColumnFilter: false,
      enableSorting: false,
      size: 180,
    }),
  ];

export default getServiceDisruptionColumns;
