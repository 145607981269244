import api, { ApiResponse } from "./../api";
class ImageViewerService {
    private static instance: ImageViewerService;
    private constructor() { }

    static getInstance(): ImageViewerService {
        if (!ImageViewerService.instance) {
            ImageViewerService.instance = new ImageViewerService();
        }
        return ImageViewerService.instance;
    }
    public getThumbnails = async (
        payload: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-view/thumbnail", payload);
    };
    public downloadAllDocuments = async (
        docId: number[]
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-view/download", {
            docId
        },{
            responseType: "blob"  // Set responseType to "blob" here
        });
    };
    public deleteSelectedImage = async (
        imageId: string
    ): Promise<ApiResponse<any>> => {
        return await api.delete("/image-view/delete", {
            params: {
                imageId
            }
        })
    }

}
export default ImageViewerService;