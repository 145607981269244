export const DOCUMENT_TYPE_ACTIONS = {
    "1": "ATH",
    "2": "Bills Of Landing",
    "12": "Credit",
    "3": "Customer",
    "4": "Deleted After Import",
    "5": "EDI",
    "6": "Invoice",
    "10": "Manifest",
    "7": "Miscellaneous",
    "8": "No Document Type",
    "9": "Proof Of Delivery",
    "11": "Unknown",
    "13": "Warehouse Receipt"
  };
  export const DOCUMENT_TYPE_ID_ACTIONS = {
    "1": "ATH",
    "2": "BOL",
    "12": "CRT",
    "3": "CUS",
    "4": "DAI",
    "5": "EDI",
    "6": "INV",
    "10": "MAN",
    "7": "MSC",
    "8": "NDT",
    "9": "POD",
    "11": "UNK",
    "13": "WHR"
  };
  export const DOCUMENT_STAT_ID_ACTIONS = {
    "1": "N",
    "2": "A",
    "3": "R",
    "4": "M",
    "5": "P",
    "6": "D",
    "10": "IN",
    "7": "X",
    "8": "IR",
    "9": "I",
    "11": "MIA"
  };
  export const LOCATION_ACTIONS = {
    "49": "Agent - Actx",
    "50": "Agent - Aewr",
    "53": "Agent - Aghv",
    "75": "Agent - AllStarFreight",
    "52": "Agent - Averitt",
    "54": "Agent - Bdsf",
    "51": "Agent - Brtc",
    "63": "Agent - Crew2",
    "55": "Agent - Diam",
    "56": "Agent - Eagl",
    "57": "Agent - Easy",
    "77": "Agent - EnterpriseFreight",
    "73": "Agent - GroundFreightExpress",
    "58": "Agent - Hath",
    "71": "Agent - Hathlou",
    "64": "Agent - Nebt",
    "62": "Agent - Pga",
    "66": "Agent - Pgak",
    "78": "Agent - PsePcd",
    "59": "Agent - Puls",
    "76": "Agent - RedlineFreight",
    "83": "Agent - Sefl",
    "60": "Agent - Shea",
    "61": "Agent - Thsv",
    "65": "Agent - Xpo",
    "41": "Agents",
    "44": "Albany",
    "2": "Albuquerque",
    "82": "Allentown",
    "4": "Anaheim",
    "7": "Aurora",
    "80": "Brownstown",
    "95": "Calhoun",
    "86": "Cartersville",
    "84": "Charlotte",
    "43": "Chattanooga",
    "90": "Chattanooga2",
    "67": "CheetahPod",
    "91": "Cleveland",
    "85": "Clinton",
    "70": "Columbus",
    "24": "Coraopolis",
    "89": "Dalton",
    "92": "Dayton",
    "18": "Eagan",
    "74": "EagleEnterprises",
    "10": "Fresno",
    "93": "GrandRapids",
    "8": "Grapevine",
    "3": "GuilderlandCtr",
    "6": "Hanover",
    "19": "Hayward",
    "12": "Houston",
    "47": "Import",
    "81": "Indianapolis",
    "13": "Jacksonville",
    "29": "Kent",
    "69": "Lakeland",
    "14": "LittleRock",
    "28": "Louisville",
    "17": "Loxley",
    "21": "Markham",
    "32": "MarylandHeights",
    "16": "MenomoneeFalls",
    "68": "Miami",
    "87": "NewOrleans",
    "20": "OklahomaCity",
    "15": "Orlando",
    "23": "Phoenix",
    "96": "Pod-External",
    "22": "Portland",
    "31": "RanchoCordova",
    "34": "Riverview",
    "25": "Rochester",
    "9": "Romulus",
    "46": "Sacramento",
    "30": "SaltLakeCity",
    "27": "SanAntonio",
    "26": "SanDiego",
    "11": "Spokane",
    "94": "Springfield",
    "1": "System",
    "48": "Testing",
    "88": "Tracy",
    "35": "Tulsa",
    "33": "TunnelHill",
    "72": "WarehouseReceipts",
    "5": "Windsor",
    "79": "Youngstown"
  };
  export const STATUS_ACTIONS = {
    "2": "Auto Indexed",
    "6": "Deleted",
    "8": "Import Review",
    "9": "Imported",
    "10": "Inspected",
    "4": "Manually Indexed",
    "11": "Missing",
    "1": "New",
    "5": "Passed",
    "7": "Purge",
    "3": "Review"
  };