import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

export const getInboundPickupSummaryColumns = () => [
  columnHelper.accessor("type", {
    id: "type",
    header: "",
    cell: (cellProps) => (
      <div
        style={{
          fontWeight: 600,
          color: "rgba(79, 95, 114, 0.72)",
          marginRight: 4,
        }}
      >
        {cellProps.getValue()}
      </div>
    ),
    size: 400,
  }),
  columnHelper.accessor("planned", {
    id: "planned",
    header: () => <div style={{ textAlign: "right" }}>Planned</div>,
    cell: (cellProps) => (
      <div style={{ marginRight: 5 }}>{cellProps.getValue()}</div>
    ),
    size: 390,
  }),
  columnHelper.accessor("actual", {
    id: "actual",
    header: () => <div style={{ textAlign: "right" }}>Actual</div>,
    cell: (cellProps) => (
      <div style={{ marginRight: 5 }}>{cellProps.getValue()}</div>
    ),
    size: 390,
  }),
];
