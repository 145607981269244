import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

export const getPickupApprovalColumns = () => [
  columnHelper.accessor("pickup.pickupNumber", {
    id: "pickupNumber",
    header: "Pickup #",
    cell: (props) => (
      <div className="blue-link">
        {props.getValue()}
      </div>
    ),
    size: 120,
  }),
  columnHelper.accessor("pickup.dateCompleted", {
    id: "dateCompleted",
    header: "Picked up",
    cell: (props) => {
      const date = props.getValue()?.toUiDateTimeFormat();
      return (
        <>
          {date?.split(" ")[0]}&nbsp;{" "}
          <span style={{ color: "rgba(79, 95, 114, 0.72)" }}>
            {date?.split(" ")[1]} {date?.split(" ")[2]}
          </span>
        </>
      );
    },
    size: 120,
  }),
  columnHelper.accessor("pickup.probill", {
    id: "probillBOL",
    header: "Probill / BOL #",
    cell: (props) => (
      <div>
        {props.row.original.pickup.probillNumber} <br />
        {props.row.original.pickup.bolNumber}
      </div>
    ),
    size: 140,
  }),
  columnHelper.accessor("consignee.name", {
    id: "consigneeName",
    header: "Consignee",
    size: 140,
  }),
  columnHelper.accessor("consignee.address", {
    id: "consigneeAddress",
    header: "Consignee Address",
    cell: (props) => {
      const address = props.getValue();
      return address
        ? `${address.address1}, ${address.city}, ${address.postalCode}`
        : "";
    },
    size: 250,
  }),
  columnHelper.accessor("shipper.name", {
    id: "shipperName",
    header: "Shipper",
    size: 140,
  }),
  columnHelper.accessor("shipper", {
    id: "shipperAddress",
    header: "Shipper address",
    cell: (props) => {
      const address = props.getValue()?.address;
      return `${address?.address1}, ${address?.city}, ${address?.postalCode}`;
    },
    size: 250,
  }),
  columnHelper.accessor("originServiceCenter", {
    id: "originTerminal",
    header: "Origin Terminal",
    cell: (props) => {
      const value = props.getValue();
      return value ? (
        <>
          {value.airportCode} - {value.terminalName?.toUpperCase()}{" "}
          <span style={{ color: "rgba(79, 95, 114, 0.72)" }}>
            {value.terminalNumber}
          </span>
        </>
      ) : (
        <></>
      );
    },
    size: 200,
  }),
  columnHelper.accessor("destinationServiceCenter", {
    id: "destinationTerminal",
    header: "Destination Terminal",
    cell: (props) => {
      const value = props.getValue();
      return value ? (
        <>
          {value.airportCode} - {value.terminalName?.toUpperCase()}{" "}
          <span style={{ color: "rgba(79, 95, 114, 0.72)" }}>
            {value.terminalNumber}
          </span>
        </>
      ) : (
        <></>
      );
    },
    size: 200,
  }),
];
