import { IState, AgingInvoicesState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import InvoiceService from "../../app/data/invoice/invoiceService";
import { InvoiceFilterModel } from "../../app/data/invoice/models";
import { SortParams } from "../../app/data/common/models";

const initialState: AgingInvoicesState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  invoices: [],
  lastIds: null,
  loadingPortion: false,
  loadedAll: false,
  needToReload: false,
  loadingCSV: false,
  loadingFailedCSV: false,
  invoicesCSV: []
};

const invoiceService = InvoiceService.getInstance();

export const agingInvoicesSlice = createSlice({
  name: "agingInvoices",
  initialState,
  reducers: {
    resetInvoiceAging: (state) => initialState,
    agingInvoicesLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loadingPortion = false;
      state.loaded = false;
      state.needToReload = false;
    },
    agingInvoicesInfiniteLoadingStarted: (state) => {
      state.loading = false;
      state.loadingFailed = false;
      state.loadingPortion = true;
      state.loaded = false;
      state.needToReload = false;
    },
    agingInvoicesLoadingSucceed: (state, { payload }) => {
      state.loadingPortion = false;
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.invoices = payload.content;
      state.lastIds = payload.scroll.lastIds;
      state.loadedAll = !!(payload.content.length < 50);
    },
    agingInvoicesInfiniteLoadingSucceed: (state, { payload }) => {
      state.loadingPortion = false;
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.invoices = [...state.invoices, ...payload?.content];
      state.lastIds = payload.scroll.lastIds;
      state.loadedAll = !!(payload.content.length < 50);
    },
    agingInvoicesLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.invoices = [];
    },
    reloadAgingInvoices: (state) => {
      if (state.loaded) {
        state.needToReload = true;
      }
    },
    agingInvoicesForCSVLoadingFailed: (state) => {
      state.loadingCSV = false;
      state.loadingFailedCSV = true;
      state.invoicesCSV = [];
    },
    agingInvoicesForCSVLoadingStarted: (state) => {
      state.loadingCSV = true;
      state.loadingFailedCSV = false;
    },
    agingInvoicesForCSVLoadingSucceed: (state, { payload }) => {
      state.loadingCSV = false;
      state.loadingFailedCSV = false;
      state.invoicesCSV = payload.content;
    }    
  },
});

export const {
  resetInvoiceAging,
  agingInvoicesLoadingStarted,
  agingInvoicesInfiniteLoadingStarted,
  agingInvoicesLoadingSucceed,
  agingInvoicesInfiniteLoadingSucceed,
  agingInvoicesLoadingFailed,
  reloadAgingInvoices,
  agingInvoicesForCSVLoadingFailed,
  agingInvoicesForCSVLoadingStarted,
  agingInvoicesForCSVLoadingSucceed
} = agingInvoicesSlice.actions;

export const agingInvoicesSelector = (state: IState) => state.agingInvoices;

export const getAgingInvoices = (
  subAccountId?: string,
  agingPeriod?: string,
  filter?: InvoiceFilterModel | null,
  sorting?: SortParams | null,
  lastIds?: string,
  infiniteScroll?: boolean
): AppThunk => async (dispatch) => {
  if (!subAccountId || !agingPeriod) {
    console.error("getAgingInvoices accountId must have value");
    dispatch(agingInvoicesLoadingFailed());
    return;
  }

  dispatch(infiniteScroll ? agingInvoicesInfiniteLoadingStarted() : agingInvoicesLoadingStarted());

  const response = await invoiceService.getAgingInvoices({
    subAccountId,
    agingPeriod,
    invoiceNumber: filter?.invoiceNumber,
    lastIds,
    ...sorting,
  });

  if (response.ok()) {
    dispatch(infiniteScroll ? agingInvoicesInfiniteLoadingSucceed(response.data) : agingInvoicesLoadingSucceed(response.data));
  } else {
    dispatch(agingInvoicesLoadingFailed());
  }
};

export const getAgingInvoicesForCSV = (
  subAccountId?: string,
  agingPeriod?: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  if (!subAccountId || !agingPeriod) {
    dispatch(agingInvoicesForCSVLoadingFailed());
    return;
  }
  dispatch(agingInvoicesForCSVLoadingStarted());
  const response = await invoiceService.getAgingInvoicesForCSV({
    subAccountId,
    agingPeriod
  });
  if (response.ok()) {
    dispatch(agingInvoicesForCSVLoadingSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(agingInvoicesForCSVLoadingFailed());
  }
};

const agingInvoicesReducer = agingInvoicesSlice.reducer;
export default agingInvoicesReducer;
