import api, { ApiResponse } from "../api";
import Invoice, {
  ManifestDocumentRequestModel,
  DocumentRequestModel,
  GetAgingInvoiceRequestModel,
  GetInvoiceDetailsRequestModel,
  InvoiceDetails,
  InvoiceDocument
} from "./models";
import { CollectionResponse } from "../common/models";
import { urlParams } from "../../../services/common/urlParams";

class InvoiceService {
  private static instance: InvoiceService;
  private constructor() {}
  static getInstance(): InvoiceService {
    if (!InvoiceService.instance) {
      InvoiceService.instance = new InvoiceService();
    }
    return InvoiceService.instance;
  }

  public getAgingInvoices = async (
    requestModel: GetAgingInvoiceRequestModel
  ): Promise<ApiResponse<CollectionResponse<Invoice>>> => {
    return await api.get(`/accounts/${requestModel.subAccountId}/invoices`, { params: urlParams(requestModel) });
  };

  public getAgingInvoicesForCSV = async (
    requestModel: GetAgingInvoiceRequestModel
  ): Promise<ApiResponse<CollectionResponse<Invoice>>> => {
    return await api.get(
      `/accounts/${requestModel.subAccountId}/invoices?all=true${
        requestModel.agingPeriod
          ? "&agingPeriod=" + requestModel.agingPeriod
          : ""
      }`
    );
  };

  public getInvoiceDetails = async (
    requestModel: GetInvoiceDetailsRequestModel
  ): Promise<ApiResponse<InvoiceDetails>> => {
    return await api.get(
      `/accounts/${requestModel.subAccountId}/invoices/${requestModel.invoiceId}`
    );
  };

  public getInvoiceDocuments = async (
    probill: number
  ): Promise<ApiResponse<InvoiceDocument[]>> => {
    return await api.get(
      `/documents/probill/${probill}`
    );
  };

  public downloadInvoiceDocument = async (
    documentId: number,
    type: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/documents/${documentId}?type=${type}`);
  };

  public getInvoiceRecords = async (
    probill: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(
      `/invoices/${probill}/records`
    );
  };

  public downloadAllInvoiceDocuments = async (
    probill: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/documents/probill/${probill}/download`, {
      responseType: "blob"
    });
  };

  public downloadGroupDocuments = async (
    request: DocumentRequestModel[]
  ): Promise<ApiResponse<any>> => {
    return await api.post("/documents/download", request);
  };
  
  public downloadManifestDocuments = async (
    request: ManifestDocumentRequestModel
  ): Promise<ApiResponse<any>> => {
    return api.post(`/documents/download-by-manifest`, request);
  }

  public sendAllDocuments = async (
    probill: number,
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(
      `/documents/probill/${probill}/send?to=${encodeURIComponent(email)}`
    );
  };

  public exportInvoiceDocument = async (
    invoiceNumber: number,
  ): Promise<ApiResponse<any>> => {
    return await api.get(
      `/invoices/export/${invoiceNumber}`
    );
  };

  public exportPODDocument = async (
    invoiceNumber: number,
  ): Promise<ApiResponse<any>> => {
    return await api.get(
      `/invoices/export/documents/${invoiceNumber}`
    );
  };

 
}

export default InvoiceService;
