import React, { useEffect, useRef, useState } from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import "./invoiceGenerator.scss";
import "../../shipments/delivery-records/deliveryRecords.scss"
import "../image-viewer/imageViewer.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cropper from "react-easy-crop";
import InvoiceGenerateState from "../../../slices/imaging/invoiceGenerateState";
import { useDispatch, useSelector } from "react-redux";
import { invoiceGenerateSelector, resetInvoiceGenerateState} from "../../../slices/imaging/invoiceGenerateSlice";
import { ReactComponent as AntiClockWiseIcon } from "../../../images/rotate-anticlockwise.svg";
import { ReactComponent as ClockWiseIcon } from "../../../images/rotate-clockwise.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus_white.svg";
import { ReactComponent as MinusIcon } from "../../../images/minus_white.svg";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { ReactComponent as PlusBlueIcon } from "../../../images/plus_blue.svg";
import { ReactComponent as TiltVertical } from "../../../images/tilt-vertical.svg";
import { ReactComponent as TiltHorizontal } from "../../../images/tilt-horizontal.svg";
import { PDFDocument} from "pdf-lib";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../app/route/RoutesConfig";

const InvoiceViewer: React.FC<{}> = (props) => {
    const invoiceGenerateState: InvoiceGenerateState = useSelector(invoiceGenerateSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageDocId, setImageDocId] = useState<string>("");
    const [currentProbill, setCurrentProbill] = useState<string>("");
    const [selectedProbillImage, setSelectedProbillImage] = useState<string>("");
    const [checkedProbill, setCheckedProbill] = useState<string>("");
    const [checkedImageUrl, setCheckedImageUrl] = useState<string>("");
    const [checkedThumbnailStatus, setCheckedThumbnailStatus] = useState(false);
    const [isThumbnailChecked, setIsThumbnailChecked] = useState(false);
    const imageContainerRef = useRef<HTMLDivElement | null>(null);
    const [imageHoveredIndex, setImageHoveredIndex] = useState<number | null>(null);
    const [includedImageHoveredIndex, setIncludedImageHoveredIndex] = useState<number | null>(null);
    const [zoom, setZoom] = useState(1); // Zoom level
    const [rotation, setRotation] = useState(0); // Rotation angle
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [relatedLoading] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop position
    const [relatedThumbnails, setRelatedThumbnails] = useState<any[]>([]);
    const [selectedRecords, setSelectedRecords] = useState<{ [probill: string]: Set<string> }>({});
    const [dynamicSize, setDynamicSize] = useState({ width: 430, height: 556 });
    const [dynamicGap, setDynamicGap] = useState("calc((100% - 84px) / 2 - 232px)");
    const [dynamicMarginGap, setDynamicMarginGap] = useState( 299 );
    const [tilt, setTilt] = useState({ horizontal: 0, vertical: 0 });

    const handleMediaLoaded = () => {
        setZoom(1);
        setRotation(0);
        setIsLoading(false);
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => {
            if (prevZoom <= 1) {
                setCrop({ x: 0, y: 0 });
                return 1;
            }
            return Math.max(prevZoom - 0.1, 1);
        });
    };

    const rotateImage = (direction: any) => {
        if (direction === "clockwise") {
            setRotation((prev) => prev + 90);
        } else {
            setRotation((prev) => prev - 90);
        }
    };

    const tiltImage = (direction: any) => {
        setTilt((prev: any) => ({
            ...prev,
            [direction]: prev[direction] + 180,
        }));
    };

    const calculateTop = () => {
        if (rotation % 180 !== 0) {
            return `${(dynamicSize.height - dynamicSize.width) / 2}px`;
        }
        return "0px"; // Default value if no top adjustment is needed
    };


    const handleCurrentThumbnailClick = (image: any, docId: any, docType: any) => {
        if (image) {
            setImageSrc("");
            setIsLoading(true);
            const imageUrlWithToken = image;
            setImageSrc(imageUrlWithToken);
        }
        docId !== 0 ? setImageDocId(docId) : setImageDocId("New invoice");
    };

    const handleCheckbox = (probill: string, imageUrl: string, isChecked: boolean) => {
        if (probill && imageUrl) {
            setCheckedProbill(probill);
            setCheckedImageUrl(imageUrl);
            setCheckedThumbnailStatus(isChecked);
            setIsThumbnailChecked(true);
            handleThumbnailClick(invoiceGenerateState?.image, probill);
        }
    }

    const handleThumbnailClick = (includedImages: any, currentProbill: any) => {
        if (includedImages && currentProbill) {
            const filteredThumbnails = includedImages
                .filter((record: any) => record.probill === currentProbill)
                .flatMap((record: any) => record.images);
            setCurrentProbill(currentProbill);
            setRelatedThumbnails(filteredThumbnails);
            setSelectedProbillImage(currentProbill);
        }
    };

    const handleCheckboxChange = (probill: string, imageUrl: string, isChecked: boolean) => {
        setSelectedRecords((prevSelectedRecords) => {
            setIsThumbnailChecked(false);
            const updatedSelectedRecords = { ...prevSelectedRecords };
            if (isChecked) {
                if (imageUrl.includes("INV")) {
                    if (!updatedSelectedRecords[probill]) {
                        updatedSelectedRecords[probill] = new Set();
                    }
                    relatedThumbnails.forEach((record) => {
                        updatedSelectedRecords[probill].add(record.imageUrl);
                    });
                } else {
                    if (updatedSelectedRecords[probill]) {
                        updatedSelectedRecords[probill].add(imageUrl);
                    }
                }
            } else {
                imageUrl.includes("INV") ? delete updatedSelectedRecords[probill] : updatedSelectedRecords[probill].delete(imageUrl);
            }
            return updatedSelectedRecords;
        });
    };

    const downloadDocument = async () => {
        if (selectedRecords) {
            setIsDownloading(true);
            for (const probill in selectedRecords) {
                if (selectedRecords.hasOwnProperty(probill)) {
                    const imageUrlsSet = selectedRecords[probill];
                    const imageUrls = Array.from(imageUrlsSet);
                    // Create a new PDF document
                    const pdfDoc = await PDFDocument.create();
                    for (let i = 0; i < imageUrls.length; i++) {
                        const imageUrl = imageUrls[i];
                        const imageUrlWithToken = imageUrl;
                        try {
                            // Fetch the image
                            const response = await fetch(imageUrlWithToken);
                            const arrayBuffer = await response.arrayBuffer();
                            // Embed the image
                            let image;
                            if (imageUrl.toLowerCase().endsWith(".png")) {
                                image = await pdfDoc.embedPng(arrayBuffer);
                            } else {
                                image = await pdfDoc.embedJpg(arrayBuffer);
                            }
                            const { width, height } = image.scale(1);
                            const page = pdfDoc.addPage([width, height]);
                            page.drawImage(image, { x: 0, y: 0, width, height });
                        } catch (error) {
                            console.error(`Error fetching image ${imageUrl}:`, error);
                        }
                    }
                    const pdfBytes = await pdfDoc.save();
                    // Trigger the download
                    const blob = new Blob([pdfBytes], { type: "application/pdf" });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = `document-${probill}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
            setIsDownloading(false);
        }
    };

    const createNewInvoice = () => {
        history.push(Routes.documents.generate)
        return () => {
            dispatch(resetInvoiceGenerateState());
        };
    };

    useEffect(() => {
        if (checkedProbill && checkedImageUrl && isThumbnailChecked) {
            handleCheckboxChange(checkedProbill, checkedImageUrl, checkedThumbnailStatus);
        }
        // eslint-disable-next-line
    }, [relatedThumbnails]);

    useEffect(() => {
        if (invoiceGenerateState?.image) {
            handleThumbnailClick(invoiceGenerateState?.image, invoiceGenerateState?.image[0]?.probill);
            handleCurrentThumbnailClick(invoiceGenerateState?.image[0]?.images[0]?.imageUrl, invoiceGenerateState?.image[0]?.images[0]?.docId, invoiceGenerateState?.image[0]?.images[0]?.docType);
            setSelectedProbillImage(invoiceGenerateState?.image[0]?.probill);
            // Initial selection of all images
            const selectedRecordsMap = invoiceGenerateState.image.reduce(
                (acc: { [probill: string]: Set<string> }, record: any) => {
                    const imageUrls: Set<string> = new Set(record.images.map((image: { imageUrl: string }) => image.imageUrl));
                    acc[record.probill] = imageUrls;
                    return acc;
                },
                {}
            );
            setSelectedRecords(selectedRecordsMap);
        }
        if (!invoiceGenerateState?.image || invoiceGenerateState.image.length === 0) {
            createNewInvoice();
        }
        // eslint-disable-next-line
    }, [invoiceGenerateState]);

    useEffect(() => {
        const handleResize = () => {
            const screenHeight = window.innerHeight;
            if (screenHeight > 800) {
                const height = screenHeight * 0.8;
                const width = height * (430 / 556);
                setDynamicSize({ width, height });
                setDynamicMarginGap(height - 292);
                const calculatedGap = `calc((100% - 84px) / 2 - ${Math.round(0.332 * screenHeight)}px)`;
                setDynamicGap(calculatedGap);

            } else {
                setDynamicSize({ width: 430, height: 565 });
                setDynamicMarginGap(299);
                setDynamicGap("calc((100% - 84px) / 2 - 240px)");
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <ContentContainer title="Invoice Viewer">
            <div className="xgs-invoice-viewer__button-collection">
                <Button
                    className="xgs-delivery-records__list__filters__button xgs-invoice-viewer__button-collection__download-button"
                    theme={ButtonThemes.blue}
                    onClick={downloadDocument}
                    spinner={isDownloading}
                    disabled={Object.keys(selectedRecords).length === 0}
                >
                    <DownloadIcon className="xgs-btn__icon xgs-invoice-viewer__button-collection__margin-eight" />
                    Download
                </Button>
                <Button
                    className="xgs-invoice-viewer__button-collection__create-button"
                    theme={ButtonThemes.blueInverted}
                    onClick={createNewInvoice}
                >
                    <PlusBlueIcon className="xgs-btn__icon xgs-invoice-viewer__button-collection__margin-eight" />
                    Create New Invoice
                </Button>
            </div>
            <div className="xgs-invoice-viewer__invoice-view">
                <div className="xgs-invoice-viewer__invoice-view__probill-thumbnail" style={{ height: `${dynamicSize.height + 33}px` }}>
                    <div className="xgs-invoice-viewer__invoice-view__header">Probills</div>
                    {relatedLoading ? (
                        <div className="xgs-invoice-viewer__invoice-view__spinner-loader">
                            <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                        </div>
                    ) : (
                        Array.isArray(invoiceGenerateState?.image) ? (
                            invoiceGenerateState.image
                                .flatMap((record: any) => {
                                    return Array.isArray(record.images)
                                        ? record.images.filter((image: any) => {
                                            return image.docType === "INV" && image.imageUrl.includes("P01");
                                        }).map((filteredImage: any) => ({
                                            ...filteredImage,
                                            probill: record.probill
                                        }))
                                        : [];
                                })
                                .map((record: any, recordIndex: number) => {
                                    return (
                                        <div key={recordIndex} className="xgs-invoice-viewer__invoice-view__thumbnail" onClick={() => handleThumbnailClick(invoiceGenerateState?.image, record.probill)} onMouseEnter={() => setImageHoveredIndex(recordIndex)} onMouseLeave={() => setImageHoveredIndex(null)}>
                                            <div className="xgs-invoice-viewer__invoice-view__img-primary">
                                                <img className="xgs-invoice-viewer__invoice-view__img-primary__img" style={{ filter: imageHoveredIndex === recordIndex ? "brightness(93%)" : "brightness(100%)", border: selectedProbillImage === record.probill ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${record.thumbnailUrl}`} alt={record.probill} />
                                            </div>

                                            <input
                                                type="checkbox"
                                                className="xgs-invoice-viewer__invoice-view__checkbox"
                                                checked={selectedRecords[record.probill]?.has(record.imageUrl) || false}
                                                onChange={(e) => handleCheckbox(record.probill, record.imageUrl, e.target.checked)}
                                            />

                                            <div className="xgs-invoice-viewer__invoice-view__thumbnail-info">
                                                {record.probill}
                                            </div>

                                        </div>
                                    );
                                })
                        ) : (
                            <div>No records found</div>
                        )
                    )}
                </div>
                <div className="xgs-invoice-viewer__invoice-view__primary">
                    {(<div className="xgs-invoice-viewer__invoice-view__related-thumbnail" style={{ height: `${dynamicSize.height + 33}px` }}>
                        <div className="xgs-invoice-viewer__invoice-view__header">Included</div>
                        {relatedLoading && invoiceGenerateState?.image?.probill ? (
                            <div className="xgs-invoice-viewer__invoice-view__spinner-loader">
                                <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                            </div>
                        ) : (
                            relatedThumbnails
                                .map((record: any, recordIndex: number) => (
                                    <div key={recordIndex} className="xgs-invoice-viewer__invoice-view__thumbnail" onClick={() => handleCurrentThumbnailClick(record.imageUrl, record.docId, record.docType)} onMouseEnter={() => setIncludedImageHoveredIndex(recordIndex)} onMouseLeave={() => setIncludedImageHoveredIndex(null)}>
                                        <div className="xgs-invoice-viewer__invoice-view__img-primary">
                                            <img className="xgs-invoice-viewer__invoice-view__img-primary__img" style={{ filter: includedImageHoveredIndex === recordIndex ? "brightness(93%)" : "brightness(100%)", border: imageSrc === record.imageUrl ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${record.thumbnailUrl}`} alt={record.thumbnailId} />
                                        </div>

                                        <input
                                            type="checkbox"
                                            className="xgs-invoice-viewer__invoice-view__checkbox"
                                            checked={selectedRecords[currentProbill]?.has(record.imageUrl) || false}
                                            onChange={(e) => handleCheckbox(record.probill, record.imageUrl, e.target.checked)}
                                            disabled={!selectedRecords.hasOwnProperty(currentProbill) && record.docType !== "INV"}
                                        />

                                        <div className="xgs-invoice-viewer__invoice-view__thumbnail-info">
                                            {record.docId !== 0 ? record.docId : "New Invoice"}
                                        </div>
                                        <div className="xgs-invoice-viewer__invoice-view__thumbnail-info">
                                            {record.docType}
                                        </div>
                                    </div>
                                ))
                        )}
                    </div>
                    )}
                    <div className="xgs-invoice-viewer__invoice-view__invoice-view-window" style={{ gap: dynamicGap }}>
                        <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__id-primary">
                            <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__id-secondary">ID: {imageDocId}</div>
                        </div>
                        <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__primary" ref={imageContainerRef} style={{ width: `${dynamicSize.width}px`, height: `${dynamicSize.height}px`, top: calculateTop(), transform: `rotateX(${tilt.vertical}deg) rotateY(${tilt.horizontal}deg)`}}>
                            {isLoading && (
                                <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__spinner-loader">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </div>
                            )}
                            {imageSrc && (
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    rotation={rotation}
                                    cropShape="rect"
                                    cropSize={dynamicSize}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    showGrid={false}// Ensure the image can cover the container
                                    maxZoom={5}
                                    style={{
                                        cropAreaStyle: {
                                            border: "none",      // Remove crop window border
                                            opacity: 0,          // Make the crop area invisible
                                            visibility: "hidden"
                                        },
                                        mediaStyle: {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        },
                                        containerStyle: {
                                            overflow: "visible", // Try making the container overflow visible
                                        },
                                    }}
                                    onMediaLoaded={handleMediaLoaded}
                                />
                            )}

                        </div>

                        <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls">
                            <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__primary" onClick={() => rotateImage("anticlockwise")}>
                                <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__secondary">
                                    <AntiClockWiseIcon className="xgs-btn__icon xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__anticlock-icon" />
                                </div>
                            </div>
                            <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__primary" onClick={() => rotateImage("clockwise")}>
                                <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__secondary">
                                    <ClockWiseIcon className="xgs-btn__icon xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__clock-icon" />
                                </div>
                            </div>
                            <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => tiltImage("horizontal")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <TiltHorizontal className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__tilt-left-primary" />
                                    </div>
                                </div>
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => tiltImage("vertical")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <TiltVertical className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__tilt-right-primary" />
                                    </div>
                                </div>
                            <div style={{ marginTop: dynamicMarginGap }}>
                                <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__plus-minus">
                                    <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__primary"
                                        onClick={() => setZoom(zoom + 0.1)}>
                                        <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__secondary">
                                            <PlusIcon className="xgs-btn__icon xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__plus-minus-icon" />
                                        </div>
                                    </div>
                                    <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__primary"
                                        onClick={handleZoomOut}>
                                        <div className="xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__secondary">
                                            <MinusIcon className="xgs-btn__icon xgs-invoice-viewer__invoice-view__invoice-view-window__image-controls__plus-minus-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentContainer>
    )
};
export default InvoiceViewer;