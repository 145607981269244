import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Button, { ButtonThemes, ButtonSizes } from "../../../ui-components/button/button";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { ReactComponent as CopyIcon } from "../../../images/copy.svg";
import { ReactComponent as CopyIconGrey } from "../../../images/copy_grey.svg";
import { ReactComponent as RecycleIcon } from "../../../images/recycle_blue.svg";
import { ReactComponent as RecycleIconGrey } from "../../../images/recycle_grey.svg";
import { ReactComponent as DeleteIcon } from "../../../images/delete_red.svg";
import { ReactComponent as DeleteIconGrey } from "../../../images/delete_grey.svg";
import { ReactComponent as AntiClockWiseIcon } from "../../../images/rotate-anticlockwise.svg";
import { ReactComponent as ClockWiseIcon } from "../../../images/rotate-clockwise.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus_white.svg";
import { ReactComponent as MinusIcon } from "../../../images/minus_white.svg";
import { ReactComponent as TickIcon } from "../../../images/tick_black.svg";
import { ReactComponent as TickIconGrey } from "../../../images/tick_grey.svg";
import { ReactComponent as LoadingIcon } from "../../../images/loading_blue.svg";
import { ReactComponent as TiltVertical } from "../../../images/tilt-vertical.svg";
import { ReactComponent as TiltHorizontal } from "../../../images/tilt-horizontal.svg";
import mixpanel from "mixpanel-browser";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import "./imageViewer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Cropper from "react-easy-crop";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { NEW_DOCUMENT_TYPE_ACTIONS } from "../image-indexer/common";
import { acceptImages, getRelatedImages, imageViewIndexSelector, lockImages, multiProImages, passImages, rejectImages } from "../../../slices/imaging/imageViewIndexSlice";
import ImageViewIndexState from "../../../slices/imaging/imageViewIndexState";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import ImageViewState from "../../../slices/imaging/imageViewState";
import { imageViewSelector, updateThumbnail } from "../../../slices/imaging/imageViewSlice";
import { ThumbnailUpdateModel } from "../../../app/data/image-viewer/model";
import { AcceptRequestModel } from "../../../app/data/image-indexer/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";


export interface ImageDetailsProps {
    currentDocId: any[] | undefined;
    relatedThumbnail: any[] | undefined;
    show: boolean;
    onClose: () => void;
    onImageUrlChange: (newImageUrl: string, index: number) => void;
}

const ImageDetails: React.FC<ImageDetailsProps> = (props) => {
    const userState: UserState = useSelector(userSelector);
    const imageViewIndexState: ImageViewIndexState = useSelector(imageViewIndexSelector);
    const imageViewState: ImageViewState = useSelector(imageViewSelector);
    const dispatch = useDispatch();
    const [docId, setDocId] = useState<string>("");
    const [probill, setProbill] = useState<string>("");
    const [customer, setCustomer] = useState<string>("");
    const [indexedBy, setIndexedBy] = useState<string>("");
    const [indexedOn, setIndexedOn] = useState<string>("");
    const [lockedBy, setLockedBy] = useState<string>("");
    const [lockedOn, setLockedOn] = useState<string>("");
    const [docStatId, setDocStatId] = useState<string>("");
    const [userIndexedOrLocked, setUserIndexedOrLocked] = useState<string>("");
    const [dateIndexedOrLocked, setDateIndexedOrLocked] = useState<string>("");
    const [bol, setBol] = useState<string>("");
    const [po, setPo] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [docType, setDocType] = useState<XGSSelectOption | null | undefined>();
    const [newDocType, setNewDocType] = useState<XGSSelectOption | null | undefined>();
    const [related, setRelated] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const imageContainerRef = useRef<HTMLDivElement | null>(null);
    const [zoom, setZoom] = useState(1); // Zoom level
    const [rotation, setRotation] = useState(0); // Rotation angle
    const [isLoading, setIsLoading] = useState(true);
    const [relatedLoading, setRelatedLoading] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop position
    const [cropSize] = useState({ width: 432, height: 556 });// Fixed crop 
    const [imageHoveredIndex, setImageHoveredIndex] = useState<number | null>(null);
    const [showContent, setShowContent] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
    const [tilt, setTilt] = useState({ horizontal: 0, vertical: 0 });
    
    let hoverTimeout: ReturnType<typeof setTimeout>;
    const onNewDocTypeChanged = (_docType: XGSSelectOption | null | undefined) => {
        setNewDocType(_docType);
    };
    const getNewDocTypePerformedValues = () => {
        return Object.keys(NEW_DOCUMENT_TYPE_ACTIONS).map((key) => {
            return {
                value: key,
                label: NEW_DOCUMENT_TYPE_ACTIONS[key]
            };
        });
    };

    const handleMouseEnter = () => {
        hoverTimeout = setTimeout(() => {
            setShowContent(true);
        }, 1000);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout);
        setShowContent(false);
    };
    const handleMediaLoaded = () => {
        setZoom(1); // Reset zoom to 1
        setRotation(0); // Reset rotation to 0
        setIsLoading(false); // Hide spinner once image is loaded
    };
    const handleZoomOut = () => {
        setZoom((prevZoom) => {
            if (prevZoom <= 1) {
                // Reset crop position and zoom to default
                setCrop({ x: 0, y: 0 });
                return 1;
            }
            return Math.max(prevZoom - 0.1, 1);
        });
    };

    const calculateTop = () => {
        if (rotation % 180 !== 0) {
            return `${(556 - 430) / 2}px`;
        }
        return "0px"; // Default value if no top adjustment is needed
    };

    // Rotate image
    const rotateImage = (direction: any) => {
        if (direction === "clockwise") {
            setRotation((prev) => prev + 90);
        } else {
            setRotation((prev) => prev - 90);
        }
    };

    const tiltImage = (direction: any) => {
        setTilt((prev: any) => ({
            ...prev,
            [direction]: prev[direction] + 180,
        }));
    };

    const handleThumbnailClick = (record: any | null) => {
        if (record.imageUrl) {
            const imageUrlWithToken = record.imageUrl;
            setImageSrc(imageUrlWithToken); // Update imageSrc with the clicked thumbnail
            setDocId(record.Id);
            setProbill(record.probill);
            setIndexedBy(record.indexUser);
            setCustomer(record.indexData?.MCUSNO);
            setIndexedOn(record.indexDate);
            setLockedBy(record.lockUser);
            setDocStatId(record.status);
            setLockedOn(record.lockDate);
            setBol(record.indexData?.PBBOL2);
            setPo(record.indexData?.PBPO);
            setCompanyName(record.indexData?.MCUSNM);
            setDocType(record.docTypeId);
            setUserIndexedOrLocked(record.lockUser !== "" ? "Locked by" : "Indexed by");
            setDateIndexedOrLocked(Number(record.lockDate) !== 0 ? "Locked on" : "Indexed on");
        }
    };
    const handleRelatedThumbnailClick = (record: any | null) => {
        if (record.thumbnailimage) {
            const imageUrlWithToken = record.thumbnailimage;
            setImageSrc(imageUrlWithToken); // Update imageSrc with the clicked thumbnail
            setDocId(record.thumbnailId);
            setProbill(record.thumbnailprobill);
            setIndexedBy(record.indexUser);
            setIndexedOn(record.docIndexDte);
            setLockedBy(record.docLockUser);
            setDocStatId(record.thumbnailstatus);
            setLockedOn(record.docLockDate);
            setDocType(record.thumbnailTypeId);
            setUserIndexedOrLocked(record.docLockUser !== "" ? "Locked by" : "Indexed by");
            setDateIndexedOrLocked(record.docLockDate !== "" ? "Locked on" : "Indexed on");
        }
    };
    const acceptClick = async () => {
        if (probill) {
            const acceptModel = getAcceptRequestModel();
            acceptImageClick(acceptModel);
        }
    };
    const multiPro = async () => {
        if (probill) {
            const acceptModel = getAcceptRequestModel();
            multiProImageClick(acceptModel);
        }
    };
    const passClick = async () => {
        if (docId) {
            PassImageClick(docId);
        }
    };
    const lockImage = async () => {
        if (docId) {
            LockImageClick(docId);
        }
    };
    const rejectClick = async () => {
        if (docId) {
            setShowRemoveConfirmation(false);
            RejectImageClick(docId);
        }
    };
    const getAcceptRequestModel = () => {
        let acceptRequestModel: AcceptRequestModel = {
            docTypeId: newDocType ? newDocType.value : "",
            docId: docId ? docId : "",
            probill: probill ? probill : "",
            customer: customer ? customer : ""
        }
        return acceptRequestModel;
    }
    const multiProImageClick = async (multiProModel: AcceptRequestModel) => {
        try {
            if (multiProModel.probill !== "") {
                await dispatch(multiProImages(multiProModel));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const acceptImageClick = async (acceptModel: AcceptRequestModel) => {
        try {
            if (acceptModel.probill !== "") {
                await dispatch(acceptImages(acceptModel));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const PassImageClick = async (acceptModel: string) => {
        try {
            await dispatch(passImages(acceptModel));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const LockImageClick = async (acceptModel: string) => {
        try {
            await dispatch(lockImages(acceptModel));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const RejectImageClick = async (acceptModel: string) => {
        try {
            await dispatch(rejectImages(acceptModel));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchRelatedThumbnails = async (probill: string, currentDocId: string) => {
        try {
            await dispatch(getRelatedImages(probill, currentDocId));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const downloadDocument = () => {
        if (props.currentDocId?.[0]?.imageUrl) {
            const link = document.createElement("a");
            const imageUrlWithToken = props.currentDocId?.[0]?.imageUrl;
            link.href = imageUrlWithToken // Use the blob URL
            link.download = `document-${props.currentDocId?.[0]?.docId}.jpg`; // You can dynamically set the filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            mixpanel.track("Image document was downloaded (JPG)");
        }
    };
    useEffect(() => {
        if (props.currentDocId?.[0]?.imageUrl) {
            const imageUrlWithToken = props.currentDocId?.[0]?.imageUrl;
            setImageSrc(imageUrlWithToken);
            setZoom(1);
            setRotation(0);
            setDocId(props.currentDocId?.[0]?.Id);
            setProbill(props.currentDocId?.[0]?.probill === 0 ? "" : props.currentDocId?.[0]?.probill);
            setIndexedBy(props.currentDocId?.[0]?.indexUser);
            setCustomer(props.currentDocId?.[0]?.indexData?.MCUSNO);
            setIndexedOn(props.currentDocId?.[0]?.indexDate);
            setLockedBy(props.currentDocId?.[0]?.lockUser);
            setDocStatId(props.currentDocId?.[0]?.status);
            setLockedOn(props.currentDocId?.[0]?.lockDate);
            setBol(props.currentDocId?.[0]?.indexData?.PBBOL2);
            setPo(props.currentDocId?.[0]?.indexData?.PBPO);
            setCompanyName(props.currentDocId?.[0]?.indexData?.MCUSNM);
            setDocType(props.currentDocId?.[0]?.docTypeId);
            setUserIndexedOrLocked(props.currentDocId?.[0]?.lockUser !== "" ? "Locked by" : "Indexed by");
            setDateIndexedOrLocked(Number(props.currentDocId?.[0]?.lockDate) !== 0 ? "Locked on" : "Indexed on");
        }
        // eslint-disable-next-line
    }, [props.currentDocId?.[0]?.imageUrl]);

    useEffect(() => {
        if (related && props.relatedThumbnail) {
            setRelated(true);
            setRelatedLoading(false);
        }
        else {
            setRelated(false);
            setRelatedLoading(true);
        }
        // eslint-disable-next-line
    }, [related]);

    useEffect(() => {
        const fetchThumbnails = async () => {
            if (related && props.currentDocId?.[0]?.probill > 0 && (props.relatedThumbnail || []).length === 0) {
                setRelatedLoading(true);
                try {
                    await fetchRelatedThumbnails(
                        props.currentDocId?.[0]?.probill.toString(),
                        props.currentDocId?.[0]?.Id.toString()
                    );
                } catch (error) {
                    console.error("Error fetching related thumbnails:", error);
                } finally {
                    setRelatedLoading(false);
                }
            }
        };

        fetchThumbnails();
        // eslint-disable-next-line
    }, [related, props.currentDocId?.[0]?.probill]);

    useEffect(() => {
        if (docType) {
            const options = getNewDocTypePerformedValues();
            const selectedOption = options.find(option => option.value === docType.toString());
            setNewDocType(selectedOption);
        }
    }, [docType]);

    useEffect(() => {
        if (imageViewIndexState) {
            setProbill(imageViewIndexState?.image?.probill ? imageViewIndexState?.image?.probill : props.currentDocId?.[0]?.probill);
            setIndexedBy(imageViewIndexState?.image?.docIndexUsr ? imageViewIndexState?.image?.docIndexUsr : props.currentDocId?.[0]?.indexUser);
            setCustomer(imageViewIndexState?.image?.indexData?.MCUSNO ? imageViewIndexState?.image?.indexData?.MCUSNO : props.currentDocId?.[0]?.indexData?.MCUSNO);
            setIndexedOn(imageViewIndexState?.image?.docIndexDte ? imageViewIndexState?.image?.docIndexDte : props.currentDocId?.[0]?.indexDate);
            setLockedBy(imageViewIndexState?.image?.docLockedUsr ? imageViewIndexState?.image?.docLockedUsr : props.currentDocId?.[0]?.lockUser);
            setLockedOn(imageViewIndexState?.image?.docLockDte ? imageViewIndexState?.image?.docLockDte : props.currentDocId?.[0]?.lockDate);
            setDocStatId(imageViewIndexState?.image?.docStatId ? imageViewIndexState?.image?.docStatId : props.currentDocId?.[0]?.status)
            setBol(imageViewIndexState?.image?.indexData?.PBBOL2 ? imageViewIndexState?.image?.indexData?.PBBOL2 : props.currentDocId?.[0]?.indexData?.PBBOL2);
            setPo(imageViewIndexState?.image?.indexData?.PBPO ? imageViewIndexState?.image?.indexData?.PBPO : props.currentDocId?.[0]?.indexData?.PBPO);
            setCompanyName(imageViewIndexState?.image?.indexData?.MCUSNM ? imageViewIndexState?.image?.indexData?.MCUSNM : props.currentDocId?.[0]?.indexData?.MCUSNM);
            setDocType(imageViewIndexState?.image?.docTypeId ? imageViewIndexState?.image?.docTypeId : props.currentDocId?.[0]?.docTypeId);
            if (props.currentDocId && props.currentDocId.length > 0 && (imageViewIndexState?.image?.docIndexDte || imageViewIndexState?.image?.docLockDte))
                props.onImageUrlChange(imageViewIndexState?.image, 0);
        }
        if (imageViewIndexState.image) {
            let updateThumbnailModel: ThumbnailUpdateModel = {
                docId: imageViewIndexState?.image?.docId,
                probill: imageViewIndexState?.image?.probill,
                docTypeId: imageViewIndexState?.image?.docTypeId,
                docIndexUsr: imageViewIndexState?.image?.docIndexUsr,
                docIndexDte: imageViewIndexState?.image?.docIndexDte,
                indexData: imageViewIndexState?.image?.indexData,
                docLockedUsr: imageViewIndexState?.image?.docLockedUsr,
                docLockDte: imageViewIndexState?.image?.docLockDte,
                docStatId: imageViewIndexState?.image?.docStatId
            };
            dispatch(updateThumbnail(updateThumbnailModel));
        }
        // eslint-disable-next-line
    }, [imageViewIndexState]);

    useEffect(() => {
        imageViewIndexState?.image?.docLockedUsr ? setUserIndexedOrLocked("Locked by") : setUserIndexedOrLocked("Indexed by");
        imageViewIndexState?.image?.docLockDte ? setDateIndexedOrLocked("Locked on") : setDateIndexedOrLocked("Indexed on");
    }, [imageViewIndexState?.image?.docLockedUsr, imageViewIndexState?.image?.docIndexUsr, imageViewIndexState?.image?.docLockDte, imageViewIndexState?.image?.docIndexDte]);

    useEffect(() => {
        props.onClose();
        // eslint-disable-next-line
    }, [imageViewState.requestStarted && imageViewState.requestCreator === "DELETE SELECTED IMAGE"])

    return (
        <SlideOutSidebar
            header="Image Details"
            onClose={props.onClose}
            show={props.show}
            className="xgs-teams__details"
        >
            <>
                <div className="xgs-image-view-indexer">
                    <div className="xgs-image-view-indexer__header">
                        <div className="xgs-image-view-indexer__header__primary">
                            <div className="xgs-image-view-indexer__header__header-name">
                                <div className="xgs-image-view-indexer__header__header-name__content">Re-Indexing</div>
                            </div>
                            <div className="xgs-image-view-indexer__header__i-icon">
                                <div className="xgs-image-view-indexer__header__i-icon__primary" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    {showContent && (
                                        <div className="xgs-image-view-indexer__header__i-icon__secondary">
                                            View, index or change document type
                                        </div>
                                    )}
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="xgs-image-view-indexer__indexed-image-buttons">
                        <div className="xgs-image-view-indexer__indexed-image-buttons__indexed-on-by-primary">
                            <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__third">
                                    <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">{userIndexedOrLocked}</div>
                                    <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">:</div>
                                </div>
                                <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                    <div className="xgs-image-view-indexer__indexed-image-buttons__fifth">{lockedBy !== "" ? lockedBy : indexedBy ?? ""}</div>
                                </div>
                            </div>
                            <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__third">
                                    <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">{dateIndexedOrLocked}</div>
                                    <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">:</div>
                                </div>
                                <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                    <div className="xgs-image-view-indexer__indexed-image-buttons__fifth">{Number(lockedOn) !== 0 ? lockedOn : indexedOn ?? ""}</div>
                                </div>
                            </div>
                        </div>
                        <div className="xgs-image-view-indexer__indexed-image-buttons__collections">
                            <div className="xgs-image-view-indexer__indexed-image-buttons__collections__primary">
                                <Button
                                    theme={ButtonThemes.transparent}
                                    size={ButtonSizes.auto}
                                    className={"xgs-image-indexer__small-button"}
                                    onClick={downloadDocument}>
                                    <DownloadIcon className="xgs-image-view-indexer__indexed-image-buttons__collections__download-copy-icon" />
                                    Download
                                </Button>
                                {UserUtils.isEmployee(userState.profile) && <Button
                                    theme={ButtonThemes.transparent}
                                    size={ButtonSizes.auto}
                                    onClick={multiPro}
                                    className={"xgs-image-indexer__small-button"}
                                    disabled={(imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "MULTIPRO_IMAGE_REQUEST") || (!(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999))}>
                                    {imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "MULTIPRO_IMAGE_REQUEST" ? (
                                        <LoadingIcon className="spin xgs-image-view-indexer__indexed-image-buttons__collections__margin-left" />
                                    ) : (
                                        (!(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999)) ?
                                            <CopyIconGrey className="xgs-image-view-indexer__indexed-image-buttons__collections_margin-right" /> : <CopyIcon className="xgs-image-view-indexer__indexed-image-buttons__collections__download-copy-icon" />
                                    )}
                                    Multi Pro
                                </Button>}
                                {UserUtils.isEmployee(userState.profile) && <Button
                                    theme={ButtonThemes.transparent}
                                    size={ButtonSizes.auto}
                                    onClick={lockImage}
                                    className={"xgs-image-indexer__small-button"}
                                    disabled={(lockedBy !== "" || docStatId === "A" || indexedBy !== "") || (imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "LOCK_INDEXING_IMAGES")}>
                                    {imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "LOCK_INDEXING_IMAGES" ? (
                                        <LoadingIcon className="spin xgs-image-view-indexer__indexed-image-buttons__collections__margin-left" />
                                    ) : (
                                        ((lockedBy !== "" || docStatId === "A" || indexedBy !== "")) ?
                                            <RecycleIconGrey className="xgs-image-view-indexer__indexed-image-buttons__collections_margin-right" /> : <RecycleIcon className="xgs-image-view-indexer__indexed-image-buttons__collections_margin-right" />
                                    )}
                                    Reindex
                                </Button>}
                                <div className="xgs-image-view-indexer__indexed-image-buttons__collections__related-toggle">
                                    <XGSToggle
                                        onChange={setRelated}
                                        checked={related}
                                        label=""
                                    />
                                    <span className="xgs-image-view-indexer__indexed-image-buttons__collections__related-toggle__name" >Related</span>
                                </div>
                            </div>
                            {UserUtils.isEmployee(userState.profile) && <div className="xgs-image-view-indexer__indexed-image-buttons__collections__reject">
                                <Button
                                    theme={ButtonThemes.transparent}
                                    size={ButtonSizes.auto}
                                    onClick={() => setShowRemoveConfirmation(true)}
                                    className={"xgs-image-viewer__small-button-delete"}
                                    disabled={(props.currentDocId?.[0]?.probill > 0 && (props.currentDocId?.[0]?.indexUser !== "" || props.currentDocId?.[0]?.lockUser !== "")) || (imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "REJECT_INDEXING_IMAGES")}>
                                    {imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "REJECT_INDEXING_IMAGES" ? (
                                        <LoadingIcon className="spin xgs-image-view-indexer__indexed-image-buttons__collections__margin-left" />
                                    ) : (
                                        (props.currentDocId?.[0]?.probill > 0 && (props.currentDocId?.[0]?.indexUser !== "" || props.currentDocId?.[0]?.lockUser !== "")) ?
                                            <DeleteIconGrey className="xgs-image-view-indexer__indexed-image-buttons__collections_margin-right" /> : <DeleteIcon className="xgs-image-view-indexer__indexed-image-buttons__collections_margin-right" />
                                    )}
                                    Reject
                                </Button>
                            </div>}
                        </div>
                    </div>
                    <div className="xgs-image-view-indexer__related-original-images">
                        {related && (<div className="xgs-image-view-indexer__related-original-images__related">
                            <div className="xgs-image-view-indexer__related-original-images__related__original" onClick={() => handleThumbnailClick(props.currentDocId?.[0])} onMouseEnter={() => setImageHoveredIndex(-1)} onMouseLeave={() => setImageHoveredIndex(null)}>
                                <div className="xgs-image-view-indexer__related-original-images__related__original__img-primary">
                                    <img className="xgs-image-view-indexer__related-original-images__related__original__img-secondary" style={{ filter: imageHoveredIndex === -1 ? "brightness(93%)" : "brightness(100%)", border: docId === props.currentDocId?.[0]?.Id ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${props.currentDocId?.[0]?.thumbnail}`} alt={props.currentDocId?.[0]?.Id} />
                                </div>
                                <div className="xgs-image-view-indexer__related-original-images__related__original__tick-primary">
                                    <div className="xgs-image-view-indexer__related-original-images__related__original__tick-secondary">
                                        <TickIcon className="xgs-btn__icon xgs-image-view-indexer__related-original-images__related__original__tick-third" />
                                    </div>
                                </div>

                                <div className="xgs-image-view-indexer__related-original-images__related__original__id-type">
                                    {props.currentDocId?.[0]?.Id}
                                </div>
                                <div className="xgs-image-view-indexer__related-original-images__related__original__id-type">
                                    {props.currentDocId?.[0]?.type}
                                </div>
                            </div>


                            <div className="xgs-image-view-indexer__related-original-images__related__related-label">Related</div>
                            {relatedLoading ? (
                                // Display the spinner during loading
                                <div className="xgs-image-view-indexer__related-original-images__related__spinner-loading">
                                    <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                                </div>
                            ) : (
                                // Check if related thumbnails exist, otherwise fallback to imageViewIndexState thumbnails
                                props.relatedThumbnail && props.relatedThumbnail.length >= 1 ? (
                                    props.relatedThumbnail
                                        .filter((record: any) => record.Id !== props.currentDocId?.[0]?.Id && record.probill === props.currentDocId?.[0]?.probill)
                                        .map((record: any, recordIndex: number) => (
                                            <div key={recordIndex} className="xgs-image-view-indexer__related-original-images__related__thumbnails" onClick={() => handleThumbnailClick(record)} onMouseEnter={() => setImageHoveredIndex(recordIndex)} onMouseLeave={() => setImageHoveredIndex(null)}>
                                                <div className="xgs-image-view-indexer__related-original-images__related__original__img-primary">
                                                    <img className="xgs-image-view-indexer__related-original-images__related__thumbnails__image" style={{ filter: imageHoveredIndex === recordIndex ? "brightness(93%)" : "brightness(100%)", border: docId === record.Id ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${record.thumbnail}`} alt={record.Id} />
                                                </div>
                                                <div className="xgs-image-view-indexer__related-original-images__related__original__id-type">
                                                    {record.Id}
                                                </div>
                                                <div className="xgs-image-view-indexer__related-original-images__related__original__id-type">
                                                    {record.type}
                                                </div>
                                            </div>
                                        ))
                                ) :
                                    (
                                        // Fallback to imageViewIndexState related thumbnails if relatedThumbnail is empty
                                        imageViewIndexState.relatedThumbnails.map((record: any, recordIndex: number) => (
                                            <div key={recordIndex} className="xgs-image-view-indexer__related-original-images__related__thumbnails" onClick={() => handleRelatedThumbnailClick(record)} onMouseEnter={() => setImageHoveredIndex(recordIndex)} onMouseLeave={() => setImageHoveredIndex(null)}>
                                                <div className="xgs-image-view-indexer__related-original-images__related__original__img-primary">
                                                    <img className="xgs-image-view-indexer__related-original-images__related__thumbnails__image" style={{ filter: imageHoveredIndex === recordIndex ? "brightness(93%)" : "brightness(100%)", border: docId === record.thumbnailId ? "2px solid #2F80ED" : "2px solid transparent" }} src={`data:image/jpeg;base64,${record.thumbnail}`} alt={record.thumbnailId} />
                                                </div>
                                                <div className="xgs-image-view-indexer__related-original-images__related__original__id-type">
                                                    {record.thumbnailId}
                                                </div>
                                                <div className="xgs-image-view-indexer__related-original-images__related__original__id-type">
                                                    {record.thumbnailtype}
                                                </div>
                                            </div>
                                        ))
                                    )
                            )}
                        </div>
                        )}
                        <div className="xgs-image-view-indexer__image-container" style={{ width: related ? "calc(100% - 84px)" : "100%", gap: related ? "calc((100% - 84px) / 2 - 232px)" : "calc((100% - 84px) / 2 - 232px)" }}>
                            <div className="xgs-image-view-indexer__image-container__id-primary">
                                <div className="xgs-image-view-indexer__image-container__id-secondary">ID {docId}</div>
                            </div>

                            <div ref={imageContainerRef} className="xgs-image-view-indexer__image-container__primary" style={{ top: calculateTop(), transform: `rotateX(${tilt.vertical}deg) rotateY(${tilt.horizontal}deg)` }}>
                                {isLoading && (
                                    <div className="xgs-image-view-indexer__image-container__loading-spinner">
                                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                    </div>
                                )}
                                {imageSrc && (
                                    <Cropper
                                        image={imageSrc}
                                        // transform="translate(-57.9601px, -66.2473px)"
                                        crop={crop}
                                        zoom={zoom}
                                        rotation={rotation}
                                        cropShape="rect"
                                        cropSize={cropSize}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        showGrid={false}
                                        maxZoom={5}
                                        style={{
                                            cropAreaStyle: {
                                                border: "none",
                                                opacity: 0,
                                                visibility: "hidden"
                                            },
                                            mediaStyle: {
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover"
                                            },
                                            containerStyle: {
                                                overflow: "visible",
                                            },
                                        }}
                                        onMediaLoaded={handleMediaLoaded}
                                    />
                                )}

                            </div>

                            <div className="xgs-image-view-indexer__image-container__img-controls">
                                <div className="xgs-image-view-indexer__image-container__img-controls__anticlock-primary" onClick={() => rotateImage("anticlockwise")}>
                                    <div className="xgs-image-view-indexer__image-container__img-controls__anticlock-secondary">
                                        <AntiClockWiseIcon className="xgs-btn__icon xgs-image-view-indexer__image-container__img-controls__anticlock-icon" />
                                    </div>
                                </div>
                                <div className="xgs-image-view-indexer__image-container__img-controls__clock-plus-minus-primary" onClick={() => rotateImage("clockwise")}>
                                    <div className="xgs-image-view-indexer__image-container__img-controls__anticlock-secondary">
                                        <ClockWiseIcon className="xgs-btn__icon xgs-image-view-indexer__image-container__img-controls__clock-plus-minus-primary_clock-secondary" />
                                    </div>
                                </div>
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => tiltImage("horizontal")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <TiltHorizontal className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__tilt-left-primary" />
                                    </div>
                                </div>
                                <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-primary" onClick={() => tiltImage("vertical")}>
                                    <div className="xgs-image-indexer__image-to-index__image-controls-primary__button-icon-secondary">
                                        <TiltVertical className="xgs-btn__icon xgs-image-indexer__image-to-index__image-controls-primary__tilt-right-primary" />
                                    </div>
                                </div>
                                <div className="xgs-image-view-indexer__image-container__img-controls__plus-minus-icon">
                                    <div className="xgs-image-view-indexer__image-container__img-controls__plus-minus">
                                        <div className="xgs-image-view-indexer__image-container__img-controls__clock-plus-minus-primary"
                                            onClick={() => setZoom(zoom + 0.1)}>
                                            <div className="xgs-image-view-indexer__image-container__img-controls__anticlock-secondary">
                                                <PlusIcon className="xgs-btn__icon xgs-image-view-indexer__image-container__img-controls__clock-plus-minus-primary__plus-minus-secondary" />
                                            </div>
                                        </div>
                                        <div className="xgs-image-view-indexer__image-container__img-controls__clock-plus-minus-primary"
                                            onClick={handleZoomOut}>
                                            <div className="xgs-image-view-indexer__image-container__img-controls__anticlock-secondary">
                                                <MinusIcon className="xgs-btn__icon xgs-image-view-indexer__image-container__img-controls__clock-plus-minus-primary__plus-minus-secondary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="xgs-image-view-indexer__img-details-two">
                        <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                            <div className="xgs-image-view-indexer__indexed-image-buttons__third">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">BOL</div>
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">:</div>
                            </div>
                            <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fifth">{bol ?? ""}</div>
                            </div>
                        </div>

                        <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                            <div className="xgs-image-view-indexer__indexed-image-buttons__third">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">PO</div>
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">:</div>
                            </div>
                            <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fifth">{po ?? ""}</div>
                            </div>
                        </div>

                        <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                            <div className="xgs-image-view-indexer__indexed-image-buttons__third">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">Company Name</div>
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fourth">:</div>
                            </div>
                            <div className="xgs-image-view-indexer__indexed-image-buttons__secondary">
                                <div className="xgs-image-view-indexer__indexed-image-buttons__fifth">{companyName ?? ""}</div>
                            </div>
                        </div>
                    </div>
                    <div className="xgs-image-view-indexer__img-details-insertion-buttons">
                        <div className="xgs-image-view-indexer__indexed-image-buttons__indexed-on-by-primary">
                            {UserUtils.isEmployee(userState.profile) && <LabeledTextInput
                                label="Probill#"
                                labelMode={LabelModes.column}
                                className="xgs-image-view-indexer__indexed-image-buttons__indexed-on-by-primary__probill-customer"
                                value={probill}
                                onChange={(e) => setProbill(e.currentTarget.value)}
                                type="text"
                                placeholder="Probill Number"
                            />}
                            {UserUtils.isEmployee(userState.profile) && <LabeledTextInput
                                label="Customer#"
                                labelMode={LabelModes.column}
                                className="xgs-image-view-indexer__indexed-image-buttons__indexed-on-by-primary__probill-customer"
                                value={customer}
                                onChange={(e) => setCustomer(e.currentTarget.value)}
                                type="text"
                                placeholder="Customer Number"
                            />}

                            <LabeledSelectInput
                                className="xgs-image-indexer__filter__input"
                                onValueChange={onNewDocTypeChanged}
                                value={newDocType}
                                options={getNewDocTypePerformedValues()}
                                label="Document type:"
                                labelMode={LabelModes.column}
                            />
                        </div>
                        <div className="xgs-image-view-indexer__img-details-insertion-buttons__button-primary">
                            {UserUtils.isEmployee(userState.profile) && <Button
                                theme={ButtonThemes.gray}
                                onClick={passClick}
                                className="xgs-image-view-indexer__img-details-insertion-buttons__button-primary__pass"
                                spinner={imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "PASS_INDEXING_IMAGES"}
                                disabled={(props.currentDocId?.[0]?.probill > 0 && (props.currentDocId?.[0]?.indexUser !== "" || props.currentDocId?.[0]?.lockUser !== ""))}
                            >
                                Pass
                            </Button>}
                            <Button
                                theme={ButtonThemes.blue}
                                onClick={acceptClick}
                                spinner={imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "ACCEPT_INDEXING_IMAGES"}
                                disabled={
                                    (probill
                                        ? !(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999)
                                        : !(customer && parseInt(customer, 10) > 100)
                                    )
                                }
                            >
                                {
                                    (probill
                                        ? !(/^\d+$/.test(probill) && parseInt(probill, 10) >= 1000000 && parseInt(probill, 10) <= 99999999)
                                        : !(customer && parseInt(customer, 10) > 100)
                                    )
                                        ? <TickIconGrey className="xgs-btn__icon xgs-image-view-indexer__indexed-image-buttons__collections_margin-right" /> : <TickIcon className="xgs-btn__icon xgs-image-view-indexer__img-details-insertion-buttons__button-primary__tick-icon" />}
                                Accept
                            </Button>
                        </div>
                    </div>
                </div>
            </>
            <ConfirmationModal
                opened={showRemoveConfirmation}
                header="Delete record"
                confirmButtonText="Delete record"
                spinner={imageViewIndexState.requestStarted && imageViewIndexState.requestCreator === "REJECT_INDEXING_IMAGES"}
                onCancel={() => { setShowRemoveConfirmation(false); }}
                onConfirm={() => { rejectClick(); }}
            >
                Are you sure you want to reject this?
            </ConfirmationModal>
        </SlideOutSidebar>
    );
};

export default ImageDetails;