import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import InvoiceGeneratorService from "../../app/data/invoice-generator/invoiceGeneratorService";
import { IState } from "..";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";
import { initialInvoiceGenerateState } from "./invoiceGenerateState";
import { DOCUMENT_TYPE_ID_ACTIONS } from "../../features/imaging/image-viewer/common";
import { InvoiceGenerateModel } from "../../app/data/invoice-generator/models";

const InvoiceGenerateService = InvoiceGeneratorService.getInstance();
export const invoiceGenerateSlice = createSlice({
    name: "generate-invoice",
    initialState: initialInvoiceGenerateState,
    reducers: {
        resetInvoiceGenerateState: (state) => initialInvoiceGenerateState,
        requestStarted: (state, { payload }) => {
            state.requestStarted = true;
            state.requestCreator = payload;
        },
        requestFailed: (state) => {
            state.requestStarted = false;
        },
        requestSucceed: (state) => {
            state.requestStarted = false;
        },
        storeGenerateReturnDetails: (state, { payload }) => {
            if (payload && payload.length > 0) {
              // Map through the payload to extract data for each probill
              state.image = payload.map((item: any) => {
                // For each item, extract the Probill and Images
                const { probill, images } = item;
                // Map each image object to store image-specific details
                const mappedImages = images.map((image: any) => ({
                  imageUrl: image.image,
                  thumbnailUrl: image.thumbnail,
                  docId: image.docId,
                  probill: image.probill,
                  docType: typeof image.docType === "number" ? DOCUMENT_TYPE_ID_ACTIONS[image.docType] || "UNK" : image.docType, // Map numeric docType using DOCUMENT_TYPE_ID_ACTIONS
                }));
                // Return an object with probill and mapped image details
                return {
                  probill: probill,
                  images: mappedImages,
                };
              });
            }
          }
    }
})

export const {
    resetInvoiceGenerateState,
    requestStarted,
    requestFailed,
    requestSucceed,
    storeGenerateReturnDetails

} = invoiceGenerateSlice.actions;

export const invoiceGenerateSelector = (state: IState) => {
    return state.invoiceGenerator;
};

export const generateInvoice = (
    invoiceGenerateModel: InvoiceGenerateModel
): AppThunk => async (dispatch) => {
    dispatch(requestStarted("START_INVOICE_GENERATION"));

    try {
        const response = await InvoiceGenerateService.generateInvoiceClick(invoiceGenerateModel);
        if (response.ok()) {
            dispatch(requestSucceed());
            dispatch(storeGenerateReturnDetails(response.data));
        } else {
            const errorMessage = response.getError ? response.getError() : ERROR_MESSAGES.COMMON;
            dispatch(requestFailed());
            toast.error(errorMessage);
        }
        return response; 
    } catch (error) {
        const errorMessage = (error as any).response?.data?.message || "Error fetching images";
        dispatch(requestFailed());
        toast.error(errorMessage);
        throw error; 
    }
};

const invoiceGenerateReducer = invoiceGenerateSlice.reducer;
export default invoiceGenerateReducer;