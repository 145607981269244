import * as Yup from "yup";
export interface InvoiceGenerateModel {
    probills: string,
    isViewBalance: boolean,
    isAth: boolean,
    isBol: boolean,
    isMsc: boolean,
    isPod: boolean
}

export const invoiceFormValidationSchema: Yup.ObjectSchema<InvoiceGenerateModel> = Yup.object().shape({
    probills: Yup.string()
        .required("Probill input cannot be empty.")
        .test(
            "no-special-characters",
            "Only numbers and commas are allowed.",
            (value) => /^[\d,]*$/.test(value || "")
        )
        .test(
            "no-more-than-20",
            "The maximum number of probills allowed is 20.",
            (value) => (value?.split(",").filter(Boolean).length || 0) <= 20
        )
        .test(
            "valid-length",
            "Each probill should be 7 or 8 digits long and contain only numbers.",
            (value) =>
                (value?.split(",").filter(Boolean) || []).every(
                    (probill: string) => /^\d{7,8}$/.test(probill)
                )
        ),
    isViewBalance: Yup.boolean().required(),
    isAth: Yup.boolean().required(),
    isBol: Yup.boolean().required(),
    isMsc: Yup.boolean().required(),
    isPod: Yup.boolean().required(),
}).defined();