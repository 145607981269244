export const DOCUMENT_TYPE_ACTIONS = {
    "LOCKED": "Locked",
    "INDEXED": "Indexed",
    "NONINDEXED": "Non Indexed",
    "IMPORTREVIEW": "Import Preview"
};
export const NEW_DOCUMENT_TYPE_ACTIONS = {
    "1": "ATH",
    "2": "Bills Of Landing",
    "12": "Credit",
    "3": "Customer",
    "4": "Deleted After Import",
    "5": "EDI",
    "6": "Invoice",
    "10": "Manifest",
    "7": "Miscellaneous",
    "8": "No Document Type",
    "9": "Proof Of Delivery",
    "11": "Unknown",
    "13": "Warehouse Receipt"
};