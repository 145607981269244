import api, { ApiResponse } from "./../api";
class ImageIndexerService {
    private static instance: ImageIndexerService;
    private constructor() { }

    static getInstance(): ImageIndexerService {
        if (!ImageIndexerService.instance) {
            ImageIndexerService.instance = new ImageIndexerService();
        }
        return ImageIndexerService.instance;
    }
    public getImages = async (
        payload: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/docimagedetails", payload);
    };
    public getRecordNumber = async (
        payload: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/docnumbers", payload);
    };
    public getRelatedThumbnails = async (
        probill: any,
        currentDocId: string
    ): Promise<ApiResponse<any>> => {
        return await api.get("/image-index/relateddoc", {
            params: {
                probill,
                currentDocId// Set the "Probill" header
            }
        });
    };
    public acceptImageClick = async (
        payload: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/accept", payload);
    };
    public multiProImageClick = async (
        payload: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/duplicate", payload);
    };
    public lockImageClick = async (
        documentId: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/reindex", {}, {
            params: {
                documentId
            }
        });
    };
    public passImageClick = async (
        documentId: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/pass", {}, {
            params: {
                documentId
            }
        });
    };
    public rejectImageClick = async (
        documentId: any
    ): Promise<ApiResponse<any>> => {
        return await api.post("/image-index/reject", {}, {
            params: {
                documentId
            }
        });
    };

}
export default ImageIndexerService;