import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import XGSNavLink from "../../ui-components/xgs-nav-link/xgsNavLink";
import CustomCell, {
  CustomCellTypes,
} from "../../ui-components/table/custom-cells/customCell";
import { AppointmentColumnCell } from "./AppointmentColumnCell";
import ShipmentModel from "../../app/data/tracking/ShipmentModel";

const columnHelper = createColumnHelper<ShipmentModel>();

const getAppointmentSummaryColumns = () => [
  columnHelper.accessor("invoice.invoiceNumber", {
    id: "invoice.invoiceNumber",
    header: "Probill",    
    cell: (cellProps) => (
      <XGSNavLink
        className="xgs-table-link-cell"
        to={`/shipments/${cellProps.getValue()}`}
      >
        {cellProps.getValue()}
      </XGSNavLink>
    ),
    size: 90,
    minSize: 90,
  }),
  columnHelper.accessor("status.translate", {
    id: "status.translate",
    header: "Probill Status",    
    size: 145,
    minSize: 145
  }),
  columnHelper.accessor("invoice.dateInvoice", {
    id: "invoice.dateInvoice",
    header: "Bill Date",    
    cell: (cellProps) => (
      <div>
        <CustomCell
          cellType={CustomCellTypes.date}
          data={cellProps.row.original}
          value={cellProps.getValue()}
        />
      </div>
    ),
    size: 85,
    minSize: 85,
  }),
  columnHelper.accessor("estimatedDeliveryDate", {
    id: "estimatedDeliveryDate",
    header: "ETA Date",
    cell: (cellProps) => (
      <div>
        <CustomCell
          cellType={CustomCellTypes.date}
          data={cellProps.row.original}
          value={cellProps.getValue()}
        />
      </div>
    ),
    size: 85,
    minSize: 85,
  }),
  columnHelper.accessor("shipper.info", {
    id: "shipper.info",
    header: "Shipper Name",    
    size: 170,
    minSize: 170
  }),
  columnHelper.accessor("consignee.info", {
    id: "consignee.info",
    header: "Consignee name",    
    size: 170,
    minSize: 170
  }),
  columnHelper.display({
    id: "appointment",
    header: "Appointment",
    size: 175,
    minSize: 175,
    cell: (cellProps) => (
      <>
        <AppointmentColumnCell
          date={cellProps.row.original.invoiceAppointment?.date}
          appointmentPending={!cellProps.row.original.invoiceAppointment?.confirmedDateTime}
          earlyTime={cellProps.row.original.invoiceAppointment?.earlyTime}
          lateTime={cellProps.row.original.invoiceAppointment?.lateTime}
        />
      </>
    )
  }),
  columnHelper.display({
    id: "exception",
    header: "Exception",
    size: 188,
    minSize: 188,
    cell: (cellProps) => (
      <>
        <AppointmentColumnCell
          exceptionStatus={cellProps.row.original.exception?.reason}
        />
      </>
    )
  })
];

export default getAppointmentSummaryColumns;
