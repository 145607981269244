import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import * as Yup from "yup";
export interface ImageSearchRequestModel {
  probill: number | string,
  documentId: number | string,
  customer: number | string,
  bol: string,
  po: string,
  docType: XGSSelectOption | null | undefined,
  location: XGSSelectOption | null | undefined,
  status: XGSSelectOption | null | undefined,
  from: string,
  to: string,
  limit: number,
  isRestoreImage: boolean,
}

export interface ImageSearchValidationModel {
  probill: string,
  documentId: string,
  customer: string,
  bol: string,
  po: string,
  from: string,
  to: string
}

export interface ThumbnailUpdateModel {
  docId: number,
  probill: number | null | undefined,
  docTypeId: string,
  docIndexUsr: string | null | undefined,
  docIndexDte: string | null | undefined,
  indexData: IndexData | null | undefined,
  docLockedUsr: string | null | undefined,
  docLockDte: string | null | undefined,
  docStatId: number
}

interface IndexData {
  MCUSNM: string | null | undefined,
  MCUSNO: number | null | undefined,
  PBBOL2: string | null | undefined,
  PBPO: string | null | undefined
}

export const imageSearchValidationSchema: Yup.ObjectSchema<ImageSearchValidationModel> = Yup.object()
  .shape({
    probill: Yup.string()
      .test("min one filled", "at lease one should be filled", function (value) {
        const { customer, probill, bol, po, documentId, from, to } = this.parent;
        return !(customer === undefined && probill === undefined && bol === undefined && po === undefined 
        && documentId === undefined && (from === undefined || from === null) && (to === undefined || from === null));
      })
      .matches(/^\d+$/, "Only whole numbers are allowed.")
      .min(7, "Probill should be min 7 digits or less")
      .max(9, "Probill should be max 8 digits or less"),
    customer: Yup.string()
      .matches(/^\d+$/, "Only whole numbers are allowed.")
      .min(3, "Customer must be at least 3 digits or empty."),
    bol: Yup.string()
      .min(4, "BOL must be at least 4 characters or empty"),
    po: Yup.string()
      .min(3, "PO must be at least 3 characters or empty."),
    documentId: Yup.string()
      .matches(/^\d+$/, "Only whole numbers are allowed.")
      .min(8, "Document ID must be greater than 10000000 or empty."),
    from: Yup.string()
      .nullable()
      .test("is-valid-from", "Start and end dates must both be provided or left empty.", function (value) {
        const { from, to } = this.parent;
        return (!from && !to) || (from && to);
      }),
    to: Yup.string()
      .nullable()
      .test("is-valid-to", "Start and end dates must both be provided or left empty.", function (value) {
        const { from, to } = this.parent;
        return (!from && !to) || (from && to);
      }),
  }).defined()