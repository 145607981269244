import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import "./imageViewer.scss";
import "../image-indexer/imageIndexer.scss"
import "../../shipments/delivery-records/deliveryRecords.scss"
import { DOCUMENT_STAT_ID_ACTIONS, DOCUMENT_TYPE_ACTIONS, DOCUMENT_TYPE_ID_ACTIONS, LOCATION_ACTIONS, STATUS_ACTIONS } from "./common";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { getThumbnails, imageViewSelector, downloadAllDocuments, deleteSelectedImage, resetImageViewState } from "../../../slices/imaging/imageViewSlice";
import ImageViewState from "../../../slices/imaging/imageViewState";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import mixpanel from "mixpanel-browser";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { ReactComponent as RestoreIcon } from "../../../images/restore.svg";
import { ReactComponent as RestoreGreyIcon } from "../../../images/restore_grey.svg";
import { ReactComponent as LoadingIcon } from "../../../images/loading_blue.svg";
import { ReactComponent as DownloadIconGrey } from "../../../images/download_grey.svg";
import ImageDetails from "./imageViewIndex";
import { resetImageViewIndexState } from "../../../slices/imaging/imageViewIndexSlice";
import { ImageSearchRequestModel, ImageSearchValidationModel, imageSearchValidationSchema } from "../../../app/data/image-viewer/model";
import ReactViewer from "react-viewer";
import { Formik, FormikProps, Form } from "formik";

const ImageViewer: React.FC<{}> = (props) => {
  const imageViewState: ImageViewState = useSelector(imageViewSelector);
  const dispatch = useDispatch();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [showGallery, setShowGallery] = useState<boolean>(false);
  const [imageHoveredIndex, setImageHoveredIndex] = useState<number | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<{ [probill: string]: Set<number> }>({});
  const [areAllRecordsSelected, setareAllRecordsSelected] = useState<boolean>(false);
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  const [docDetailsRecord, setdocDetailsRecord] = useState<any[]>([]);
  const [filteredThumbnails, setFilteredThumbnails] = useState<any[]>([]);
  const [hoveredIconId, setHoveredIconId] = useState<string | null>(null);
  const [deletingImageId, setDeletingImageId] = useState<number[]>([]);
  const [docType, setDocType] = useState<XGSSelectOption | null | undefined>();
  const [location, setLocation] = useState<XGSSelectOption | null | undefined>();
  const [status, setStatus] = useState<XGSSelectOption | null | undefined>();
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const pageSize = 12; // The number of items per page
  const [dynamicHeight, setDynamicHeight] = useState(404);
  const contentRef = useRef<HTMLDivElement>(null);
  const imageSearchFormRef = useRef<FormikProps<ImageSearchValidationModel>>(null);
  const [disableImageViewer, setDisableImageViewer] = useState<boolean>(false);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const initialValues: ImageSearchValidationModel = {
    probill: "",
    documentId: "",
    customer: "",
    bol: "",
    po: "",
    from: "",
    to: ""
  };

  const statusStyles: { [key: string]: { background: string; color: string; description: string } } = {
    A: { background: "rgba(93, 199, 84, 0.16)", color: "#14870A", description: "Auto Indexed" },
    M: { background: "rgba(151, 71, 255, 0.16)", color: "#7936D0", description: "Manually Indexed" },
    I: { background: "rgba(79, 95, 114, 0.16)", color: "#4F5F72", description: "Imported" },
    IN: { background: "rgba(255, 201, 71, 0.16)", color: "#D89100", description: "Inspected" },
    D: { background: "rgba(255, 71, 71, 0.16)", color: "#D02020", description: "Deleted" },
    IR: { background: "rgba(71, 181, 255, 0.16)", color: "#2074D0", description: "Import Review" },
    MIA: { background: "rgba(255, 133, 71, 0.16)", color: "#D06520", description: "Missing" },
    N: { background: "rgba(255, 238, 71, 0.16)", color: "#D0C320", description: "New" },
    P: { background: "rgba(199, 199, 199, 0.16)", color: "#7A7A7A", description: "Passed" },
    X: { background: "rgba(71, 255, 181, 0.16)", color: "#20D074", description: "Purge" },
    R: { background: "rgba(128, 0, 128, 0.16)", color: "#800080", description: "Review" }
  };
  const getDocTypePerformedValues = () => {
    return Object.keys(DOCUMENT_TYPE_ACTIONS).map((key) => {
      return {
        value: key,
        label: DOCUMENT_TYPE_ACTIONS[key]
      };
    });
  };
  const getLocationPerformedValues = () => {
    return Object.keys(LOCATION_ACTIONS).map((key) => {
      return {
        value: key,
        label: LOCATION_ACTIONS[key]
      };
    });
  };
  const getStatusPerformedValues = () => {
    return Object.keys(STATUS_ACTIONS).map((key) => {
      return {
        value: key,
        label: STATUS_ACTIONS[key]
      };
    });
  };
  const searchRecordsClick = (data: ImageSearchValidationModel) => {
    if (page === 1)
      searchRecords(data);
    setPage(1);
  };
  const searchRecords = (data: ImageSearchValidationModel) => {
    if (!(imageViewState.requestStarted && (imageViewState.requestCreator === "IMAGE_THUMBNAIL_DETAILS" || imageViewState.requestCreator === "RESTORE_DELETED_IMAGES"))) {
      let searchRequestModel = getSearchRequestModel(data);
      dispatch(getThumbnails(searchRequestModel, false, page, pageSize));
      setSelectedRecords({});
      setareAllRecordsSelected(false);
      setTotalSelectedCount(0);
    }
  };

  const searchRecordsOnScroll = () => {
    if (imageSearchFormRef.current?.isValid && !(imageViewState.requestStarted && imageViewState.requestCreator === "IMAGE_THUMBNAIL_DETAILS")) {
      let searchRequestModel = getSearchRequestModel(imageSearchFormRef.current?.values);
      if (JSON.stringify(searchRequestModel) === JSON.stringify(imageViewState.payload)) {
        dispatch(getThumbnails(searchRequestModel, false, page, pageSize));
        setSelectedRecords({});
        setareAllRecordsSelected(false);
        setTotalSelectedCount(0);
      }
    }
  };

  const restoreDeleted = () => {
    if (imageSearchFormRef.current?.isValid) {
      let searchRequestModel = getSearchRequestModel(imageSearchFormRef.current?.values);
      searchRequestModel.isRestoreImage = true;
      dispatch(getThumbnails(searchRequestModel, true, page, pageSize));
      setSelectedRecords({});
      setareAllRecordsSelected(false);
      setTotalSelectedCount(0);
    }
  };

  const handleImageDetails = (record: any, docId: string, probill?: string) => {
    setdocDetailsRecord((prevRecords) => [...prevRecords, record]);
    if (probill) {
      const filteredThumbnails = imageViewState.thumbnails.filter(
        (item: any) => {
          return item && item.probill && item.probill.toString() === probill.toString() && item.Id.toString() !== docId.toString();
        }
      );
      setFilteredThumbnails(filteredThumbnails);
    }
  };

  const getSearchRequestModel = (data: ImageSearchValidationModel) => {
    let limit = 0;
    let isRestoreImage = false;
    let searchRequestModel: ImageSearchRequestModel = {
      probill: parseInt(data.probill) || "",
      documentId: parseInt(data.documentId) || "",
      customer: parseInt(data.customer) || "",
      bol: data.bol,
      po: data.po,
      from: data.from?.toApiDateFormat(),
      to: data.to?.toApiDateFormat(),
      docType: docType,
      location: location,
      status: status,
      limit: limit,
      isRestoreImage: isRestoreImage
    };
    return searchRequestModel;
  };

  const clearSearchRecords = () => {
    setDocType(null);
    setLocation(null);
    setStatus(null);
  };
  const onDocTypeChanged = (_docType: XGSSelectOption | null | undefined) => {
    setDocType(_docType);
  };
  const onLocationChanged = (_location: XGSSelectOption | null | undefined) => {
    setLocation(_location);
  };
  const onStatusChanged = (_status: XGSSelectOption | null | undefined) => {
    setStatus(_status);
  };

  useEffect(() => {
    if (imageViewState.thumbnails && imageViewState.thumbnails.length > 0) {
      if (contentRef.current) {
        const contentHeight = contentRef.current.offsetHeight;
        const calculatedHeight = contentHeight * 1.1;
        setDynamicHeight(calculatedHeight);
      }

      setGalleryImages(
        imageViewState.thumbnails.map((record: any, index: number) => {
          return {
            src: record.imageUrl,
            class: `xgs-image-viewer__image-styles-${index}`,
            rotation: 0,
            flipHorizontal: false, // Initialize with no horizontal flip
            flipVertical: false, // Initialize with no vertical flip
            zoomed: false,
          };
        })
      );

      setDeletingImageId((prevIds) => {
        const thumbnailIds = new Set(imageViewState.thumbnails.map((record: any) => record.Id));
        return prevIds.filter((id) => thumbnailIds.has(id));
      });
      setHoveredIconId(null);
    }
  }, [imageViewState.thumbnails]);


  useEffect(() => {
    const container = imageContainerRef.current;
    const handleScroll = () => {
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const limit = 0;
        const isRestoreImage = false;
        let searchRequestModel = { ...imageSearchFormRef.current?.values, docType, location, status, limit, isRestoreImage };
        searchRequestModel.from = searchRequestModel.from?.toApiDateFormat();
        searchRequestModel.to = searchRequestModel.to?.toApiDateFormat();

        if (scrollHeight - scrollTop <= clientHeight + 1 &&
          imageViewState.paginationDetails.page < imageViewState.paginationDetails.totalPages &&
          imageSearchFormRef.current?.isValid &&
          !imageViewState.requestStarted &&
          JSON.stringify(searchRequestModel) === JSON.stringify(imageViewState.payload) // used to prevent the scroll search if the filter changes
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
    // eslint-disable-next-line
  }, [imageViewState.thumbnails, imageViewState.requestStarted]);

  useEffect(() => {
    searchRecordsOnScroll();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (docDetailsRecord.length > 0)
      setDisableImageViewer(true);
  }, [docDetailsRecord, filteredThumbnails]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (hoveredIndex !== null) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 1000);
    } else {
      setShowTooltip(false);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hoveredIndex]);

  useEffect(() => {
    const totalCount = Object.values(selectedRecords).reduce((total, recordSet) => total + recordSet.size, 0);
    setTotalSelectedCount(totalCount);
    const areAllRecordsSelected = imageViewState.thumbnails.length > 0 && Object.values(selectedRecords).every((set) => set.size > 0) &&
      imageViewState.thumbnails.length === Object.values(selectedRecords).reduce((acc, set) => acc + set.size, 0);
    setareAllRecordsSelected(areAllRecordsSelected);
    // eslint-disable-next-line
  }, [selectedRecords]);

  useEffect(() => {
    return () => {
      dispatch(resetImageViewState());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(1);
  }, []);

  const selectAllClickProbill = (probill: string, selectAll: boolean) => {
    setSelectedRecords((prevSelectedRecords) => {
      const updatedSelectedRecords = { ...prevSelectedRecords };
      const indexes = imageViewState.thumbnails
        .map((record: any, index: number) => (record.probill === probill ? record.Id : -1))
        .filter((index: number) => index !== -1);

      if (selectAll) {
        // If selecting all, add all indexes to the Set for this probill
        updatedSelectedRecords[probill] = new Set(indexes);
      } else {
        // If unselecting all, clear the Set for this probill
        updatedSelectedRecords[probill] = new Set();
      }
      return updatedSelectedRecords;
    });
  };
  const selectAllClick = () => {
    const updatedSelectedRecords: { [probill: string]: Set<number> } = {};

    imageViewState.thumbnails.forEach((record: any, index: number) => {
      if (!updatedSelectedRecords[record.probill]) {
        updatedSelectedRecords[record.probill] = new Set();
      }
      updatedSelectedRecords[record.probill].add(record.Id);
    });

    setSelectedRecords(updatedSelectedRecords); // Set all records as selected
  };
  // Check if all records for a specific probill are selected
  const isAllSelected = (probill: string) => {
    const probillIndexes = imageViewState.thumbnails
      .map((record: any, index: number) => (record.probill === probill ? record.Id : -1))
      .filter((index: number) => index !== -1);
    return probillIndexes.length > 0 && probillIndexes.every((index: number) => selectedRecords[probill]?.has(index));
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (probill: string, recordIndex: number, isChecked: boolean) => {
    setSelectedRecords((prevSelectedRecords) => {
      const updatedSelectedRecords = { ...prevSelectedRecords };

      if (!updatedSelectedRecords[probill]) {
        updatedSelectedRecords[probill] = new Set();
      }

      if (isChecked) {
        updatedSelectedRecords[probill].add(recordIndex);
      } else {
        updatedSelectedRecords[probill].delete(recordIndex);
      }

      return updatedSelectedRecords;
    });
  };

  const handleClearSelection = () => {
    setSelectedRecords({});
  };

  const downloadDocuments = () => {
    const extractedValues: number[] = [];
    for (const key in selectedRecords) {
      if (selectedRecords.hasOwnProperty(key)) {
        extractedValues.push(...selectedRecords[key]);
      }
    }
    dispatch(downloadAllDocuments(extractedValues, (file: Blob) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = "selected_documents.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      mixpanel.track("All invoices documents were downloaded (PDF)");
    }));
  };

  const deleteImage = async (imageId: string) => {
    if (imageId !== "") {
      setDeletingImageId((prevIds) => [...prevIds, imageId]);
      await dispatch(deleteSelectedImage(imageId));
      setSelectedRecords({});
      setareAllRecordsSelected(false);
      setTotalSelectedCount(0);
    }
  }

  const handleImageUrlChange = (image: any, recordIndex: number) => {
    setdocDetailsRecord((prevRecords) => {
      const updatedRecords = [...prevRecords];
      updatedRecords[recordIndex] = {
        ...updatedRecords[recordIndex],
        probill: image.probill ?? updatedRecords[recordIndex].probill,
        indexDate: image.docIndexDte ? image.docIndexDte : "",
        indexUser: image.docIndexUsr ? image.docIndexUsr : "",
        indexData: image.indexData,
        lockUser: image.docLockedUsr ? image.docLockedUsr : "",
        lockDate: image.docLockDte ? image.docLockDte : "",
        docStatId: DOCUMENT_STAT_ID_ACTIONS[image.docStatID] || "A",
        type: DOCUMENT_TYPE_ID_ACTIONS[image.docTypeId] || "UNK"
      };
      return updatedRecords;
    });
  };

  return (
    <ContentContainer title="Image Viewer">
      <Formik
        initialValues={initialValues}
        validationSchema={imageSearchValidationSchema}
        onSubmit={searchRecordsClick}
        validateOnChange
        validateOnMount
        innerRef={imageSearchFormRef}
      >
        {(props: FormikProps<ImageSearchValidationModel>) => {
          const handleReset = () => {
            props.resetForm();
            clearSearchRecords();
          };
          return (
            <Form>
              {((imageViewState.thumbnails && imageViewState.thumbnails.length > 0) || (props.isValid && Object.values(props.values).some((value) => value && value.toString().trim() !== ""))) && (
                <div className="xgs-image-viewer__restore-deleted">
                  <div className="xgs-image-viewer__restore-deleted__child">
                    <Button
                      theme={ButtonThemes.transparent}
                      size={ButtonSizes.auto}
                      onClick={restoreDeleted}
                      type="button"
                      className={"xgs-image-indexer__small-button"}
                      disabled={!props.isValid || !Object.values(props.values).some((value) => value && value.toString().trim() !== "")}
                    >
                      {imageViewState.requestStarted && imageViewState.requestCreator === "RESTORE_DELETED_IMAGES" ? (
                        <LoadingIcon className="xgs-image-indexer__spin xgs-image-viewer__restore-deleted__margin-right-eight" />
                      ) : (!props.isValid || !Object.values(props.values).some((value) => value && value.toString().trim() !== "") ? <RestoreGreyIcon className="xgs-btn__icon xgs-image-indexer__restore-icon" /> :
                        <RestoreIcon className="xgs-btn__icon xgs-image-indexer__restore-icon" />
                      )}
                      Restore Deleted Images
                    </Button>
                  </div>
                </div>
              )}
              <div className="xgs-image-viewer">
                <div className="xgs-image-viewer__list__filters">
                  <LabeledTextInput
                    label="Probill#"
                    name="probill"
                    labelMode={LabelModes.column}
                    className="xgs-image-viewer__list__filters__input"
                    value={props.values.probill}
                    onChange={(e) => props.setFieldValue("probill", e.currentTarget.value)}
                    type="text"
                    placeholder="Probill"
                  />
                  <LabeledTextInput
                    label="Customer#"
                    name="customer"
                    labelMode={LabelModes.column}
                    className="xgs-image-viewer__list__filters__input"
                    value={props.values.customer}
                    onChange={(e) => props.setFieldValue("customer", e.currentTarget.value)}
                    type="text"
                    placeholder="Customer"
                  />
                  <LabeledTextInput
                    label="BOL#"
                    name="bol"
                    labelMode={LabelModes.column}
                    className="xgs-image-viewer__list__filters__input"
                    value={props.values.bol}
                    onChange={(e) => props.setFieldValue("bol", e.currentTarget.value)}
                    type="text"
                    placeholder="BOL"
                  />
                  <LabeledTextInput
                    label="PO#"
                    name="po"
                    labelMode={LabelModes.column}
                    className="xgs-image-viewer__list__filters__input"
                    value={props.values.po}
                    onChange={(e) => props.setFieldValue("po", e.currentTarget.value)}
                    type="text"
                    placeholder="PO"
                  />
                  <LabeledTextInput
                    label="Document#"
                    name="documentId"
                    labelMode={LabelModes.column}
                    className="xgs-image-viewer__list__filters__input"
                    value={props.values.documentId}
                    onChange={(e) => props.setFieldValue("documentId", e.currentTarget.value)}
                    type="text"
                    placeholder="Document"
                  />
                  <LabeledDateRangeInput
                    label="Dates range:"
                    labelMode={LabelModes.column}
                    className="xgs-image-viewer__list__filters__dates"
                    start={props.values.from}
                    end={props.values.to}
                    onStartChange={(value) => props.setFieldValue("from", value)}
                    onEndChange={(value) => props.setFieldValue("to", value)}
                  />
                </div>
                <div className="xgs-image-viewer__list__filters">
                  <div className="xgs-image-viewer__controls__item">
                    <LabeledSelectInput
                      onValueChange={onDocTypeChanged}
                      value={docType}
                      options={getDocTypePerformedValues()}
                      label="Document type:"
                      name="docType"
                      labelMode={LabelModes.column}
                      className="xgs-tracking-filter__input"
                    />
                  </div>
                  <div className="xgs-image-viewer__controls__item">
                    <LabeledSelectInput
                      onValueChange={onLocationChanged}
                      value={location}
                      options={getLocationPerformedValues()}
                      label="Location:"
                      name="location"
                      labelMode={LabelModes.column}
                      className="xgs-tracking-filter__input"
                    />
                  </div>
                  <div className="xgs-image-viewer__controls__item">
                    <LabeledSelectInput
                      onValueChange={onStatusChanged}
                      value={status}
                      options={getStatusPerformedValues()}
                      label="Status:"
                      name="status"
                      labelMode={LabelModes.column}
                      className="xgs-tracking-filter__input"
                    />
                  </div>
                  <Button
                    className="xgs-image-viewer__list__filters__button"
                    theme={ButtonThemes.blue}
                    type="submit"
                    spinner={imageViewState.requestStarted && imageViewState.requestCreator === "IMAGE_THUMBNAIL_DETAILS"}
                    disabled={!props.isValid || imageViewState.requestStarted || !Object.values(props.values).some((value) => value && value.toString().trim() !== "")}
                  >
                    Search
                  </Button>
                  <Button
                    className="xgs-image-viewer__list__filters__button xgs-image-viewer__list__filters__button--last"
                    theme={ButtonThemes.gray}
                    onClick={handleReset}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      {(imageViewState.thumbnails && imageViewState.thumbnails.length > 0) && (
        <div className="xgs-image-viewer__images-view-section" style={{ height: `${dynamicHeight}px`, maxHeight: `${window.innerHeight - 145}px` }} ref={imageContainerRef}>

          {/* thumbnails container */}
          <div className="xgs-image-viewer__thumbnail-modal" ref={contentRef}>
            {imageViewState.thumbnails.reduce((acc: any, record: any, recordIndex: number) => {
              const isNewProbill = recordIndex === 0 || imageViewState.thumbnails[recordIndex - 1].probill !== record.probill;

              if (isNewProbill) {
                const allSelected = isAllSelected(record.probill);
                acc.push(
                  <div key={`probill-${record.probill}`} className="xgs-image-viewer__thumbnail-modal__probill-number-selector">
                    <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-primary">
                      <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-secondary">
                        <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-third">Probill</div>
                        <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-third">:</div>
                      </div>
                      <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-fourth">{record.probill === 0 ? "" : record.probill}</div>
                    </div>
                    <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__gap"></div>
                    <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__selector">
                      <Button
                        theme={ButtonThemes.transparent}
                        size={ButtonSizes.auto}
                        className={"xgs-image-indexer__small-button"}
                        onClick={() => selectAllClickProbill(record.probill, !allSelected)}>
                        {allSelected ? "Unselect All" : "Select All"}
                      </Button>
                    </div>
                  </div>
                );
              }

              acc.push(
                <div key={recordIndex} className="xgs-image-viewer__thumbnail-modal__thumbnail-card">
                  <div style={{ cursor: disableImageViewer ? "" : "pointer" }} onClick={disableImageViewer ? undefined : () => { setCurrentImageIndex(recordIndex); setShowGallery(true); }} onMouseEnter={disableImageViewer ? undefined : () => setImageHoveredIndex(recordIndex)} onMouseLeave={disableImageViewer ? undefined : () => setImageHoveredIndex(null)}>
                    <img className="xgs-image-viewer__thumbnail-modal__thumbnail-card__image" style={{ filter: imageHoveredIndex === recordIndex ? "brightness(93%)" : "brightness(100%)" }} src={`data:image/jpeg;base64,${record.thumbnail}`} alt="Thumbnail" />
                  </div>
                  <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__primary">
                    <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__secondary">
                      <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__third">
                        <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__fourth">
                          <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__fifth">
                            <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__sixth">
                              <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__seventh" style={{ background: statusStyles[record.status]?.background || "rgba(93, 199, 84, 0.16)" }} onMouseEnter={() => handleMouseEnter(recordIndex)} onMouseLeave={handleMouseLeave}>
                                <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__eighth" style={{ color: statusStyles[record.status]?.color || "#14870A" }}>
                                  {record.type}
                                </div>
                                {showTooltip && hoveredIndex === recordIndex && (
                                  <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__ninth">
                                    {statusStyles[record.status]?.description || "No description"}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__tenth">
                              {record.Id}
                            </div>
                          </div>
                          <div className="xgs-image-viewer__checkboxes xgs-image-viewer__checkbox-group xgs-image-viewer__thumbnail-modal__thumbnail-card__eleventh" >
                            <input
                              disabled={disableImageViewer}
                              type="checkbox"
                              className="xgs-image-viewer__thumbnail-modal__thumbnail-card__eleventh__checkbox"
                              style={{ cursor: disableImageViewer ? "" : "pointer" }}
                              checked={selectedRecords[record.probill]?.has(record.Id) || false}
                              onChange={(e) => handleCheckboxChange(record.probill, record.Id, e.target.checked)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="xgs-image-viewer__thumbnail-modal__thumbnail-cardtwelfth">{record.indexDate}</div>
                    </div>
                    <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails">
                      <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails__primary">
                        <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails__secondary">
                          <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails__third">
                            <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails__fourth">
                              {deletingImageId.includes(record.Id) && imageViewState.requestCreator === "DELETE_SELECTED_IMAGE" && !imageViewState.requestFailed ? (
                                <LoadingIcon className="xgs-image-indexer__spin xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails__fifth" />
                              ) : (
                                <XGSIcon
                                  icon={XGSIcons.faTrashAlt}
                                  size="1x"
                                  onClick={disableImageViewer ? undefined : () => deleteImage(record.Id)}
                                  style={{ cursor: disableImageViewer ? "" : "pointer", color: hoveredIconId === record.Id && !disableImageViewer ? "rgba(79, 95, 114, 1)" : "rgba(79, 95, 114, 0.50)" }}
                                  onMouseEnter={() => setHoveredIconId(record.Id)}
                                  onMouseLeave={() => setHoveredIconId(null)}
                                />
                              )}
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="xgs-image-viewer__thumbnail-modal__thumbnail-card__trash-imagedetails__sixth" onClick={() => handleImageDetails(record, record.Id, record.probill ? record.probill : "")}>
                          <Button
                            theme={ButtonThemes.transparent}
                            size={ButtonSizes.auto}
                            disabled={disableImageViewer}
                            className={"xgs-image-indexer__small-button"}>
                            Image Details
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              return acc;
            }, [])}
          </div>

          {/* Fixed bottom header */}
          <div className="xgs-image-viewer__image-selections">
            <div className="xgs-image-viewer__image-selections__primary">
              <div className="xgs-image-viewer__image-selections__selected-docs">
                <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-third">
                  Selected Documents:
                </div>
                <div className="xgs-image-viewer__image-selections__selected-docs__primary">{totalSelectedCount}</div>
              </div>
              <div className="xgs-image-viewer__image-selections__selected-docs">
                <div className="xgs-image-viewer__thumbnail-modal__probill-number-selector__probill-number-third">
                  Total Page:
                </div>
                <div className="xgs-image-viewer__image-selections__selected-docs__primary">{`${imageViewState.paginationDetails.page} / ${imageViewState.paginationDetails.totalPages}`}</div>
              </div>
              <div className="xgs-image-viewer__image-selections__buttons">
                <Button
                  theme={ButtonThemes.gray}
                  className={"xgs-delivery-records__list__filters__button xgs-image-viewer__controls__clear-button-width"}
                  onClick={handleClearSelection}
                  disabled={totalSelectedCount <= 0 || disableImageViewer}>
                  Clear Selection
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  className={"xgs-delivery-records__list__filters__button xgs-image-viewer__controls__selectall-button-width"}
                  onClick={selectAllClick}
                  disabled={areAllRecordsSelected === true || disableImageViewer}>
                  Select All
                </Button>
                <Button
                  theme={ButtonThemes.blue}
                  className={"xgs-delivery-records__list__filters__button xgs-image-viewer__controls__download-button-width"}
                  onClick={downloadDocuments}
                  disabled={totalSelectedCount <= 0 || disableImageViewer}
                  spinner={imageViewState.requestStarted && imageViewState.requestCreator === "DOWNLOAD_SELECTED_IMAGES"}>
                  {totalSelectedCount <= 0 ? <DownloadIconGrey className="xgs-btn__icon xgs-image-viewer__controls__download-button-width__grey-icon" /> :
                    <DownloadIcon className="xgs-btn__icon xgs-image-viewer__controls__download-button-width__normal-icon" />
                  }
                  Download Docs
                </Button>

              </div>
            </div>
          </div>
        </div>
      )}

      <ReactViewer
        visible={showGallery}
        onClose={() => setShowGallery(false)}
        images={galleryImages.map((image: any) => ({
          src: image.src,
          alt: "Gallery Image"
        }))}
        defaultScale={1.2}
        attribute={false}
        activeIndex={currentImageIndex}
      />

      <ImageDetails
        currentDocId={docDetailsRecord}
        relatedThumbnail={filteredThumbnails}
        show={docDetailsRecord.length > 0}
        onClose={() => {
          setDisableImageViewer(false);
          setdocDetailsRecord([]);
          setFilteredThumbnails([]);
          dispatch(resetImageViewIndexState());
        }}
        onImageUrlChange={handleImageUrlChange}
      />
    </ContentContainer>
  );
};

export default ImageViewer;
