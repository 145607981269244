import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SteppedProgress, { SteppedProgressStep } from "../../../ui-components/stepped-progress/steppedProgress";
import XGSNavLink from "../../../ui-components/xgs-nav-link/xgsNavLink";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import {
  shipmentDetailsSelector,
  getTrackingHistory
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import { ShipmentTrackingHistoryModel } from "../../../app/data/tracking/ShipmentDetailsModel";
import { cn } from "../../../services/common/className";
import { Routes } from "../../../app/route/RoutesConfig";
import clockImg from "../../../images/clock_blue.svg";
import Loading from "../../../ui-components/loading/loading";

export interface ShipmentTrackingHistoryProps {
  probillNumber: number;
  inSidebar?: boolean;
}

const ShipmentTrackingHistory: React.FC<ShipmentTrackingHistoryProps> = ({probillNumber, inSidebar}) => {
  const dispatch = useDispatch();
  const shipmentDetailsState = useSelector(shipmentDetailsSelector);

  const generateProgressSteps = (trackingHistory: ShipmentTrackingHistoryModel) => {
    if (!trackingHistory.pickupEvents && !trackingHistory.linehaulEvents && !trackingHistory.deliveryEvents && !trackingHistory.agentEvents) return [];
    let steps: SteppedProgressStep[] = [];
    // pickup events
    if (trackingHistory.pickupEvents.length > 0) {
      let pickupSteps = trackingHistory.pickupEvents.map((step, index) => {
        let newObj = {
          title: step.event,
          completed: true,
          reached: true,
          details: <>
            <div>{step.date.toUiDateTimeFormat()}</div>
            {step.address && (
              <>
                <div>Street Address: <span>{step.address.address.toLowerCase().capitalize()}</span></div>
                <div>City/State/ZIP: <span>{step.address.city}, {step.address.state}{step.address.zip ? `, ${step.address.zip}` : ""}</span></div>
              </>
            )}
            {step.driverName && <div>Driver: <span>{step.driverName}</span></div>}
            {step.pickupNumber && <div>Pickup #:&nbsp;
              <XGSNavLink
                className="xgs-table-link-cell"
                target="_blank"
                to={`${Routes.pickup.log}/${step.pickupNumber}`}
              >
                {step.pickupNumber}
              </XGSNavLink>
            </div>}
          </>,
          collapsible: true
        };
        return newObj;
      });
      steps = [...steps, ...pickupSteps];
    }
    // linehaul events
    if (trackingHistory.linehaulEvents.length > 0) {
      let linehaulSteps = trackingHistory.linehaulEvents.map((step, index) => {
        let newObj = {
          title: step.event,
          completed: true,
          reached: true,
          details: <>
            <div>{step.date.toUiDateTimeFormat()}</div>
            {step.trailerNumber && <div>Trailer #: <span>{step.trailerNumber}</span></div>}
            {step.manifestNumber && <div>Manifest #: <span>{step.manifestNumber}</span></div>}
          </>,
          collapsible: true
        };
        return newObj;
      });
      steps = [...steps, ...linehaulSteps];
    }
    // delivery events
    if (trackingHistory.deliveryEvents.length > 0) {
      let deliverySteps = trackingHistory.deliveryEvents.map((step, index) => {
        let newObj = {
          title: step.event,
          completed: true,
          reached: true,
          details: <>
            <div>{step.date.toUiDateTimeFormat()}</div>
            {step.trailerNumber && <div>Trailer #: <span>{step.trailerNumber}</span></div>}
            {step.manifestNumber && <div>Manifest #: <span>{step.manifestNumber}</span></div>}
          </>,
          collapsible: true
        };
        return newObj;
      });
      steps = [...steps, ...deliverySteps];
    }
    // agent events
    if (trackingHistory.agentEvents.length > 0) {
      let deliverySteps = trackingHistory.agentEvents.map((step, index) => {
        let newObj = {
          title: step.event,
          completed: true,
          reached: true,
          details: <div>{step.date.toUiDateTimeFormat()}</div>,
          collapsible: true
        };
        return newObj;
      });
      steps = [...steps, ...deliverySteps];
    }
    if (steps.length > 0) {
      const lastIndex = steps.length - 1;
      steps[lastIndex].completed = false;
      steps[lastIndex].defaultOpened = true;
    }
    steps.push({
      completed: false,
      reached: false
    });
    return steps.reverse();
  };

  const wereEventsProvided = () => {
    if (!shipmentDetailsState.trackingHistory) return false;
    return (
      (shipmentDetailsState.trackingHistory.pickupEvents.length > 0)
      || (shipmentDetailsState.trackingHistory.linehaulEvents.length > 0)
      || (shipmentDetailsState.trackingHistory.deliveryEvents.length > 0)
      || (shipmentDetailsState.trackingHistory.agentEvents.length > 0)
    );
  };

  useEffect(() => {
    if (!probillNumber) return;
    dispatch(getTrackingHistory(probillNumber));
  }, [dispatch, probillNumber]);

  return (
    <div className={cn("xgs-shipment-details__tracking-history")({"in-sidebar": inSidebar})}>
      {!inSidebar && (
        <div className="xgs-shipment-details__tracking-history__header">
          <div className="xgs-shipment-details__tracking-history__header__icon"><img src={clockImg} alt="clock" /></div> Tracking History
        </div>
      )}
      <Loading className="xgs-shipment-details__tracking-history__spinner" isLoading={shipmentDetailsState.trackingHistoryLoading} />
      {!shipmentDetailsState.trackingHistoryLoading && shipmentDetailsState.trackingHistoryLoaded && (
        <>
          <div className="xgs-shipment-details__tracking-history__paired-values">
            <div className="xgs-shipment-details__tracking-history__paired-values__item">
              <div className="xgs-shipment-details__tracking-history__paired-values__item__label">Last Location:</div>
              <div className="xgs-shipment-details__tracking-history__paired-values__item__value">
                {shipmentDetailsState.trackingHistory?.lastLocation
                  ? (
                    <>
                      {shipmentDetailsState.trackingHistory.lastLocation.address ? <div>{shipmentDetailsState.trackingHistory.lastLocation.address}</div> : ""}
                      {shipmentDetailsState.trackingHistory.lastLocation.city}, {shipmentDetailsState.trackingHistory.lastLocation.state}
                      {shipmentDetailsState.trackingHistory.lastLocation.zip ? `, ${shipmentDetailsState.trackingHistory.lastLocation.zip}` : ""}
                    </>
                  ) : "N/A"
                }
              </div>
            </div>
            <div className="xgs-shipment-details__tracking-history__paired-values__item">
              <div className="xgs-shipment-details__tracking-history__paired-values__item__label">Last Terminal:</div>
              <div className="xgs-shipment-details__tracking-history__paired-values__item__value">
                {shipmentDetailsState.trackingHistory?.lastTerminal
                  ? (
                    <>
                      {shipmentDetailsState.trackingHistory.lastTerminal.airportCode} - {shipmentDetailsState.trackingHistory.lastTerminal.name} - {shipmentDetailsState.trackingHistory.lastTerminal.terminal}
                    </>
                  ) : "N/A"
                }
              </div>
            </div>
          </div>
          <div className="xgs-shipment-details__tracking-history__content">
            {shipmentDetailsState.trackingHistory && wereEventsProvided() && (
              <SteppedProgress
                steps={generateProgressSteps(shipmentDetailsState.trackingHistory)}
                vertical
              />
            )}
            {!wereEventsProvided() && (
              <div className="xgs-shipment-details__tracking-history__no-events">There are no events yet for this probill.</div>
            )}
          </div>
        </>
      )}
      {!shipmentDetailsState.trackingHistoryLoading && shipmentDetailsState.trackingHistoryFailed && (
        <XGSErrorMessage className="xgs-shipment-details__tracking-history__error">{shipmentDetailsState.trackingHistoryError}</XGSErrorMessage>
      )}
    </div>
  );
};

export default ShipmentTrackingHistory;
