import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../ui-components/table/table";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import LabeledTextInput from "../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import ContentContainer from "../../templates/content-container/contentContainer";
import { UserUtils } from "../../app/data/user/userUtils";
import TeamsState from "../../slices/company/companyState";
import {
  teamsSelector,
  getAgents,
  resetErrors,
  resetTeams,
  resetSorting
} from "../../slices/company/companySlice";
import CreateTeam from "../company/create-team/createTeam";
import TeamDetails from "../company/teamDetails";
import UserState from "../../slices/user/UserState";
import {userSelector} from "../../slices/user/userSlice";
import { TeamInfo } from "../../app/data/company/models";
import "./agentsList.scss";

const columnHelper = createColumnHelper<TeamInfo>();

const AgentsList: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const teamsState: TeamsState = useSelector(teamsSelector);
  const userState: UserState = useSelector(userSelector);
  const [teamId, setTeamId] = useState<string | undefined>();
  const [showCreateTeam, setShowCreateTeam] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  const columns = [
    columnHelper.accessor("name", {
      id: "name",      
      header: "Name",
      size: 460,
    }),
    columnHelper.accessor("accounts", {
      id: "accounts",      
      header: () => (
        <div className="text-center">
          Accounts
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          {cellProps.getValue()}
        </div>
      ),
      size: 130,
    }),
    columnHelper.accessor("users", {
      id: "users",      
      header: () => (
        <div className="text-center">
          Users
        </div>
      ),      
      cell: (cellProps) => (
        <div className="text-center">
          {cellProps.getValue()}
        </div>
      ),
      size: 100,
    })
  ];

  let clearSorting: (() => void) | null = null;

  const assignClearSorting = (f: () => void) => {
    clearSorting = f;
  };

  const onListInfiniteScroll = () => {
    dispatch(getAgents(teamsState.teamsScrollRequest?.lastIds, teamsState.teamsSearchString, false, teamsState.teamsSorting));
  };

  const onTeamClick = (row: any) => {
    setTeamId(row.id)
  };

  const onSearchStart = () => {
    dispatch(getAgents("", searchString, false));
    setTeamId(undefined);
  };

  const onSearchClear = () => {
    setSearchString("");
    if (!teamsState.teamsSearchString) return;
    clearSorting?.();
    dispatch(resetTeams());
    dispatch(getAgents("", "", false));
    setTeamId(undefined);
  };

  const onSortByColumn = (
    columnIndex: number,
    order: string
  ) => {
    setTeamId(undefined);
    const columns = [
      "name",
      "accCount",
      "userCount"
    ];
    dispatch(resetErrors());
    dispatch(getAgents("", "", false, {
      sortBy: columns[columnIndex],
      order
    }));
  };

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(resetSorting());
    dispatch(getAgents("", "", false));
  }, [dispatch]);

  return (
    <ContentContainer title="Agents">
      <div className="xgs-teams">
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search">
            <LabeledTextInput
              labelMode={LabelModes.column}
              className="xgs-list__controls__search__input"
              value={searchString}
              onChange={(e) => setSearchString(e.currentTarget.value)}
              onKeyDown={(e) => (e.key === "Enter") && !teamsState.teams_fetch_was_started && onSearchStart()}
              type="text"
              placeholder="Agent name, account name or number"
            />
            <Button
              className="xgs-list__controls__button"
              theme={ButtonThemes.blue}
              disabled={teamsState.teams_fetch_was_started}
              onClick={onSearchStart}>
              Search
            </Button>
            <Button
              className="xgs-list__controls__button"
              theme={ButtonThemes.gray}
              onClick={onSearchClear}>
              Clear
            </Button>
          </div>
          <div className="xgs-list__controls__actions">
            {UserUtils.isXGSAdministrator(userState.profile) && (
              <>
                <Button
                  className="xgs-teams__create-team-button"
                  theme={ButtonThemes.blue}
                  onClick={() => setShowCreateTeam(true)}>
                  Create new agent
                </Button>
                <CreateTeam
                  show={showCreateTeam}
                  type="AGENT"
                  onTeamCreated={() => {
                    dispatch(getAgents("", "", false));
                    setSearchString("");
                  }}
                  onClose={() => {
                    setShowCreateTeam(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        {teamsState.teams_fetch_was_failed && (
          <XGSErrorMessage>{teamsState.teams_fetch_fail_reason}</XGSErrorMessage>
        )}
        {!teamsState.teams_fetch_was_failed && (
          <Table
            isLoading={teamsState.teams_fetch_was_started}
            keepTableOnLoading={true}
            columns={columns}
            data={teamsState.teams}
            cursorPointer={true}
            onRowClicked={onTeamClick}
            rowHeight={38}
            minTableHeight={420}
            noResultsText="There are no agents"
            infiniteScroll={true}
            infiniteScrollLoading={teamsState.teams_fetchPortion_was_started}
            infiniteScrollHasNext={!teamsState.teamsFetchedAll}
            onInfiniteScroll={onListInfiniteScroll}
            sorting={!teamsState.teamsSearchString}
            onSortByColumn={onSortByColumn}
            clearSorting={assignClearSorting}
            highlightRow={teamId ? teamsState.teams.findIndex((team: any) => team.id === teamId) : null}
          />
        )}
        <TeamDetails
          id={teamId}
          show={!!teamId}
          type="AGENT"
          onClose={() => {
            setTeamId(undefined);
          }} />
      </div>
    </ContentContainer>
  );
};

export default AgentsList;
